import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FontPicker from "font-picker-react";
import { ColorPicker, Input } from "../../../Controls";
import { _t } from "js/utils/TranslationService";
import Enhancer from "./container";

const createLabel = (label, type) => (
  <Typography variant={`subtitle${type}`} gutterBottom>
    {_t(label)}
  </Typography>
);

const Cucardas = ({ classes, types, handleChangeCucarda }) => (
  <Grid container>
    {types.map(
      ({ type, label, background, color, fontFamily, typography }, i) => (
        <Fragment key={type}>
          <Grid item xs={12} md={8}>
            {createLabel(type, 1)}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className={!(types.length === i + 1) ? classes.Row : ""}
          >
            <Grid container spacing={24}>
              <Grid item xs>
                {createLabel("Etiqueta", 2)}
                <Input
                  value={label}
                  onChange={handleChangeCucarda(`[${i}].label`)}
                />
              </Grid>
              <Grid item xs>
                {createLabel("Color", 2)}
                <ColorPicker
                  color={background}
                  onChange={handleChangeCucarda(`[${i}].background`)}
                />
              </Grid>
              <Grid item xs>
                {createLabel("Color de texto", 2)}
                <ColorPicker
                  color={color}
                  onChange={handleChangeCucarda(`[${i}].color`)}
                />
              </Grid>
              <Grid item xs>
                {createLabel("Fuente", 2)}
                <FontPicker
                  apiKey="AIzaSyDm8xG6mYjOimJOXRrdQh-NTkQXAUknlGc"
                  activeFont={fontFamily || "Arial"}
                  onChange={nextFont =>
                    handleChangeCucarda(`[${i}].fontFamily`)(nextFont.family)
                  }
                />

                <p style={{ fontFamily }}>{label}</p>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )
    )}
  </Grid>
);

Cucardas.propTypes = {
  classes: PropTypes.object
};

Cucardas.defaultProps = {};

Cucardas.displayName = "Cucardas";

export default Enhancer(Cucardas);
