import React, { PureComponent, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CheckboxField,
  DateTimePicker,
  InputNumberField,
  InputTextField,
  ProgramaField,
  ReduxTypeAhead,
  SegmentoField,
  ListMultipleField
} from "js/components/Shared/Form/Fields";
import Button from "@material-ui/core/Button";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";
import Detalle from "../Detalle";
import { ClipboardIcon } from "images/icons";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import {
  getEstados,
  DIRECCIONAMIENTO,
  MODO_MAIL_RESULTADO,
  TIPO_ACCION,
  MATEMATICA_RANKING
} from "../constants";
import EmailField from "./EmailField";
import moment from "moment";
import getComponente from "./TipoAccion";
import Variables from "./variables";
import AuthService from "js/utils/AuthService";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { copyToClipboard } from "js/utils";
import Tooltip from "@material-ui/core/Tooltip";
import { get } from "lodash";

import { withApollo } from "react-apollo";
import PerfilGraphQL from "js/graphql/resolvers/perfil.resolver";

class MyForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      definicionesPerfil: [],
      showInputAcreditaPuntos: props.initialValues.acreditaAccionPuntos
    };
  }

  async componentDidMount() {
    const { idTargetPrograma: idPrograma } = this.props;
    if (idPrograma) {
      await this.fetchDefiniciones(idPrograma);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { idTargetPrograma: idAntes } = this.props;
    const { idTargetPrograma: idPrograma } = nextProps;
    if (idAntes !== idPrograma) {
      await this.fetchDefiniciones(idPrograma);
      this.setState({
        showInputAcreditaPuntos: this.props.initialValues.acreditaAccionPuntos
      });
    }
  }

  async fetchDefiniciones(idPrograma) {
    const { client } = this.props;
    await client
      .query({
        query: PerfilGraphQL.queries.getDefinicionesPerfilById,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: { idPrograma: idPrograma }
      })
      .then(res => {
        if (res && res.data && res.data.getDefinicionesPerfil) {
          const definiciones = res.data.getDefinicionesPerfil.map(
            definicion => {
              return { label: definicion.label, value: definicion.key };
            }
          );
          this.setState({ definicionesPerfil: definiciones });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const {
      idTargetPrograma: idPrograma,
      fechaInicio,
      fechaFin,
      handleSubmit,
      classes,
      submitting,
      pristine,
      invalid,
      initialValues: { estado: initialEstado, tieneRanking },
      initialValues,
      tipoForm,
      disabled,
      volver,
      view,
      nuevo,
      edit,
      tipo,
      goToEdit,
      permiteMultipleParticipacion,
      idAccion,
      openNotification
    } = this.props;

    const { definicionesPerfil, showInputAcreditaPuntos } = this.state;
    const Tipo = getComponente(tipo);
    let checkboxMultipleParticipacion = true;
    let puedeTenerRanking = true;
    let puedeTenerGanadores = true;

    const changeStateInput = () => {
      this.setState({ showInputAcreditaPuntos: !showInputAcreditaPuntos });
    };

    if (tipo) {
      checkboxMultipleParticipacion = {
        TRIVIA: true,
        FORMULARIO: true,
        ENCUESTA: true
      }[tipo];
      puedeTenerRanking = {
        TRIVIA: true,
        FORMULARIO: false,
        ENCUESTA: false
      }[tipo];
      puedeTenerGanadores = {
        TRIVIA: true,
        FORMULARIO: true,
        ENCUESTA: false
      }[tipo];
    }
    const clipboard = `/${tipo ? tipo.toLowerCase() : ""}/${idAccion}`;
    const rankingUrl = `/ranking/${idAccion}`;
    const urlReporte = `/detalleAccion/${idAccion}`;
    const urlEdicion = get(
      initialValues,
      `${tipo ? tipo.toLowerCase() : ""}.urlEdicion`
    );
    if (idPrograma) initialValues.idTargetPrograma = idPrograma;
    return (
      <form onSubmit={handleSubmit}>
        {AuthService.hasPerms(["PROGRAMA_LISTA"]) ? (
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {`${tipoForm} acción`}
                <Tooltip title="Tutorial Acciones">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/document/d/1tbPcwYJNtDuaKIuLyMNqV1NsJnOF26Qo5z3wzLYOG3U/edit?usp=sharing"
                      )
                    }
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                {view || edit ? (
                  <Fragment>
                    <Tooltip title="Editar formulario en SurveyGizmo">
                      <IconButton
                        color="primary"
                        onClick={() => window.open(urlEdicion)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {clipboard && (
                      <Tooltip title="Copiar URL de la acción">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            copyToClipboard(
                              clipboard,
                              openNotification,
                              "La URL de la acción ha sido copiada al portapapeles"
                            )
                          }
                        >
                          <ClipboardIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {urlReporte && (
                      <Tooltip title="Copiar URL del reporte">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            copyToClipboard(
                              urlReporte,
                              openNotification,
                              "La URL del reporte ha sido copiada al portapapeles"
                            )
                          }
                        >
                          <AssessmentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {tieneRanking &&
                      rankingUrl && (
                        <Tooltip title="Copiar URL del ranking">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              copyToClipboard(
                                rankingUrl,
                                openNotification,
                                "La URL del ranking la acción ha sido copiada al portapapeles"
                              )
                            }
                          >
                            <LocalActivityIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Fragment>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ReduxTypeAhead
                name="tipo"
                label="Tipo"
                suggestions={{ sug: TIPO_ACCION }}
                path={{ path: "sug", value: "value", label: "label" }}
                placeholder="Tipo de acción"
                validate={requiredValidation}
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextField
                name="nombre"
                label="Nombre"
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextField
                name="descripcion"
                label="Descripción"
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DateTimePicker
                key="fechaInicio"
                name="fechaInicio"
                label="Fecha de Inicio"
                disablePast
                disabled={
                  disabled || ["BORRADOR", "LISTA"].indexOf(initialEstado) < 0
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DateTimePicker
                key="fechaFin"
                name="fechaFin"
                label="Fecha de Fin"
                disablePast
                minDate={moment(fechaInicio)}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ReduxTypeAhead
                name="estado"
                label="Estado"
                suggestions={{
                  sug: getEstados({ nuevo, edit, initialEstado })
                }}
                path={{ path: "sug", value: "value", label: "label" }}
                placeholder="Estado"
                validate={requiredValidation}
                required
                disabled={
                  disabled ||
                  ["FINALIZADA", "CANCELADA"].indexOf(initialEstado) > -1
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ReduxTypeAhead
                name="direccionamientoObligatorio"
                label="Direccionamiento obligatorio"
                suggestions={{ sug: DIRECCIONAMIENTO }}
                path={{ path: "sug", value: "value", label: "label" }}
                placeholder="Direccionamiento obligatorio"
                validate={requiredValidation}
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ProgramaField
                name="idTargetPrograma"
                disabled={nuevo ? disabled : true}
                required
                redux
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SegmentoField
                idPrograma={parseInt(idPrograma)}
                name="idTargetSegmento"
                disabled={disabled || !idPrograma}
                redux
                withTodos
                withTodosNull
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputNumberField
                name="puntosParticipar"
                label="Puntos por participar"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <InputTextField
                name="conceptoPuntosParticipar"
                label="Concepto de puntos por participar"
                disabled={disabled}
              />
            </Grid>

            {puedeTenerGanadores ? (
              <Fragment>
                <Grid item xs={6} sm={6} md={6}>
                  <CheckboxField
                    name="acreditaAccionPuntos"
                    label="¿Acredita puntos por respuesta correcta?"
                    disabled={disabled}
                    onChange={() => {
                      changeStateInput();
                    }}
                  />
                </Grid>
                {/* { showInputAcreditaPuntos && ( */}
                <Grid item xs={6} sm={6} md={6}>
                  <InputNumberField
                    name="puntosAcreditar"
                    label="Puntos por acreditar"
                    disabled={disabled || !showInputAcreditaPuntos}
                  />
                </Grid>
                {/* )} */}
                <Grid item xs={12} sm={6} md={4}>
                  <CheckboxField
                    name="ganarAccionPuntos"
                    label="¿Entrega puntos a los ganadores?"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputTextField
                    name="ganarAccionCriterio"
                    label="Criterio para ganar"
                    disabled={disabled}
                  />
                </Grid>
              </Fragment>
            ) : (
              ""
            )}

            {puedeTenerRanking ? (
              <Fragment>
                <Grid item xs={12} sm={6} md={4}>
                  <CheckboxField
                    name="tieneRanking"
                    label="¿Tiene ranking?"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ReduxTypeAhead
                    name="matematicaRanking"
                    label="Matemática para el Ranking"
                    suggestions={{ sug: MATEMATICA_RANKING }}
                    path={{ path: "sug", value: "value", label: "label" }}
                    placeholder="Matemática para el Ranking"
                    validate={
                      permiteMultipleParticipacion ? requiredValidation : null
                    }
                    required={!!permiteMultipleParticipacion}
                    disabled={disabled || !permiteMultipleParticipacion}
                  />
                </Grid>
              </Fragment>
            ) : (
              ""
            )}

            {checkboxMultipleParticipacion ? (
              <Grid item xs={12} sm={6} md={4}>
                <CheckboxField
                  name="permiteMultipleParticipacion"
                  label="Permite más de una participación?"
                  disabled={disabled}
                />
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12} sm={4}>
              <InputNumberField
                name="alturaIframe"
                label="Altura del Iframe"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ListMultipleField
                name={"definicionesIncluidas"}
                label={"Datos a incluir en exportación"}
                options={definicionesPerfil}
                disabled={disabled}
              />
            </Grid>

            <Grid xs={12} item container>
              <Tipo {...this.props} />
            </Grid>
            <Grid xs={12} item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            <Grid item container xs={12} spacing={16}>
              <Variables definicionesPerfil={definicionesPerfil} />
            </Grid>
            <EmailField
              disabled={disabled}
              name={"mailInvitacionAccion"}
              label="Email de invitación"
            />
            <Grid xs={12} item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            <EmailField
              disabled={disabled}
              name={"mailRecordatorio"}
              label="Email de recordatorio"
            />
            <Grid item xs={12} sm={6} md={4}>
              <DateTimePicker
                key="fechaHoraRecordatorio"
                name="fechaHoraRecordatorio"
                label="Fecha de recordatorio"
                disablePast
                minDate={moment(fechaInicio)}
                maxDate={moment(fechaFin)}
                disabled={disabled}
              />
            </Grid>
            <Grid xs={12} item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            <EmailField
              disabled={disabled}
              name={"mailResultado"}
              label="Email con el resultado"
            />
            <ReduxTypeAhead
              name="modoMailResultado"
              label="El mail de resultado se envía a:"
              suggestions={{ sug: MODO_MAIL_RESULTADO }}
              path={{ path: "sug", value: "value", label: "label" }}
              placeholder="El mail de resultado se envía a:"
              required
              disabled={disabled}
            />
            <Grid item xs={12} sm={6} md={4}>
              <DateTimePicker
                key="fechaHoraResultado"
                name="fechaHoraResultado"
                label="Fecha de envío de resultado"
                disablePast
                minDate={moment(fechaFin)}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid container spacing={16}>
          <Grid xs={12} item>
            <Divider classes={{ root: classes.divider }} />
          </Grid>
          {view && <Detalle />}
          <Grid item xs={12} className={classes.gridButton}>
            <Button
              variant="contained"
              size="large"
              onClick={volver}
              className={classes.button}
            >
              Volver
            </Button>
            {!view ? (
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                disabled={
                  submitting ||
                  invalid ||
                  pristine ||
                  ["CANCELADA", "FINALIZADA"].indexOf(initialEstado) > -1
                }
              >
                Guardar
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={goToEdit}
                disabled={
                  ["CANCELADA", "FINALIZADA"].indexOf(initialEstado) > -1
                }
              >
                Editar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withApollo(MyForm);
