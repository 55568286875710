import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/ApiToken/ProgramasTerceros/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/apiToken.resolver";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      idProgramaTercero: this.props.match.params.idProgramaTercero
    };
  }

  componentDidMount() {
    const { idProgramaTercero } = this.state;

    if (idProgramaTercero) {
      this.fetchApiToken();
    } else {
      this.setState({
        initialValues: {
          nombre: "",
          estado: "ACTIVO"
        }
      });
    }
  }

  async fetchApiToken() {
    const { client } = this.props;
    const { idProgramaTercero } = this.state;

    await client
      .query({
        query: AppQraphQL.queries.getApiTokensProgramasTerceros,
        fetchPolicy: "network-only",
        variables: {
          idProgramaTercero
        }
      })
      .then(({ data }) => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            ...data.getApiTokensProgramasTerceros.itemsPagina[0]
          }
        });
      });
  }

  async send(variables, client, mutation) {
    return await client.mutate({
      mutation,
      variables: { apiTokenProgramaTercero: { ...variables } }
    });
  }

  async submit(values) {
    const { client, history, openNotification } = this.props;
    let { id, idProgramaTercero } = this.state;

    const variables = {
      nombre: values.nombre,
      descripcion: values.descripcion,
      estado: values.estado,
      idPrograma: id,
      _id: null
    };

    try {
      let mutation = null;

      if (idProgramaTercero) {
        variables._id = values._id;
        mutation = AppQraphQL.mutations.updateApiTokenProgramaTercero;
      } else {
        mutation = AppQraphQL.mutations.createApiTokenProgramaTercero;
      }

      await this.send(variables, client, mutation);
      openNotification("Guardado.");
      history.push("/admin/programa/21/api-tokens/programas-terceros");
    } catch (errors) {
      const arr = [];
      arr.push({ message: "Error inesperado" });
      this.setState({
        errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
      });
      return false;
    }
  }

  render() {
    return (
      <Form
        id={this.state.id}
        idProgramaTercero={this.state.idProgramaTercero}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
        openNotification={this.props.openNotification}
      />
    );
  }
}

export default Edit;
