import React, { PureComponent } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewListIcon from "@material-ui/icons/ViewList";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import capitalize from "lodash/capitalize";

import Table from "js/components/Shared/Tabla";
import { Monto } from "js/components/Shared/Tabla/TableCells";
import advancedFilter from "js/pages/Admin/Programa/Campana/Tabla/advancedFilter.js";
import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";
import ObjetivoCampanaGraphQL from "js/graphql/resolvers/campanaObjetivo.resolver";
import {
  campanaObjetivosAction,
  editCampanaAction
} from "js/pages/Admin/Programa/Campana/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Campañas",
    btnsNextTitle,
    new: {
      title: "Nueva",
      perms: [Roles.CAMPANA_W]
    },
    export: {
      path: "campanas",
      perms: [Roles.CAMPANA_R]
    },
    import: {
      action: CampanaGraphQL.mutations.importarCampanas,
      perms: [Roles.CAMPANA_W],
      tooltip: "Importar Campañas",
      model: "Modelo_Campanas.csv",
      modelTooltip: "Modelo de importación de campañas",
      variables: {
        idPrograma: id
      }
    },
    queryStringMatcher: {
      idsPrograma: undefined
    },
    others: [
      {
        action: ObjetivoCampanaGraphQL.mutations.importarObjetivosCampanas,
        perms: [Roles.CAMPANA_W],
        tooltip: "Importar Objetivos de campañas",
        model: "Modelo_Objetivos_Campanas.csv",
        type: "import",
        variables: {
          idPrograma: parseInt(id, 10)
        },
        id: "upload-objetivos"
      },
      {
        path: "objetivosCampanas",
        perms: [Roles.CAMPANA_R],
        type: "export",
        tooltip: "Exportar objetivos de campañas"
      },
      {
        path: "performanceCampanas",
        perms: [Roles.CAMPANA_PERFORMANCE_R],
        type: "export",
        tooltip: "Exportar perfromance"
      }
    ]
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "idCampana", label: "ID", removeSort: true },
  { id: "nombre", label: "Nombre campaña" },
  { id: "fechaInicio", label: "Fecha inicio", type: "date" },
  { id: "fechaFin", label: "Fecha fin", type: "date" },
  { id: "fechaCierre", label: "Fecha cierre", type: "date" },
  { id: "grupoProductos_nombre", label: "Grupo productos", removeSort: true },
  {
    id: "puntosTodos",
    label: "Puntos por usuario",
    type: "custom",
    parser: puntos => <Monto value={puntos} />
  },
  {
    id: "tipo",
    label: "Tipo",
    type: "custom",
    parser: tipo => <Estado estado={capitalize(tipo)} />
  },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editCampanaAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CAMPANA_W]
      })
    });

    const objetivos = params => ({
      ...campanaObjetivosAction({
        ...params,
        history: this.props.history,
        perms: [Roles.CAMPANA_W]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Parámetros Generales">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/parametros-campanas`
              )
            }
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_PARAMETROS_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Grupos productos">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/grupos-productos-campanas`
              )
            }
          >
            <ViewListIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_PRODUCTOS_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Targets campañas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/targets-campanas`)
            }
          >
            <TrackChangesIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CAMPANA_TARGET_R]
    });

    return (
      <Table
        storage={"FiltroCampana"}
        query={CampanaGraphQL.queries.getPaginaCampanas}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getCampanas}
        actionsCustom={[edit, objetivos]}
        pathId={"idCampana"}
      />
    );
  }
}

export default Tabla;
