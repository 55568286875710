import React, { PureComponent } from "react";
import moment from "moment";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withApollo } from "react-apollo";

import PerformanceCampanaGraphQL from "js/graphql/resolvers/campanaPerformance.resolver";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import SemiCircularIndicator from "./semiCircularIndicator";
import TrianguloIndicator from "./trianguloIndicator";
import CircularIndicator from "./circularIndicator";
import BarrasVerticalIndicator from "./barrasVerticalIndicator";
import BarrasHorizontalIndicator from "./barrasHorizontalIndicator";

class CampanaPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      infoPagina: [],
      showSplash: false,
      campana: props.campana,
      showProgress: false,
      performanceUsuarios: [],
      page: 1
    };
  }

  getUserName = usuario => {
    if (!usuario) {
      return AuthService.getIdUsuario();
    }
    return usuario.perfil && (usuario.perfil.nombre || usuario.perfil.apellido)
      ? `${usuario.perfil.nombre} ${usuario.perfil.apellido}`
      : usuario.username;
  };

  renderPerformanceIndividual = (
    classes,
    i,
    performanceUsuario,
    showName,
    campana,
    parametrosVisuales,
    isXs
  ) => {
    const nombre = this.getUserName(performanceUsuario.usuario);
    const tipoVisual = parametrosVisuales.tipoVisual;
    const smValue =
      performanceUsuario.performanceObjetivos.length === 1 ? 12 : 6;
    const indicatorClass = smValue === 6 ? classes.indicatorContainer : "";
    return (
      <Grid
        className={isXs ? classes.xsContainer : classes.container}
        item
        xs={12}
        key={i}
      >
        {showName && (
          <div className={classes.usernameTitle}>
            {nombre} {"  "}{" "}
          </div>
        )}
        <Grid
          className={isXs ? classes.xsContainer : classes.container}
          container
          spacing={isXs ? 0 : 16}
        >
          {performanceUsuario.performanceObjetivos.map(
            (performanceObjetivo, j) => {
              if (tipoVisual === "SEMI_CIRCULAR") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <SemiCircularIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "TRIANGULOS") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <TrianguloIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "CIRCULAR") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      margin: "0 auto"
                    }}
                  >
                    <CircularIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "BARRAS_VERTICAL") {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={smValue === 12 ? 8 : 6}
                    key={`container-${nombre}-${i}-${j}`}
                    className={indicatorClass}
                    style={{
                      width: smValue === 12 && !isXs ? "75%" : "100%",
                      margin: smValue === 12 && !isXs ? "0 auto" : "0"
                    }}
                  >
                    <BarrasVerticalIndicator
                      key={`${nombre}-${i}-${j}`}
                      index={`${nombre}-${i}-${j}`}
                      performanceObjetivo={performanceObjetivo}
                      parametrosVisuales={parametrosVisuales}
                      isXs={isXs}
                    />
                  </Grid>
                );
              } else if (tipoVisual === "BARRAS_HORIZONTAL") {
                // Si estamos en mobile (xs), visualmente queda dificil mostrar una barra de avance horizontal
                // por lo tanto se renderiza la visualización vertical
                if (!isXs) {
                  return (
                    <Grid item xs={12} key={`container-${nombre}-${i}-${j}`}>
                      <BarrasHorizontalIndicator
                        key={`${nombre}-${i}-${j}`}
                        index={`${nombre}-${i}-${j}`}
                        performanceObjetivo={performanceObjetivo}
                        parametrosVisuales={parametrosVisuales}
                        isXs={isXs}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={`container-${nombre}-${i}-${j}`}
                      className={indicatorClass}
                      style={{
                        width: "100%",
                        margin: "0"
                      }}
                    >
                      <BarrasVerticalIndicator
                        key={`${nombre}-${i}-${j}`}
                        index={`${nombre}-${i}-${j}`}
                        performanceObjetivo={performanceObjetivo}
                        parametrosVisuales={parametrosVisuales}
                        isXs={isXs}
                      />
                    </Grid>
                  );
                }
              }
              return (
                <Grid item xs={12} key={`container-${nombre}-${i}-${j}`}>
                  <BarrasHorizontalIndicator
                    key={`${nombre}-${i}-${j}`}
                    index={`${nombre}-${i}-${j}`}
                    performanceObjetivo={performanceObjetivo}
                    parametrosVisuales={parametrosVisuales}
                    isXs={isXs}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      </Grid>
    );
  };

  handleCampanaCollapse = () => {
    this.setState(prevState => ({
      expandedCampana: !prevState.expandedCampana
    }));
  };

  hasCompletedObjetivos = objetivos => {
    let completed = true;
    objetivos.map(objetivo => {
      if (objetivo.performance < objetivo.target) {
        completed = false;
      }
    });
    return completed;
  };

  hasCompletedCampana = () => {
    const { performanceCampana, isMiPerformance, isPerformance } = this.props;
    let objetivos = [];
    if (isPerformance) {
      objetivos = performanceCampana.total;
    }

    if (isMiPerformance) {
      objetivos = performanceCampana.individual.itemsPagina[0].objetivos;
    }

    const completed = this.hasCompletedObjetivos(objetivos);
    return completed;
  };

  renderFechaActualizacion(parametrosVisuales, fechaActualizacion) {
    return (
      <div
        style={{
          color: `${parametrosVisuales.colorFechaActualizacion}`,
          fontSize: `${parametrosVisuales.sizeFechaActualizacion}px`,
          fontFamily: `${parametrosVisuales.fontFamily}`,
          fontStyle: "italic"
        }}
      >
        {`${_t("Datos actualizados al ")} ${moment(fechaActualizacion).format(
          parametrosVisuales.formatoFechas
        )}`}
      </div>
    );
  }

  handleSeeMore = () => {
    let { page: newPage } = this.state;
    newPage = newPage + 1;
    this.setState({ showProgress: true, page: newPage }, () => {
      this.getMoreUsersPerformances();
    });
  };

  getMoreUsersPerformances = async () => {
    const { idPrograma, campana } = this.props;
    const { page } = this.state;
    await this.fetchPerformance({
      idPrograma,
      idCampana: campana._id,
      page
    });
  };

  async fetchPerformance(filters) {
    const { client, parametrosVisuales, isPerformance } = this.props;
    const { performanceUsuarios, page } = this.state;

    if (Object.keys(parametrosVisuales).length !== 0) {
      await client
        .query({
          query: isPerformance
            ? PerformanceCampanaGraphQL.queries.getPerformanceCampanas
            : PerformanceCampanaGraphQL.queries.getMiPerformanceCampanas,
          fetchPolicy: "network-only",
          variables: {
            idPrograma: filters.idPrograma,
            idCampana: filters.idCampana,
            idUsuario: filters.idUsuario,
            estado: filters.estado,
            fechaInicioDesde: filters.fechaInicio,
            fechaInicioHasta: filters.fechaInicioHasta,
            page: page,
            rowsPerPage: parametrosVisuales.campanaPorPagina
              ? parametrosVisuales.campanaPorPagina
              : 2
          }
        })
        .then(res => {
          let performances = [];
          let infoPagina = [];

          if (isPerformance) {
            if (res.data.getPerformanceCampanas.itemsPagina.length > 0) {
              performances =
                res.data.getPerformanceCampanas.itemsPagina[0]
                  .performanceUsuarios;
              infoPagina =
                res.data.getPerformanceCampanas.itemsPagina[0].infoPagina;
            }
          } else {
            if (res.data.getMiPerformanceCampanas.itemsPagina.length > 0) {
              performances =
                res.data.getMiPerformanceCampanas.itemsPagina[0]
                  .performanceUsuarios;
              infoPagina =
                res.data.getMiPerformanceCampanas.itemsPagina[0].infoPagina;
            }
          }

          let performanceUpdated = [];
          if (performances.length === 0) {
            performanceUpdated = performances;
          } else {
            performanceUpdated = performanceUsuarios.concat(performances);
          }

          this.setState({
            performanceUsuarios: performanceUpdated,
            infoPagina: infoPagina,
            showSplash: false,
            showProgress: false
          });
        });
    }
  }

  LoadPerformancesUsuarios = () => {
    const { expandedCampana } = this.props;
    const { performanceUsuarios } = this.state;
    if (expandedCampana && performanceUsuarios.length === 0) {
      const { idPrograma, campana, onSearch, idUsuario } = this.props;
      let filter = { idPrograma, idCampana: campana._id };
      if (idUsuario && onSearch) {
        filter.idUsuario = idUsuario;
      }
      this.fetchPerformance(filter);
    }
  };

  render() {
    const {
      classes,
      parametrosVisuales,
      isPerformance,
      campana,
      expandedCampana
    } = this.props;
    const { showProgress, performanceUsuarios, infoPagina } = this.state;

    this.LoadPerformancesUsuarios();

    const fechaActualizacion = campana.fecha;

    const isXs = window.innerWidth < 600 ? true : false;

    return (
      <>
        {parametrosVisuales.posicionFechaActualizacion === "ANTES_DESC" &&
          moment(fechaActualizacion).isValid() &&
          this.renderFechaActualizacion(parametrosVisuales, fechaActualizacion)}
        <Grid item xs={12}>
          <p
            style={{
              color: `${parametrosVisuales.colorDescripcionCampana}`,
              fontSize: `${parametrosVisuales.sizeDescripcionCampana}px`,
              fontFamily: `${parametrosVisuales.fontFamily}`
            }}
          >
            {campana.descripcion}
          </p>
        </Grid>

        {parametrosVisuales.posicionFechaActualizacion === "ANTES_CHART" &&
          moment(fechaActualizacion).isValid() &&
          this.renderFechaActualizacion(parametrosVisuales, fechaActualizacion)}
        {expandedCampana &&
          performanceUsuarios.length === 0 && (
            <Grid className={classes.seeMoreContainer} item xs={12}>
              <CircularProgress
                size={50}
                thickness={4}
                color="primary"
                variant="indeterminate"
              />
            </Grid>
          )}
        {performanceUsuarios.map((performanceUsuario, i) => {
          return this.renderPerformanceIndividual(
            classes,
            `${campana._id}-${i}`,
            performanceUsuario,
            isPerformance ? true : false,
            campana,
            parametrosVisuales,
            isXs
          );
        })}
        {isPerformance &&
          performanceUsuarios.length < infoPagina.total && (
            <Grid className={classes.seeMoreContainer} item xs={12}>
              <Button
                type="button"
                variant="outlined"
                size="large"
                color="primary"
                style={{
                  minWidth: "250px",
                  maxWidth: "500px",
                  marginBottom: "20px"
                }}
                onClick={this.handleSeeMore}
              >
                {showProgress && (
                  <CircularProgress
                    size={20}
                    thickness={4}
                    color="primary"
                    variant="indeterminate"
                  />
                )}
                {!showProgress && _t("Ver más performance de usuarios")}
              </Button>
            </Grid>
          )}
        {(parametrosVisuales.posicionFechaActualizacion ===
          "DEBAJO_CHART_DERECHA" ||
          parametrosVisuales.posicionFechaActualizacion ===
            "DEBAJO_CHART_IZQUIERDA") &&
          moment(fechaActualizacion).isValid() && (
            <Grid item xs={12}>
              {this.renderFechaActualizacion(
                parametrosVisuales,
                fechaActualizacion
              )}
            </Grid>
          )}
      </>
    );
  }
}

export default withApollo(CampanaPanel);
