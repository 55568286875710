import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/ApiToken/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/apiToken.resolver";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      idToken: this.props.match.params.idToken
    };
  }

  componentDidMount() {
    const { idToken } = this.state;

    if (idToken !== "new") {
      this.fetchApiToken();
    } else {
      this.setState({
        initialValues: {
          estado: "ACTIVO",
          tieneProgramaTercero: false
        }
      });
      this.getApiTokensProgramasTerceros();
    }
  }

  async fetchApiToken() {
    const { client } = this.props;
    const { idToken } = this.state;

    await client
      .query({
        query: AppQraphQL.queries.getApiTokenById,
        fetchPolicy: "network-only",
        variables: {
          apiTokenLike: {
            idToken
          }
        }
      })
      .then(async res => {
        const apiToken = res.data.getApiTokens.itemsPagina[0];
        this.setState({
          initialValues: {
            ...apiToken,
            tieneProgramaTercero: !!apiToken.idProgramaTercero
          }
        });
        await this.getApiTokensProgramasTerceros();
      });
  }

  async getApiTokensProgramasTerceros() {
    const { client } = this.props;
    const { idPrograma } = this.state;

    await client
      .query({
        query: AppQraphQL.queries.getApiTokensProgramasTerceros,
        fetchPolicy: "network-only",
        variables: {
          apiTokenProgramaTerceroLike: {
            idPrograma
          }
        }
      })
      .then(({ data }) => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            programasTercerosPagina:
              data.getApiTokensProgramasTerceros.itemsPagina
          }
        });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    let { id, idToken, initialValues } = this.state;

    const variables = {
      nombre: values.nombre,
      descripcion: values.descripcion,
      estado: values.estado,
      tieneProgramaTercero: initialValues.tieneProgramaTercero,
      idProgramaTercero: null,
      programaTerceroNombre: null
    };

    if (variables.tieneProgramaTercero) {
      if (initialValues.programaTercero._id === "Seleccionar")
        return this.props.openNotification(
          "Debe seleccionar un Programa Tercero"
        );
      variables.idProgramaTercero = initialValues.programaTercero._id || null;
    }

    if (idToken !== "new") {
      variables.idToken = idToken;
    } else {
      variables.idPrograma = Number(id);
    }

    delete variables.programaTerceroNombre;
    delete variables.tieneProgramaTercero;

    await client
      .mutate({
        mutation:
          idToken === "new"
            ? AppQraphQL.mutations.createApiToken
            : AppQraphQL.mutations.updateApiToken,
        variables: {
          apiToken: variables
        }
      })
      .then(async res => {
        const mensaje =
          "Api token " +
          (idToken === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);

        idToken === "new"
          ? (idToken = res.data.createApiToken.idToken)
          : (idToken = res.data.updateApiToken.idToken);

        this.setState({ idToken });

        if (idToken !== "new") await this.fetchApiToken();
        // history.push("/admin/programa/21/api-tokens");
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  handleTieneProgramaTerceroChange = val => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        tieneProgramaTercero: val
      }
    });
  };

  handleProgramaTerceroAsociadoChange = value => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        programaTercero: {
          _id: value
        }
      }
    });
  };

  render() {
    const isEmpty = Object.keys(this.state.initialValues).length === 0;
    if (isEmpty) return <></>;

    return (
      <Form
        id={this.state.id}
        idToken={this.state.idToken}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
        handleChangeFecha={this.handleDateChange}
        handleChangeTieneProgramaTercero={this.handleTieneProgramaTerceroChange}
        handleChangeProgramaTerceroAsociado={
          this.handleProgramaTerceroAsociadoChange
        }
        openNotification={this.props.openNotification}
      />
    );
  }
}

export default Edit;
