import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import {
  Facebook,
  Twitter,
  Instagram,
  Whatsapp,
  Linkedin,
  Youtube,
  Google,
  Snapchat,
  Pinterest,
  Tumblr,
  Skype,
  Yahoo,
  Telegram,
  Spotify,
  Slack,
  Yammer
} from "mdi-material-ui";
import Enhancer from "./container";
import { TypeAhead } from "js/components/Shared/Form/Fields";

const socialIcons = {
  facebook: <Facebook style={{ color: "#3b5998" }} />,
  twitter: <Twitter style={{ color: "#1da1f2" }} />,
  instagram: <Instagram style={{ color: "#c32aa3" }} />,
  whatsapp: <Whatsapp style={{ color: "#25d366" }} />,
  linkedin: <Linkedin style={{ color: "#007bb5" }} />,
  youtube: <Youtube style={{ color: "#ff0000" }} />,
  google: <Google style={{ color: "#4285f4" }} />,
  snapchat: <Snapchat style={{ color: "#fffc00" }} />,
  pinterest: <Pinterest style={{ color: "#bd081c" }} />,
  tumblr: <Tumblr style={{ color: "#35465d" }} />,
  skype: <Skype style={{ color: "#00aff0" }} />,
  yahoo: <Yahoo style={{ color: "#430297" }} />,
  telegram: <Telegram style={{ color: "#0088cc" }} />,
  spotify: <Spotify style={{ color: "#1ed760" }} />,
  slack: <Slack style={{ color: "#4a154b" }} />,
  yammer: <Yammer style={{ color: "#0078d4" }} />
};

const RedesSociales = ({
  redesDisponibles,
  redesSeleccionadas,
  onChange,
  onEdit,
  onRemove,
  classes
}) => (
  <Grid item xs={12}>
    {redesDisponibles.length > 0 && (
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.redes}
      >
        <Grid item xs={12} sm={6}>
          <TypeAhead
            label={"Más redes"}
            onChange={onChange}
            path={{ path: "redes", value: "red", label: "label" }}
            placeholder={"Más redes"}
            suggestions={{
              redes: redesDisponibles.map(({ red }) => ({
                red,
                label: capitalize(red)
              }))
            }}
          />
          <FormHelperText>Agregar más redes sociales</FormHelperText>
        </Grid>
      </Grid>
    )}
    <Grid container spacing={16}>
      {redesSeleccionadas &&
        redesSeleccionadas.map(rs => (
          <Grid item xs={12} sm={9}>
            <FormControl
              className={classNames(classes.margin, classes.textField)}
            >
              <Input
                id="adornment-link"
                type="text"
                value={rs.link}
                onChange={onEdit(rs.red)}
                placeholder="Link"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>{socialIcons[rs.red]}</IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <CloseIcon onClick={() => onRemove(rs.red)} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        ))}
    </Grid>
  </Grid>
);

RedesSociales.displayName = "RedesSociales";

export default Enhancer(RedesSociales);
