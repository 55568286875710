export default [
  {
    link: "",
    red: "facebook"
  },
  {
    link: "",
    red: "twitter"
  },
  {
    link: "",
    red: "instagram"
  },
  {
    link: "",
    red: "whatsapp"
  },
  {
    link: "",
    red: "linkedin"
  },
  {
    link: "",
    red: "youtube"
  },
  {
    link: "",
    red: "google"
  },
  {
    link: "",
    red: "snapchat"
  },
  {
    link: "",
    red: "pinterest"
  },
  {
    link: "",
    red: "tumblr"
  },
  {
    link: "",
    red: "skype"
  },
  {
    link: "",
    red: "yahoo"
  },
  {
    link: "",
    red: "telegram"
  },
  {
    link: "",
    red: "spotify"
  },
  {
    link: "",
    red: "slack"
  },
  {
    link: "",
    red: "yammer"
  }
];
