import gql from "graphql-tag";

export const Log = {};

Log.fragments = {
  log: gql`
    fragment log on LogImportacion {
      _id
      fecha
      estado
      concepto
      usuario {
        username
        programa {
          idPrograma
          nombreFantasia
        }
      }
    }
  `,
  logSolo: gql`
    fragment logSolo on LogImportacion {
      concepto
      fecha
      usuario {
        username
      }
      lineasProcesadas
      total
      estado
      nombreArchivo
      usuariosNuevos
      usuariosInactivados
      errores {
        linea
        error
        programa {
          nombreFantasia
        }
        usuario {
          username
        }
      }
    }
  `,
  miLog: gql`
    fragment miLog on LogImportacion {
      _id
      fecha
      estado
      concepto
      usuario {
        username
      }
    }
  `,
  miLogSolo: gql`
    fragment miLogSolo on LogImportacion {
      concepto
      fecha
      usuario {
        username
      }
      lineasProcesadas
      total
      estado
      nombreArchivo
      usuariosNuevos
      usuariosInactivados
      errores {
        linea
        error
        usuario {
          username
        }
      }
    }
  `
};

export default Log;
