import gql from "graphql-tag";
import Fragmentos from "../fragments";

const { Canje } = Fragmentos;

export const canjeEfectuado = gql`
  query misCanjes(
    $canjeLike: CanjeSearch
    $canjeOrder: CanjeOrder
    $canjeRange: CanjeRange
    $pagina: Pagina
  ) {
    misCanjes(
      canjeLike: $canjeLike
      canjeOrder: $canjeOrder
      canjeRange: $canjeRange
      pagina: $pagina
    ) {
      itemsPagina {
        ...itemsPaginaCanje
      }
    }
  }
  ${Canje.fragments.canjeEfectuado}
`;

// "order": "desc",
// "orderBy": "fecha",
// "page": 1,
// "rowsPerPage": 10,
// "estado": "ACTIVO",
// "idPrograma": 1,
// "excluirFactura": false,
// "excluirCanje": false,
// "administracion": "ASHIWEA",
// "idProducto": 22
export const getCanjes = gql`
  query getCanjes(
    $order: Order = asc
    $orderBy: CanjeColumn = fecha
    $page: Int = 1
    $rowsPerPage: Int!
    $estadoCanje: [EstadoCanje]
    $idsProgramas: [Int]
    $idUsuario: Int
    $excluirFactura: Boolean
    $excluirCanje: Boolean
    $excluirCanceladosYPausados: Boolean
    $administracion: Gestion
    $idProducto: Int
    $fechaDesde: Date
    $fechaHasta: Date
    $idCanje: Int
    $idLote: String
    $idPais: Int
    $idFactura: Int
    $idProveedor: Int
    $tipoProducto: TipoProducto
    $tipoPrograma: String
  ) {
    getCanjes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      canjeRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      canjeOrder: { column: $orderBy, order: $order }
      canjeLike: {
        eleccionProducto_idProducto: $idProducto
        eleccionProducto_idPrograma: $idsProgramas
        estado: $estadoCanje
        numeroLote: $idLote
        excluirFacturados: $excluirFactura
        excluirCanceladosYPausados: $excluirCanceladosYPausados
        excluirLoteados: $excluirCanje
        eleccionProducto_producto_gestionadoPor: $administracion
        eleccionProducto_producto_tipoProducto: $tipoProducto
        idCanje: $idCanje
        idPais: $idPais
        idFactura: $idFactura
        idProveedor: $idProveedor
        idUsuario: $idUsuario
        tipoPrograma: $tipoPrograma
      }
    ) {
      ...canjes
    }
  }
  ${Canje.fragments.canjes}
`;

export const getCanjesFront = gql`
  query getCanjes(
    $order: Order = asc
    $orderBy: CanjeColumn = fecha
    $page: Int = 1
    $rowsPerPage: Int!
    $estadoCanje: [EstadoCanje]
    $idsProgramas: [Int]
    $idUsuario: Int
    $excluirCanceladosYPausados: Boolean
    $idProducto: Int
    $fechaDesde: Date
    $fechaHasta: Date
    $idCanje: Int
  ) {
    getCanjes(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      canjeRange: { fecha: { min: $fechaDesde, max: $fechaHasta } }
      canjeOrder: { column: $orderBy, order: $order }
      canjeLike: {
        estado: $estadoCanje
        eleccionProducto_idPrograma: $idsProgramas
        idUsuario: $idUsuario
        excluirCanceladosYPausados: $excluirCanceladosYPausados
        eleccionProducto_idProducto: $idProducto
        idCanje: $idCanje
      }
    ) {
      ...canjesFront
    }
  }
  ${Canje.fragments.canjesFront}
`;

// [{idCanje:1, fechaDeEntrega: 22/11/2018},{idCanje:2, fechaDeEntrega:22/11/2018}]
export const bulkUpdateCanje = gql`
  mutation bulkUpdateCanje($canjes: [CanjeUpdate!]!) {
    bulkUpdateCanje(canjes: $canjes) {
      idCanje
    }
  }
`;

export const updateCanje = gql`
  mutation updateCanje(
    $idCanje: Int!
    $estadoCanje: EstadoCanje
    $fechaEntrega: Date
    $costoReal: Float
    $idFactura: Int
    $deslotear: Boolean
    $motivoCancelacion: String
  ) {
    updateCanje(
      canje: {
        idCanje: $idCanje
        estado: $estadoCanje
        fechaEntrega: $fechaEntrega
        costoReal: $costoReal
        deslotear: $deslotear
        idFactura: $idFactura
        motivoCancelacion: $motivoCancelacion
      }
    ) {
      idCanje
    }
  }
`;

export const canjearProducto = gql`
  mutation canjearProducto($canje: CanjeInput!) {
    canjearProducto(canje: $canje) {
      ...canjear
    }
  }
  ${Canje.fragments.canjear}
`;

export const getCanje = gql`
  query getCanje(
    $idCanje: Int
    $canjeRange: CanjeRange
    $canjeOrder: CanjeOrder
  ) {
    getCanjes(
      canjeRange: $canjeRange
      canjeOrder: $canjeOrder
      canjeLike: { idCanje: $idCanje }
    ) {
      ...canje
    }
  }
  ${Canje.fragments.canje}
`;

export const getCanjeFront = gql`
  query getCanje(
    $idCanje: Int
    $canjeRange: CanjeRange
    $canjeOrder: CanjeOrder
  ) {
    getCanjes(
      canjeRange: $canjeRange
      canjeOrder: $canjeOrder
      canjeLike: { idCanje: $idCanje }
    ) {
      ...canjeFront
    }
  }
  ${Canje.fragments.canjeFront}
`;

export default {
  queries: {
    canjeEfectuado,
    getCanjes,
    getCanjesFront,
    getCanje,
    getCanjeFront
  },
  mutations: {
    canjearProducto,
    bulkUpdateCanje,
    updateCanje
  }
};
