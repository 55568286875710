import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/usuario.resolver";
import ProgramaPerfil from "js/pages/Profile";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";

class EditPerfil extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idPrograma: null
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idUsuario }
      }
    } = this.props;

    let finalIdUsuario = idUsuario;
    if (!finalIdUsuario) {
      finalIdUsuario = this.props.computedMatch.params.idUsuario;
    }

    await client
      .query({
        query: AppQraphQL.queries.getUsuarioById,
        fetchPolicy: "cache-first",
        variables: {
          idUsuario: finalIdUsuario
        }
      })
      .then(res => {
        this.setState({
          idPrograma: res.data.getUsuarios.itemsPagina[0].programa.idPrograma,
          programa: res.data.getUsuarios.itemsPagina[0].programa.nombre,
          idUsuario: res.data.getUsuarios.itemsPagina[0].idUsuario,
          username: res.data.getUsuarios.itemsPagina[0].username,
          editable:
            AuthService.hasPerms([Roles.PERFIL_W]) ||
            AuthService.getIdUsuario() === idUsuario
        });
      });
  }

  render() {
    if (!this.state.username) return null;
    return (
      <ProgramaPerfil
        idProgramaSelected={this.state.idPrograma}
        idUsuarioSelected={this.state.idUsuario}
        usernameSelected={this.state.username}
        programaSelected={this.state.programa}
        showBox={false}
        disabled={!this.state.editable}
        showAllCampos={true}
      />
    );
  }
}

export default withApollo(withRouter(EditPerfil));
