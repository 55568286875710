import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import { styles } from "js/components/Shared/TablaFiltro/styles.js";
import { _t } from "js/utils/TranslationService";
import FacturaPorPuntosQraphQL from "js/graphql/resolvers/parametrosCargaFacturaPorPuntos.resolver";
import client from "../../../../../App/client.graphql";
import LabeledAutocomplete from "../../../../../components/Shared/Form/Fields/LabeledAutocomplete/";

class advancedFilter extends PureComponent {
  constructor(props) {
    super(props);
    const { idPrograma } = this.props;
    this.state = {
      puntosDeVenta: []
    };
  }

  componentDidMount = async () => {
    const { idPrograma } = this.props;

    await this.getPuntosDeVenta(idPrograma, client);
  };

  getPuntosDeVenta = async (id, client) => {
    let puntosDeVenta = [];

    let data = await client.query({
      query: FacturaPorPuntosQraphQL.queries.getParametrosFacturaPorPuntosById,
      variables: {
        idPrograma: id || localStorage.getItem("idPrograma")
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    if (data) {
      let {
        data: { getParametrosFacturaPorPuntos: params }
      } = data;

      let parametroPuntoDeVenta = params.find(param => {
        return param.key === "puntoDeVenta";
      });

      if (parametroPuntoDeVenta) {
        let { value: puntosDeVentaUpdated } = parametroPuntoDeVenta;

        puntosDeVenta = puntosDeVentaUpdated.map(puntoDeVenta => {
          return {
            value: puntoDeVenta,
            label: puntoDeVenta
          };
        });
      }
    }
    this.setState({ puntosDeVenta });
  };

  submitAdvanceFilter = e => {
    const { submitValue } = this.props;

    let puntoDeVenta = {
      target: {
        name: "puntoVenta",
        value: e.value
      }
    };
    submitValue(puntoDeVenta);
  };

  getAutocompleteValue = (data, stateValue) =>
    stateValue !== undefined
      ? data.find(p => p.value === stateValue)
      : { value: undefined, label: "" };

  render() {
    const { value, submitValue, classes } = this.props;
    const { puntosDeVenta } = this.state;

    const isAdminUser = AuthService.hasPerms([
      Roles.MODERACION_FACTURA_POR_PUNTOS_W
    ]);

    return (
      <Grid container spacing={16}>
        {isAdminUser ? (
          <>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  key={"usuario"}
                  name={"usuario"}
                  label={_t("Usuario solicitante")}
                  type="search"
                  value={value["usuario"] || ""}
                  onChange={e => submitValue(e)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  key={"usuarioModerador"}
                  name={"usuarioModerador"}
                  label={_t("Moderador")}
                  type="search"
                  value={value["usuarioModerador"] || ""}
                  onChange={e => submitValue(e)}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionDesde"}
              name={"fechaCreacionDesde"}
              label={
                isAdminUser
                  ? "Fecha de creación (Desde)"
                  : "Fecha de envío (Desde)"
              }
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionDesde",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionDesde"]
                  ? moment(value["fechaCreacionDesde"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <DatePicker
              key={"fechaCreacionHasta"}
              name={"fechaCreacionHasta"}
              label={
                isAdminUser
                  ? "Fecha de creación (Hasta)"
                  : "Fecha de envío (Hasta)"
              }
              input={{
                onChange: date =>
                  submitValue({
                    target: {
                      name: "fechaCreacionHasta",
                      value: date.toISOString()
                    }
                  }),
                value: value["fechaCreacionHasta"]
                  ? moment(value["fechaCreacionHasta"])
                  : null
              }}
              format={"DD/MM/YYYY"}
              autoOk={true}
              startOfDate
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            className={classes.formControl}
            style={{ marginTop: "4px" }}
          >
            <InputLabel htmlFor="estado-simple" id="estado-simple">
              Estado
            </InputLabel>
            <Select
              value={value["estado"] || ""}
              onChange={e => submitValue(e)}
              inputProps={{
                name: "estado",
                id: "estado"
              }}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              <MenuItem value={"RECHAZADA"}>Rechazada</MenuItem>
              <MenuItem value={"APROBADA"}>Aprobada</MenuItem>
              <MenuItem value={"PENDIENTE_APROBACION"}>
                Pendiente de aprobación
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <LabeledAutocomplete
              label={"Punto de venta"}
              isDisabled={false}
              onChange={e => this.submitAdvanceFilter(e)}
              noOptionsMessage={() => "No hay puntos de venta"}
              placeholder="Buscar por punto de venta"
              suggestions={puntosDeVenta}
              value={this.getAutocompleteValue(
                puntosDeVenta,
                value["puntoVenta"]
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

advancedFilter.propTypes = {
  value: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(advancedFilter);
