import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import UserQraphQL from "js/graphql/resolvers/programa.resolver";
import AccessQraphQL from "js/components/Access/Access.graphql.js";
import DebounceLink from "apollo-link-debounce";
import { createUploadLink } from "apollo-upload-client";

import { graphqlURL } from "js/constants";

const DEBOUNCE_TIMEOUT = 500;

const cache = new InMemoryCache();

// By settings defaults, we don't need to define explicit Query operations
const defaults = {
  // user: AccessQraphQL.defaults.userDefaults
};

const resolvers = {
  Mutation: {
    writeUser: AccessQraphQL.mutations.writeUser
  },
  Query: { user: UserQraphQL.queries.getUser }
};

const debounceLink = new DebounceLink(DEBOUNCE_TIMEOUT);
const stateLink = withClientState({
  cache,
  defaults,
  resolvers
});
const createApLink = createUploadLink({
  uri: graphqlURL,
  credentials: "include"
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([debounceLink, stateLink, createApLink])
});

export default client;
