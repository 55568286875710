import gql from "graphql-tag";


export const getCodigosActivacionByProducto = gql`
  query getCodigosActivacion($idProducto: Int!) {
    getCodigosActivacion(
      codigoActivacionLike: { idProducto: $idProducto }
    ) {
        codigo
        estado
    }
  }
`;

export default {
  queries: {
    getCodigosActivacionByProducto,
  }
};
