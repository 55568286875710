import React from "react";
import PropTypes from "prop-types";
import FontPicker from "font-picker-react";
import Enhancer from "./container";

const FontPickerCustom = ({ onChange, classes, activeFont }) => (
  <FontPicker
    apiKey="AIzaSyDm8xG6mYjOimJOXRrdQh-NTkQXAUknlGc"
    activeFont={activeFont}
    onChange={nextFont => onChange(nextFont.family)}
  />
);

FontPickerCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeFont: PropTypes.string,
  classes: PropTypes.object
};

FontPickerCustom.defaultProps = {
  activeFont: "Arial"
};

FontPickerCustom.displayName = "FontPickerCustom";

export default Enhancer(FontPickerCustom);
