import React, { PureComponent, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import Hidden from "@material-ui/core/Hidden";
import "./styles.css";
import {
  TicketPromedio,
  TicketPromedioPDF,
  TransaccionesGlobales,
  TransaccionesGlobalesPDF,
  TransaccionesPorSucursal,
  Genero,
  RangoEtario
} from "./Reportes";
import upperCase from "lodash/upperCase";
import { numberWithCommas } from "js/utils";
import _ from "lodash";
import { PictureAsPdf, CloudDownload } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import AppQraphQL from "../../../js/graphql/resolvers/programa.resolver";
import client from "../../../js/App/client.graphql";
import { getProgramaPublicInfo } from "../../App/utils";

import FileSaver from "file-saver";
import XLSX from "sheetjs-style";

class ReportesCLN extends PureComponent {
  constructor(props) {
    super(props);
    this.componentRef = createRef();
    this.state = {
      logo: {},
      onPrint: false,
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false
    };
  }

  componentDidMount = async () => {
    const url = window.location.hostname.replace("www.", "");

    await client
      .query({
        query: AppQraphQL.queries.getProgramaPublicInfo,
        variables: { url },
        errorPolicy: "all",
        fetchPolicy: "cache-first"
      })
      .then(({ data }) => {
        if (data) {
          const { template } = getProgramaPublicInfo(data);

          this.setState({ logo: template.header.logoPrincipal });
        }
      });
  };

  handleOnBeforeGetContent = () => {
    return new Promise(resolve => {
      this.setState({ onPrint: true }, resolve);
    });
  };

  handleBeforePrint = () => {
    return new Promise(resolve => {
      this.setState({ onPrint: false }, resolve);
    });
  };

  handleDownloadXLS = () => {
    const { data } = this.props;

    const { transaccionesPorSucursal, ticketPromedio } = data;
    const {
      info: { comercio }
    } = this.props;

    const sortYearMonth = (a, b) => {
      const [yearA, monthA] = a.split("/");
      const [yearB, monthB] = b.split("/");
      return yearA - yearB || monthA - monthB;
    };

    let exportData = _(transaccionesPorSucursal)
      .groupBy("sucursal.idSucursal")
      .map(items => {
        const nombre = items[0].sucursal.nombre;
        const transaccionesPorAnoMes = items.reduce((acc, item) => {
          const key = `${item.anoMes
            .toString()
            .substring(0, 4)}/${item.anoMes.toString().substring(4, 6)}`;
          acc[key] = (acc[key] || 0) + item.transacciones;
          return acc;
        }, {});

        const totalGralTransacciones = _.sumBy(items, "transacciones");

        return {
          "Nombre de Sucursal": nombre,
          ...transaccionesPorAnoMes,
          Total: totalGralTransacciones
        };
      })
      .value();

    let transaccionesGlobales = _.transform(
      transaccionesPorSucursal,
      (result, transaccion) => {
        if (transaccion.anoMes) {
          const anoMesFormateado = `${transaccion.anoMes
            .toString()
            .substring(0, 4)}/${transaccion.anoMes.toString().substring(4, 6)}`;
          result[anoMesFormateado] =
            (result[anoMesFormateado] || 0) + transaccion.transacciones;
        }
        result["Total"] = (result["Total"] || 0) + transaccion.transacciones;
        result["Nombre de Sucursal"] = "Transacciones Globales";
      },
      {}
    );

    let ticketsPromedio = _.transform(
      ticketPromedio,
      (result, ticket) => {
        if (ticket.anoMes) {
          const anoMesFormateado = `${ticket.anoMes
            .toString()
            .substring(0, 4)}/${ticket.anoMes.toString().substring(4, 6)}`;
          result[anoMesFormateado] =
            (result[anoMesFormateado] || 0) + ticket.ticketPromedio;
        }
        result["Total"] = (result["Total"] || 0) + ticket.ticketPromedio;
        result["Nombre de Sucursal"] = "Ticket Promedio";
      },
      {}
    );

    exportData.push(transaccionesGlobales);
    exportData.push(ticketsPromedio);

    const allKeys = [...new Set(exportData.flatMap(Object.keys))];
    const yearMonthKeys = allKeys
      .filter(key => /^\d{4}\/\d{2}$/.test(key))
      .sort(sortYearMonth);
    const orderedKeys = ["Nombre de Sucursal", ...yearMonthKeys, "Total"];

    const orderedData = exportData.map(item => {
      const newItem = {};
      orderedKeys.forEach(key => {
        newItem[key] = item[key] || 0;
      });
      return newItem;
    });

    const fileName = `Reporte_CLN_${data.comercio.username}_${moment().format(
      "YYYYMMDDHHmmss"
    )}`;

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(orderedData);

    const wb = { Sheets: { [`${comercio}`]: ws }, SheetNames: [comercio] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array"
    });

    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, fileName + fileExtension);
  };

  render() {
    const {
      data: {
        genero,
        rangoEtario,
        transaccionesPorSucursal,
        ticketPromedio,
        transaccionesGlobales
      },
      data,
      loading,
      variables: { fechaDesde, fechaHasta },
      info: { comercio, objetivo, lastMonth },
      info,
      onChange,
      classes
    } = this.props;

    const { logo, onPrint, inBackOffice } = this.state;

    const { url } = logo;

    const pageStyle = `
    @page {
      size: A4;
      margin: 0;
    }
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      .page-break {
        display: block;
        page-break-before: always;
      }
    }
  `;

    return (
      <Grid
        xs={12}
        container
        spacing={16}
        className={`${classes.contenedorCLN} ${classes.fondoNegro}`}
        justify={"center"}
        style={{
          maxWidth: "100%",
          borderRadius: "0px",
          paddingTop: "15px"
        }}
        ref={this.componentRef}
      >
        <Grid item container xs={onPrint ? 12 : 10}>
          <Grid xs={12} sm={6} item style={{ margin: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              {onPrint && !inBackOffice && <img alt="Logo" src={url} />}
              <Typography
                variant={!onPrint ? "h3" : "h4"}
                color="grey"
                className={classes.contenedorCLN}
                classes={{ root: classes.textoTituloComercio }}
                style={{ marginTop: "2%", marginLeft: onPrint ? "2%" : "0" }}
              >
                {comercio}
              </Typography>
            </div>
            <div className="no-print">
              <IconButton onClick={this.handleDownloadXLS}>
                <Tooltip title="Exportar transacciones por sucursal">
                  <CloudDownload
                    className={`${classes.contenedorCLN} ${classes.textoNegro}`}
                  />
                </Tooltip>
              </IconButton>
              <ReactToPrint
                content={() => this.componentRef.current}
                ref={el => {
                  this.ReactToPrintRef = el && el.handlePrint;
                }}
                onBeforeGetContent={this.handleOnBeforeGetContent}
                onBeforePrint={this.handleBeforePrint}
                pageStyle={pageStyle}
              >
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <IconButton onClick={handlePrint}>
                      <Tooltip title="Exportar reporte PDF">
                        <PictureAsPdf
                          className={`${classes.contenedorCLN} ${
                            classes.textoNegro
                          }`}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            item
            container
            spacing={24}
            className={classes.contenedorCLN}
          >
            <Grid xs={12} sm={6} item>
              <Grid xs={12} item>
                <Typography
                  variant="subtitle1"
                  color="#414141"
                  className={`${classes.contenedorCLN} ${classes.textoPeriodo}`}
                >
                  PERÍODO:
                </Typography>
              </Grid>
              <Grid xs={12} item container>
                <Grid xs={4} item>
                  <Typography
                    variant="subtitle1"
                    color="#414141"
                    // eslint-disable-next-line prettier/prettier
                    className={`${classes.contenedorCLN} ${
                      classes.textoDatePicker
                    }`}
                    classes={{ root: classes.textoDatePicker }}
                  >
                    DESDE
                  </Typography>
                </Grid>
                <Grid xs={8} item>
                  <Typography
                    variant="subtitle1"
                    className={classes.textoDinamicoFecha}
                  >
                    <DatePicker
                      key="min"
                      name="min"
                      dateFormat="MMMM YYYY"
                      maxDate={moment()}
                      views={["year", "month"]}
                      openTo="year"
                      startOfDate
                      input={{
                        onChange: onChange("fechaDesde"),
                        value: moment(fechaDesde, "YYYYMM")
                      }}
                      className={`${classes.datePicker} ${
                        classes.contenedorCLN
                      }`}
                      InputProps={{
                        className:
                          classes.input +
                          " " +
                          classes.contenedorCLN +
                          classes.textoDinamicoFecha
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} item container>
                <Grid xs={4} item>
                  <Typography
                    variant="subtitle1"
                    color="#414141"
                    className={classes.contenedorCLN}
                    classes={{ root: classes.textoDatePicker }}
                  >
                    HASTA
                  </Typography>
                </Grid>
                <Grid xs={8} item>
                  <Typography
                    variant="subtitle1"
                    className={classes.textoDinamicoFecha}
                  >
                    <DatePicker
                      key="max"
                      name="max"
                      dateFormat="MMMM YYYY"
                      maxDate={moment()}
                      views={["year", "month"]}
                      openTo="year"
                      startOfDate
                      className={`${classes.datePicker} ${
                        classes.contenedorCLN
                      }`}
                      input={{
                        onChange: onChange("fechaHasta"),
                        value: moment(fechaHasta, "YYYYMM")
                      }}
                      InputProps={{
                        className: classes.input + " " + classes.contenedorCLN
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <div className={classes.verticalDivider} />
            </Hidden>
            <Grid xs={12} sm={5} item>
              <Typography
                variant="subtitle1"
                color="black"
                className={classes.textoFijoObjetivo}
              >
                {lastMonth &&
                  objetivo &&
                  `OBJETIVO ${upperCase(
                    moment(lastMonth, "MM/YYYY").format("MMMM YYYY")
                  )}`}
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                className={classes.contenedorCLN}
                classes={{ root: classes.textoDinamicoObjetivo }}
              >
                {lastMonth &&
                  objetivo &&
                  `${numberWithCommas(objetivo) || "--"} transacciones`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!loading && (
          <Fragment>
            <Grid
              xs={12}
              sm={10}
              container
              item
              spacing={16}
              className={classes.contenedorCards}
            >
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: onPrint ? "none" : "" }}
              >
                <Card raised={true}>
                  <TransaccionesGlobales
                    data={transaccionesGlobales}
                    info={info}
                    classes={classes}
                  />
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: onPrint ? "none" : "" }}
              >
                <Card raised={true}>
                  <TicketPromedio data={ticketPromedio} classes={classes} />
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: onPrint ? "" : "none" }}
              >
                <Card raised={true}>
                  <TransaccionesGlobalesPDF
                    data={transaccionesGlobales}
                    info={info}
                    classes={classes}
                  />
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: onPrint ? "" : "none" }}
              >
                <Card raised={true}>
                  <TicketPromedioPDF data={ticketPromedio} classes={classes} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TransaccionesPorSucursal
                  data={transaccionesPorSucursal}
                  classes={classes}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              item
              spacing={16}
              className={`classes.fondoBlanco page-break`}
              justify={"center"}
              style={{ padding: 0 }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                className={classes.fondoBlanco}
                container
                justify={"center"}
              >
                <Grid item xs={12} container justify={"center"}>
                  <Typography
                    variant="h6"
                    color={"primary"}
                    className={classes.titulosInferiores}
                  >
                    Género
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                  <Genero data={genero} classes={classes} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                className={classes.fondoBlanco}
                container
                pageStyle={".divider { break-after: always; }"}
              >
                <Typography
                  variant="h6"
                  color={"primary"}
                  className={classes.titulosInferiores}
                >
                  Rango etario
                </Typography>
                <RangoEtario data={rangoEtario} classes={classes} />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

ReportesCLN.propTypes = {
  classes: PropTypes.object
};

ReportesCLN.defaultProps = {
  data: PropTypes.any
};

ReportesCLN.displayName = "ReportesCLN";

export default ReportesCLN;
