import React, { Component, Fragment } from "react";
import { Field, FieldArray } from "redux-form";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Select, TextField } from "redux-form-material-ui";
import { required } from "js/components/Shared/Form/validations.js";
import ErrorList from "js/components/Shared/ErrorList";
import EmailField from "js/pages/Admin/Accion/Edit/EmailField";
import RangoPremio from "./RangoPremio";
import ChipInput from "material-ui-chip-input"; // https://github.com/TeamWertarbyte/material-ui-chip-input

import {
  InputNumberField,
  InputTextField
} from "js/components/Shared/Form/Fields";
import Switch from "@material-ui/core/Switch";
import Icon from "@material-ui/core/Icon/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import AppQraphQL from "js/graphql/resolvers/perfil.resolver";
import Variables from "./variables";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class MyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      definicionPerfilValues: [],
      tipos: []
    };
  }

  getCamposPerfil() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getDefinicionesPerfilById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        if (!res.data.getDefinicionesPerfil.length) return;
        let data = res.data.getDefinicionesPerfil;
        data.forEach((v, i) => {
          data[i].index = i;
        });
        this.setState({
          definicionPerfilValues: data
        });
      });
  }
  async componentDidMount(prevProps) {
    await this.getCamposPerfil();
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.initialValues !== this.props.initialValues) {
      const { initialValues } = nextProps;
      this.setState({
        tipos:
          initialValues && initialValues.tipo && initialValues.tipo.valores
            ? initialValues.tipo.valores.slice()
            : []
      });
    }
  };

  render() {
    const {
      handleSubmit,
      classes,
      history,
      errors,
      disabled = false,
      otorgaPremio,
      otorgaPuntos,
      conSG,
      url,
      nivelAprobacion,
      apruebaRecursosHumanos,
      focoenreconocedor,
      match: {
        params: { id }
      }
    } = this.props;
    const tipos = this.state.tipos;
    const camposPerfil = this.state.definicionPerfilValues;
    const renderSwitch = ({ input }) => (
      <Switch checked={input.value ? true : false} onChange={input.onChange} />
    );
    const renderChips = ({ input, tipos }) => (
      <ChipInput
        {...input}
        value={input.value || []}
        onAdd={addedChip => {
          let values = input.value || [];
          values = values.slice();
          values.push(addedChip);
          if (tipos) {
            this.setState({ tipos: values.slice() });
          }
          input.onChange(values);
        }}
        newChipKeyCodes={[9, 13]} // Enter, Tab
        onDelete={deletedChip => {
          let values = input.value || [];
          values = values.filter(v => v !== deletedChip);
          if (tipos)
            this.setState({
              tipos: values.slice()
            });
          input.onChange(values);
        }}
        onBlur={() => input.onBlur()}
      />
    );

    const renderMenuTipos = () => {
      return tipos.map(tipo => (
        <MenuItem key={`tipo-${tipo}`} value={tipo}>
          {tipo}
        </MenuItem>
      ));
    };

    const renderRedefine = ({ fields, meta: { error, submitFailed } }) => (
      <Grid item container xs={12} spacing={16}>
        {!fields.length ? (
          <Tooltip title="Agregar nuevo elemento" placement="right-end">
            <Icon
              // className={classes.iconAddElement}
              color="secondary"
              onClick={() => {
                if (tipos.length > 0)
                  fields.push({
                    label: tipos[0],
                    conSG,
                    url,
                    nivelAprobacion,
                    apruebaRecursosHumanos,
                    focoenreconocedor
                  });
              }}
            >
              add_circle
            </Icon>
          </Tooltip>
        ) : (
          ""
        )}
        {fields.map((redefine, index) => {
          return (
            <Fragment key={"redefine-" + index}>
              <Grid xs={11} item container>
                <Paper style={{ width: "100%", padding: "15px" }}>
                  <Grid container item xs={12} spacing={16}>
                    <Grid xs={12} item container spacing={16}>
                      <Grid xs={12} sm={4} item>
                        <Field
                          name={`${redefine}.label`}
                          label="Tipo"
                          component={Select}
                          className={classes.field}
                        >
                          {renderMenuTipos()}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item container spacing={16}>
                      <Grid item xs={12} sm={4}>
                        <Typography>Con SurveyGizmo?</Typography>
                        <Field
                          name={`${redefine}.conSG`}
                          color="primary"
                          component={renderSwitch}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name={`${redefine}.url`}
                          label="Url del formulario"
                          component={TextField}
                          className={classes.field}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid xs={12} item container spacing={16}>
                        <Grid item xs={12} sm={4}>
                          <FormControl className={classes.field}>
                            <InputLabel htmlFor="nivelAprobacion">
                              Nivel de aprobación
                            </InputLabel>
                            <Field
                              name={`${redefine}.nivelAprobacion`}
                              label="Nivel de aprobación"
                              component={Select}
                              className={classes.field}
                              validate={required}
                              disabled={disabled}
                              required
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                          <Typography>Aprueba RRHH?</Typography>
                          <Field
                            name={`${redefine}.apruebaRecursosHumanos`}
                            color="primary"
                            component={renderSwitch}
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Tooltip title="Quitar elemento" placement="right-start">
                <Icon
                  // className={classes.iconRemoveElement}
                  color="action"
                  onClick={() => fields.splice(index, 1)}
                >
                  remove_circle
                </Icon>
              </Tooltip>

              {fields.length - 1 === index ? (
                <Tooltip title="Agregar nuevo elemento" placement="right-end">
                  <Icon
                    // className={classes.iconAddElement}
                    color="secondary"
                    onClick={() => {
                      if (tipos.length > 0)
                        fields.push({
                          label: tipos[0],
                          conSG,
                          url,
                          nivelAprobacion,
                          apruebaRecursosHumanos,
                          focoenreconocedor
                        });
                    }}
                  >
                    add_circle
                  </Icon>
                </Tooltip>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </Grid>
    );
    const renderMatriz = ({
      fields,
      meta: { error, submitFailed },
      puntos = false
    }) => (
      <Grid item container xs={12} spacing={16}>
        {!fields.length ? (
          <Tooltip title="Agregar nuevo elemento" placement="right-end">
            <Icon
              // className={classes.iconAddElement}
              color="secondary"
              onClick={() => fields.push({})}
            >
              add_circle
            </Icon>
          </Tooltip>
        ) : (
          ""
        )}
        {fields.map((rango, index) => (
          <Fragment key={index}>
            <Grid xs={11} item container>
              <Paper style={{ width: "100%", padding: "15px" }}>
                <Grid container item xs={12} spacing={16}>
                  <RangoPremio name={rango} index={index} />
                  <Grid item xs={12}>
                    {puntos ? (
                      <InputNumberField
                        name={`${rango}.valor`}
                        label="Puntos"
                      />
                    ) : (
                      <InputTextField name={`${rango}.valor`} label="Premio" />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {fields.length !== 1 ? (
              <Tooltip title="Quitar elemento" placement="right-start">
                <Icon
                  // className={classes.iconRemoveElement}
                  color="action"
                  onClick={() => fields.splice(index, 1)}
                >
                  remove_circle
                </Icon>
              </Tooltip>
            ) : (
              ""
            )}

            {fields.length - 1 === index ? (
              <Tooltip title="Agregar nuevo elemento" placement="right-end">
                <Icon
                  // className={classes.iconAddElement}
                  color="secondary"
                  onClick={() => fields.push({})}
                >
                  add_circle
                </Icon>
              </Tooltip>
            ) : (
              ""
            )}
          </Fragment>
        ))}
      </Grid>
    );
    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Formulario Reconocimiento
              <Tooltip title="Tutorial Reconocimiento">
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/document/d/1PkrW95Z2zkqXLAnOa_hYVqy4r_gGQA9votE_Vqy-2co/edit?usp=sharing"
                    )
                  }
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            {/* <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{
                float: "right"
              }}
              onClick={() =>
                history.push(`/admin/programa/${id}/reconocimiento/jerarquia`)
              }
            >
              Jerarquía
            </Button> */}
          </Grid>
        </Grid>
        <Grid className={classes.grid} container spacing={16}>
          <Grid item xs={12} sm={3}>
            <Typography>Con SurveyGizmo?</Typography>
            <Field name={`conSG`} color="primary" component={renderSwitch} />
          </Grid>
          <Grid item xs={12} sm={3}>
            {conSG ? (
              <Field
                name="url"
                label="Url del formulario"
                component={TextField}
                className={classes.field}
                disabled={disabled}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {conSG ? (
              <Field
                name="urlEdicion"
                label="Url de edicion de formulario"
                component={TextField}
                className={classes.field}
                disabled={disabled}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {conSG ? (
              <InputNumberField name="alturaIframe" label="Altura del Iframe" />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="nivelAprobacion">
                Nivel de aprobación
              </InputLabel>
              <Field
                name="nivelAprobacion"
                label="Nivel de aprobación"
                component={Select}
                className={classes.field}
                validate={required}
                disabled={disabled}
                required
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Aprueba RRHH?</Typography>
            <Field
              name={`apruebaRecursosHumanos`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>ID ROL RRHH</Typography>
            <Field
              name="menurrhh"
              component={TextField}
              className={classes.field}
              disabled={apruebaRecursosHumanos ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Puede reconocer equipos?</Typography>
            <Field
              name={`puedeReconocerEquipos`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography>Con mensaje de aprobadores?</Typography>
            <Field
              name={`conMensajeAprobadores`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Con observación?</Typography>
            <Field
              name={`conObservacion`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Foco en el Reconocedor?</Typography>
            <Field
              name={`focoenreconocedor`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="campoUsuarioPrincipal">
                Información de usuario principal
              </InputLabel>
              <Field
                name="campoUsuarioPrincipal"
                label="Información de usuario principal"
                component={Select}
                className={classes.field}
                disabled={disabled}
              >
                {camposPerfil.map(({ label }) => (
                  <MenuItem value={label}>{label}</MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="campoUsuarioSecundario">
                Información de usuario secundario
              </InputLabel>
              <Field
                name="campoUsuarioSecundario"
                label="Información de usuario secundario"
                component={Select}
                className={classes.field}
                disabled={disabled}
              >
                {camposPerfil.map(({ key, label }) => (
                  <MenuItem value={label}>{label}</MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <Divider classes={{ root: classes.dividerRoot }} />
            <Typography>Pregunta acción</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="pregunta1.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Se muestra?</Typography>
            <Field
              name={`pregunta1.seMuestra`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Es requerida?</Typography>
            <Field
              name={`pregunta1.esRequerida`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*<Typography>Es requerida?</Typography>*/}
            <InputNumberField
              name="pregunta1.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {" "}
              Valoración del reconocimiento ($, horas, %, cantidad / unidad,
              etc)..{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="pregunta3.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Se muestra?</Typography>
            <Field
              name={`pregunta3.seMuestra`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Es requerida?</Typography>
            <Field
              name={`pregunta3.esRequerida`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*<Typography>Es requerida?</Typography>*/}
            <InputNumberField
              name="pregunta3.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            {/*<Divider classes={{ root: classes.dividerRoot }} />*/}
            <Typography>Pregunta justificación</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="pregunta2.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Se muestra?</Typography>
            <Field
              name={`pregunta2.seMuestra`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Es requerida?</Typography>
            <Field
              name={`pregunta2.esRequerida`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*<Typography>Es requerida?</Typography>*/}
            <InputNumberField
              name="pregunta2.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            {/*<Divider classes={{ root: classes.dividerRoot }} />*/}
            <Typography>Mensaje del nominador</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="mensajeNominador.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Se muestra?</Typography>
            <Field
              name={`mensajeNominador.seMuestra`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Es requerido?</Typography>
            <Field
              name={`mensajeNominador.esRequerida`}
              color="primary"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*<Typography>Es requerida?</Typography>*/}
            <InputNumberField
              name="mensajeNominador.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            <Divider classes={{ root: classes.dividerRoot }} />
            <Typography>Valor</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="valor.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Valores</Typography>
            <Field
              name="valor.valores"
              label="Valores"
              component={renderChips}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Respuesta multiple?</Typography>
            <Field
              name={`valor.respuestaMultiple`}
              color="primary"
              label="Respuesta multiple?"
              component={renderSwitch}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="valor.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography>Leyenda</Typography>
            <Field
              name="leyendaValor"
              multiline
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            <Divider classes={{ root: classes.dividerRoot }} />
            <Typography>Tipo</Typography>
          </Grid>
          <Grid xs={12} sm={3} item>
            <Field
              name="tipo.alias"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <Typography>Valores</Typography>
            <Field
              name="tipo.valores"
              label="Valores"
              component={renderChips}
              className={classes.field}
              disabled={disabled}
              tipos={tipos}
            />
          </Grid>
          <Grid xs={12} sm={2} item>
            {/* <Typography>Respuesta multiple?</Typography>
            <Field
              name={`tipo.respuestaMultiple`}
              color="primary"
              component={renderSwitch}
            /> */}
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="tipo.orden"
              label="Orden"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography>Leyenda</Typography>
            <Field
              name="leyendaTipo"
              multiline
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12}>
            <Typography>Redefinir por tipo?</Typography>
          </Grid>
          <Grid xs={12}>
            <FieldArray name="redefineTipo" component={renderRedefine} />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Typography>Otorga premios?</Typography>
              <Field
                name={`otorgaPremio`}
                color="primary"
                component={renderSwitch}
              />
            </Grid>
            {otorgaPremio ? (
              <Fragment>
                <Grid item xs={4} style={{ paddingBottom: "20px" }}>
                  <FormControl className={classes.field}>
                    <InputLabel htmlFor="modoPremio">
                      Modo de reemplazo premio
                    </InputLabel>
                    <Field
                      name="modoPremio"
                      label="Modo de reemplazo premio"
                      component={Select}
                      className={classes.field}
                      disabled={disabled}
                    >
                      <MenuItem value={"NO_REEMPLAZO"}>
                        No se puede reemplazar
                      </MenuItem>
                      <MenuItem value={"REEMPLAZO_PARCIAL"}>
                        Se puede reemplazar solo por valores del rango
                      </MenuItem>
                      <MenuItem value={"REEMPLAZO_TOTAL"}>
                        Se puede reemplazar por cualquier valor
                      </MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray name="matrizPremio" component={renderMatriz} />
                </Grid>
              </Fragment>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.dividerRoot }} />
              <Typography>Otorga puntos?</Typography>
              <Field
                name={`otorgaPuntos`}
                color="primary"
                component={renderSwitch}
                puntos={false}
              />
            </Grid>
            {otorgaPuntos ? (
              <Fragment>
                <Grid item xs={4} style={{ paddingBottom: "20px" }}>
                  <FormControl className={classes.field}>
                    <InputLabel htmlFor="modoPuntos">
                      Modo de reemplazo puntos
                    </InputLabel>
                    <Field
                      name="modoPuntos"
                      label="Modo de reemplazo puntos"
                      component={Select}
                      className={classes.field}
                      disabled={disabled}
                    >
                      <MenuItem value={"NO_REEMPLAZO"}>
                        No se puede reemplazar
                      </MenuItem>
                      <MenuItem value={"REEMPLAZO_PARCIAL"}>
                        Se puede reemplazar solo por valores del rango
                      </MenuItem>
                      <MenuItem value={"REEMPLAZO_TOTAL"}>
                        Se puede reemplazar por cualquier valor
                      </MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="matrizPuntos"
                    component={renderMatriz}
                    puntos={true}
                  />
                </Grid>
              </Fragment>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>Acreditación automatica de puntos?</Typography>
            <Field
              name={`acreditacionAutomatica`}
              color="primary"
              component={renderSwitch}
            />
            <Divider classes={{ root: classes.dividerRoot }} />
          </Grid>

          <Grid item container xs={12} spacing={16}>
            <Divider classes={{ root: classes.dividerRoot }} />
            <Variables idPrograma={id} />
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de aprobación pendiente
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <EmailField name={"mailAprobacion"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de recordatorio
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <EmailField name={"mailRecordatorio"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputNumberField
              name="diasRecordatorio"
              helperText="Días para recordar reconocimiento pendiente de aprobación"
              label="Días para recordar"
              component={TextField}
              className={classes.field}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de felicitaciones con aprobación
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <Divider classes={{ root: classes.dividerRoot }} />
                  <EmailField name={"mailReconocimientoAprobado"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de felicitaciones sin aprobación
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <Divider classes={{ root: classes.dividerRoot }} />
                  <EmailField name={"mailReconocimientoSinAprobacion"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de aviso Reconocimiento para editar
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <Divider classes={{ root: classes.dividerRoot }} />
                  <EmailField name={"mailReconocimientoEnEdicion"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Email de nominación rechazada
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12}>
                  <Divider classes={{ root: classes.dividerRoot }} />
                  <EmailField name={"mailReconocimientoRechazado"} conCCO />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <ErrorList errors={errors} />
        <Grid className={classes.grid} container>
          <Grid item xs />
          <Grid item xs />
          <Grid className={classes.gridButton} item xs>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push("/admin/programa")}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid className={classes.gridButton} item xs>
            {!disabled && (
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}
