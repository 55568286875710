import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { withRouter } from "react-router-dom";
import withPrograma from "js/hoc/withPrograma";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { getField } from "js/components/Shared/Form/fieldGenerator";
import LabeledAutocomplete from "../Shared/Form/Fields/LabeledAutocomplete";

class ProductosField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: {}
    };
  }

  getAutocompleteValue = (data, key, selectedAutoComplete) => {
    let seletedValue = { value: undefined, label: "" };
    if (this.state.filter[key] !== undefined) {
      seletedValue = data.find(p => p.value === this.state.filter[key]);
    }
    let selected = {
      [key]: seletedValue.value
    };
    selectedAutoComplete(selected);
    return seletedValue;
  };

  SubmitValue = (e, key) => {
    e.target = {
      name: key,
      value: e.value
    };

    const obj = this.state.filter;

    obj[e.target.name] = e.target.value;

    this.setState(({ filter }) => ({
      filter: {
        ...obj
      }
    }));
  };

  CustomField = ({ input, meta, fieldConfig }) => {
    const { tipo } = fieldConfig;
    return getField(tipo)({
      ...fieldConfig,
      input,
      meta
    });
  };

  renderField = (field, index) => {
    if (
      !(
        field.key === "tasaDeAcreditacion" ||
        field.key === "limiteDiario" ||
        field.key === "limitePorFactura" ||
        field.key === "limiteMensual" ||
        field.key === "incrementos" ||
        field.key === "regexNroFactura"
      )
    ) {
      return (
        <Grid item xs={12} md={6} key={index}>
          <Field
            name={field.key}
            component={this.CustomField}
            fieldConfig={field}
          />
        </Grid>
      );
    }
  };
  render() {
    const {
      classes,
      parametrosFacturaPorPuntos,
      selectedAutoComplete
    } = this.props;
    return (
      <form name="formFacturasPorPuntos">
        <Grid
          container
          alignItems="center"
          spacing={16}
          className={classes.gridCard}
        >
          {parametrosFacturaPorPuntos.map((field, index) => {
            if (field.tipo !== "Lista_Autocomplete") {
              return this.renderField(field, index);
            } else {
              const suggestions =
                field.value && field.value.length > 0
                  ? field.value.map(val => ({ value: val, label: val }))
                  : [];

              return (
                <Grid item xs={12} md={6} key={index}>
                  <LabeledAutocomplete
                    label={field.label}
                    isDisabled={false}
                    onChange={e => this.SubmitValue(e, field.key)}
                    noOptionsMessage={() => "No hay coincidencias"}
                    placeholder={`Buscar por ${field.label}`}
                    suggestions={suggestions}
                    value={this.getAutocompleteValue(
                      suggestions,
                      field.key,
                      selectedAutoComplete
                    )}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </form>
    );
  }
}

const formValuesSelector = formValueSelector("formFacturasPorPuntos");
const mapStateToPropsWithSelectors = (state, ownProps) => {
  const { parametrosFacturaPorPuntos, handleChange } = ownProps;

  const formValues = {};
  parametrosFacturaPorPuntos.forEach(field => {
    formValues[field.key] = formValuesSelector(state, field.key);
  });
  handleChange(formValues);
  return {
    formValues
  };
};

const enhance = compose(
  withPrograma,
  withRouter,
  withStyles(styles),
  withApollo,
  connect(mapStateToPropsWithSelectors),
  reduxForm({
    form: "formFacturasPorPuntos"
  })
);

export default enhance(ProductosField);
