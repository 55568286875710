import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FacturaPorPuntosQraphQL from "js/graphql/resolvers/cargaFacturaPorPuntos.resolver";
import client from "js/App/client.graphql.js";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import moment from "moment";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import capitalize from "lodash/capitalize";
import { _t } from "js/utils/TranslationService";
import { formatImporte } from "../../helpers/formatImporte.js";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import "./estilos.css";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogModeracion extends React.Component {
  state = {
    errorMotivo: null,
    errors: null,
    disabled: true,
    disabledButtons: false,
    motivo: null,
    programaInfo: null,
    inBackOffice:
      window.location.pathname.indexOf("admin") !== -1 ? true : false
  };

  sendValues = async value => {
    this.setState({ errors: null, errorMotivo: null, disabledButtons: true });
    let estado = value;
    if (estado !== "APROBADA") {
      if (
        this.state.motivo === null ||
        this.state.motivo === "" ||
        this.state.motivo.length < 2
      )
        return this.setState({
          errorMotivo: "Completar el motivo con un mínimo de 2 caracteres.",
          disabledButtons: false
        });
    }

    let tipoDocumento = _t("Factura");

    try {
      await client
        .mutate({
          mutation: FacturaPorPuntosQraphQL.mutations.updateFacturaPoPuntos,
          variables: {
            facturaPorPuntos: {
              id: this.props.factura.itemId,
              estado,
              modificada: true,
              motivo: this.state.motivo,
              idPrograma: this.props.factura.item.idPrograma,
              idUsuario: this.props.factura.item.idUsuario,
              tipoDocumento
            }
          }
        })
        .then(res => {
          let facturaUpdated = res.data.updateFacturaPoPuntos;
          this.props.openNotification(
            `${_t("Factura")} ${
              facturaUpdated.estado === "APROBADA"
                ? "aprobada/o"
                : "rechazada/o"
            }
          `,
            6000
          );
          if (
            facturaUpdated.estado === "RECHAZADA" &&
            facturaUpdated.motivo.includes("limite")
          ) {
            let menssages = facturaUpdated.motivo.trim().split("\n");
            for (let menssage of menssages) {
              setTimeout(() => {
                this.props.openNotification(menssage, 6000);
              }, 6001);
            }
          }

          this.setState({ disabledButtons: false });
          this.props.onTableChange();
          this.props.handleClose();
        });
    } catch (error) {
      this.setState({ errors: error, disabledButtons: false });
      this.props.openNotification(`Ocurrió un error, intente nuevamente`);
    }
  };

  async componentDidMount() {
    const { inBackOffice } = this.state;
    const {
      factura: {
        item: { idPrograma }
      }
    } = this.props;
    const query = inBackOffice
      ? AppQraphQL.queries.getProgramaById
      : AppQraphQL.queries.getProgramaPublicInfo;
    const { data } = await client.query({
      query: query,
      fetchPolicy: "network-only",
      variables: inBackOffice
        ? { idPrograma }
        : {
            url: window.location.hostname.replace("www.", "")
          }
    });
    if (data) {
      if (inBackOffice)
        this.setState({
          programaInfo: data ? data.getProgramas.itemsPagina[0] : undefined
        });
      else
        this.setState({
          programaInfo: data ? data.getProgramaPublicInfo.settings : undefined
        });
    }

    this.setState({
      motivo: this.props.factura.item.motivo || null
    });
  }

  handleIsAllowedMotivo = factura => {
    // Si el motivo no es string vacio, se bloquea el input
    if (!!factura.item.motivo) return true;
    // Si el motivo no es string vacio, se bloquea el input
    if (
      factura.item.estado === "APROBADA" ||
      factura.item.estado === "RECHAZADA"
    )
      return true;
    // Si tiene el permiso MODERACION_FACTURA_POR_PUNTOS_W, se habilita el input
    if (AuthService.hasPerms([Roles.MODERACION_FACTURA_POR_PUNTOS_W]))
      return false;
    return true;
  };

  handleMotivo = e => {
    this.setState({ motivo: e.target.value });
  };

  handleProductSelect = event => {
    this.setState({ selectedProduct: event.target.value });
  };

  handleChange = () => event => {
    this.setState({ lote: event.target.value });
  };

  handleCanSeeMotivo = factura => {
    // Es admin, puede ver siempre
    if (AuthService.hasPerms([Roles.MODERACION_FACTURA_POR_PUNTOS_W]))
      return true;
    // No es admin, puede ver su motivo solo si está rechaza
    else return factura.estado === "RECHAZADA" ? true : false;
  };

  abrirImagenEnNuevaPestana = e => {
    e.stopPropagation(); // Evita que el evento se propague al manejador del diálogo
    window.open(this.props.factura.item.facturaUrl, "_blank");
  };

  render() {
    const { handleClose, classes, factura } = this.props;
    const { disabled, disabledButtons, programaInfo } = this.state;
    let pais = null;

    if (programaInfo) {
      const { paises } = programaInfo;
      pais = paises[0].descripcion;
    }

    const props = {
      width: 500,
      height: 600,
      zoomWidth: 500,
      scale: 1.5,
      zoomPosition: "original",
      img: factura.item.facturaUrl
    };
    // const currencyFormat = formatImporte;

    // let importeConFormato = parseFloat(factura.item.importe)
    return (
      <Fragment>
        <Dialog
          open
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <div className="borderContainer fieldsContainer">
                  <DialogTitle
                    id="alert-dialog-slide-title"
                    style={{ paddingLeft: 0 }}
                  >
                    <Typography variant="h4" gutterBottom align="left">
                      {_t("Moderación factura por puntos")} #
                      {factura.item.idCargaFactura}
                    </Typography>
                    <Typography variant="h5" gutterBottom align="left">
                      {factura.item.estado === "PENDIENTE_APROBACION"
                        ? _t("Pendiente aprobación")
                        : _t(capitalize(factura.item.estado))}
                    </Typography>
                  </DialogTitle>
                  <FormControl className="formField">
                    <TextField
                      required={true}
                      id={"numeroFactura"}
                      name={"numeroFactura"}
                      label={_t("Nº de Factura")}
                      value={factura.item.numeroFactura}
                      onChange={this.handleChange("numeroFactura")}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormControl className="formField">
                    <DatePicker
                      key={"fechaFactura"}
                      name={"fechaFactura"}
                      label={_t("Fecha de factura")}
                      input={{
                        onChange: date =>
                          this.submitValueDate({
                            target: {
                              name: "fechaFactura",
                              value: date.toISOString()
                            }
                          }),
                        value: moment(factura.item.fechaFactura)
                      }}
                      format={"DD/MM/YYYY"}
                      autoOk={true}
                      disabled={disabled}
                      startOfDate
                    />
                  </FormControl>
                  <FormControl className="formField">
                    <TextField
                      id={"usuario"}
                      name={"usuario"}
                      label={_t("Usuario solicitante")}
                      value={factura.item.usuario.username || ""}
                      disabled={true}
                    />
                  </FormControl>
                  <FormControl className="formField">
                    <TextField
                      id={"importe"}
                      name={"importe"}
                      label={_t("Importe")}
                      value={formatImporte(factura.item.importe, pais) || ""}
                      disabled={true}
                    />
                  </FormControl>
                  <FormControl className="formField">
                    <TextField
                      id={"puntoDeVenta"}
                      name={"puntoDeVenta"}
                      label={_t("Punto de venta")}
                      value={factura.item.puntoDeVenta || ""}
                      disabled={true}
                    />
                  </FormControl>
                  {AuthService.hasPerms([
                    Roles.MODERACION_FACTURA_POR_PUNTOS_W
                  ]) ? (
                    <FormControl className="formField">
                      <TextField
                        id={"usuarioModerador"}
                        name={"usuarioModerador"}
                        label={_t("Moderador")}
                        value={
                          factura.item.usuarioModerador
                            ? factura.item.usuarioModerador.username
                            : "--"
                        }
                        disabled={true}
                      />
                    </FormControl>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction="column"
                justify="space-between"
              >
                <div className="containerMotivo imgContainer">
                  <img
                    className="imgFactura"
                    src={factura.item.facturaUrl}
                    alt={_t("Factura")}
                    onClick={this.abrirImagenEnNuevaPestana}
                  />
                </div>
                <div className="containerMotivo">
                  {this.handleCanSeeMotivo(this.props.factura.item) && (
                    <FormControl style={{ width: "100%", marginBottom: "0%" }}>
                      <TextField
                        id="outlined-textarea"
                        error={!!this.state.errorMotivo}
                        label={
                          this.state.motivo
                            ? null
                            : _t("Motivo (Completar solo en caso de rechazo)")
                        }
                        disabled={this.handleIsAllowedMotivo(factura)}
                        multiline
                        className={classes.textField}
                        margin="normal"
                        value={
                          this.state.motivo
                            ? this.state.motivo.split("\n").join(". ")
                            : null
                        }
                        onChange={this.handleMotivo}
                        helperText={
                          !!this.state.errorMotivo ? this.state.errorMotivo : ""
                        }
                      />
                    </FormControl>
                  )}
                </div>

                {!factura.item.modificada &&
                AuthService.hasPerms([
                  Roles.MODERACION_FACTURA_POR_PUNTOS_W
                ]) ? (
                  <div className="borderContainer buttonCointainer">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      disabled={disabledButtons}
                      className="buttonCancelar buttonSecondary"
                      style={{ marginBottom: "5px" }}
                    >
                      Cancelar
                    </Button>

                    <Button
                      onClick={() => this.sendValues("RECHAZADA")}
                      variant="outlined"
                      color="primary"
                      disabled={disabledButtons}
                      className="buttonSecondary"
                      style={{ marginBottom: "5px" }}
                    >
                      Rechazar
                    </Button>
                    <Button
                      onClick={() => this.sendValues("APROBADA")}
                      variant="contained"
                      color="primary"
                      disabled={disabledButtons}
                      className="buttonPrimary"
                      style={{ marginBottom: "5px" }}
                    >
                      Aprobar
                    </Button>
                  </div>
                ) : (
                  <div
                    className="borderContainer buttonCointainer"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div
                      className="buttonContainerChild"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        disabled={disabledButtons}
                        className="buttonCancelar buttonSecondary"
                        size="large"
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

DialogModeracion.defaultProps = {
  classes: styles
};

DialogModeracion.propTypes = {
  classes: PropTypes.object.isRequired
};

export default DialogModeracion;
