import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppQraphQL from "js/graphql/resolvers/datosRequeridos.resolver";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { _t } from "js/utils/TranslationService";

const styles = theme => ({
  listItem: {
    "padding-left": 0
  }
});

class DatosRequeridos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      datos: []
    };
  }

  componentWillReceiveProps(props) {
    this.fetchdatos();
  }

  async fetchdatos() {
    const { client } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getDatosRequeridos,
        fetchPolicy: "cache-first",
        errorPolicy: "all"
      })
      .then(res => {
        this.setState({
          datos: res.data.getMasterDatosRequeridos
        });
      });
  }

  handleToggle(fields, id) {
    if (fields.length === 0 || fields.getAll().indexOf(id) === -1) {
      fields.push(id);
    } else {
      fields.remove(fields.getAll().indexOf(id));
    }
  }

  render() {
    const { disabled, classes, name, withSucursales } = this.props;
    const { datos } = this.state;
    const renderDatosRequeridos = ({
      fields,
      meta: { error, submitFailed }
    }) => (
      <div className={classes.root}>
        <Grid container spacing={12}>
          {(datos || [])
            .filter(
              dato =>
                dato.visibleEnCreacionProducto &&
                (withSucursales || dato.key !== "sucursal")
            )
            .map((item, index) => (
              <Grid item xs={6}>
                <ListItem
                  className={classes.listItem}
                  key={item.idDatoRequerido}
                  dense
                  button
                  disabled={disabled}
                  onClick={this.handleToggle.bind(
                    this,
                    fields,
                    item.idDatoRequerido
                  )}
                >
                  <Checkbox
                    checked={
                      fields.length !== 0 &&
                      fields.getAll().indexOf(item.idDatoRequerido) !== -1
                    }
                    tabIndex={-1}
                    disabled={disabled}
                    disableRipple
                  />
                  <ListItemText primary={_t(item.nombre)} />
                </ListItem>
              </Grid>
            ))}
        </Grid>
      </div>
    );
    return (
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {`${_t("Campos requeridos al usuario al momento del canje")}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name={name || "idDatosRequeridos"}
            component={renderDatosRequeridos}
          />
        </Grid>
      </Grid>
    );
  }
}

DatosRequeridos.propTypes = {
  name: PropTypes.string
};

export default withApollo(withRouter(withStyles(styles)(DatosRequeridos)));
