import gql from "graphql-tag";

const Catalogo = {};

Catalogo.fragments = {
  getZonasUI: gql`
    fragment getZonasUI on ZonaUI {
      idZona
      descripcion
      zonaCantidad
      localidades {
        idLocalidad
        descripcion
        localidadCantidad
      }
    }
  `,
  getRubrosUI: gql`
    fragment getRubrosUI on RubroUI {
      idRubro
      descripcion
      rubroCantidad
      subrubros {
        idSubrubro
        descripcion
        subrubroCantidad
      }
    }
  `,
  infoPagina: gql`
    fragment infoPagina on InfoPaginado {
      numeroPagina
      total
      cantidadPaginas
    }
  `,
  paginaCatalogo: gql`
    fragment itemsPagina on Catalogo {
      idEleccionProducto
      favorito
      puntos
      cucarda
      rubros {
        descripcion
      }
      subrubros {
        descripcion
      }
      stockInfinito
      stockPorPrograma
      producto {
        idProducto
        imagenes {
          idImagen
          url
          alt
        }
        nombre
        copete
        stock
        stockInfinito
        rubro {
          descripcion
        }
        subrubro {
          descripcion
        }
      }
    }
  `,
  detalleProducto: gql`
    fragment detalleProducto on Catalogo {
      idEleccionProducto
      favorito
      puntos
      cucarda
      producto {
        idProducto
        condicionCanje
        copete
        descripcion
        imagenes {
          idImagen
          url
          alt
        }
        datosRequeridos {
          idDatoRequerido
          nombre
          estado
          key
        }
        nombre
        sucursales {
          estado
          idSucursal
          latitud
          longitud
          nombre
          pais {
            descripcion
          }
          telefono
          zona {
            descripcion
          }
        }
        tieneSucursales
        tipoProducto
        paginaWeb
        proveedor {
          publicaPaginaWeb
          publicaSucursales
          tieneSucursales
          urlPaginaWeb
        }
      }
    }
  `,
  tablaCatalogo: gql`
    fragment tablaCatalogo on EleccionProducto {
      idEleccionProducto
      estado
      puntos
      producto {
        idProducto
        nombre
        stockInfinito
        proveedor {
          nombreFantasia
        }
      }
      segmentos {
        nombre
      }
      stockPorPrograma
      stockInfinito
    }
  `,
  detalleEleccionProducto: gql`
    fragment detalleEleccionProducto on EleccionProducto {
      idEleccionProducto
      estado
      porcentaje
      puntos
      stockInfinito
      stockPorPrograma
      stockPorUsuario
      stockPorPeriodo
      periodo
      cucarda
      prioridad
      programa {
        tasaConversion
      }
      producto {
        idProducto
        nombre
        stock
        costo
        precioSugerido
        stockInfinito
        proveedor {
          nombreFantasia
          razonSocial
          pais {
            moneda
          }
        }
        imagenes {
          idImagen
          url
          alt
        }
      }
      segmentos {
        idSegmento
        descripcion
        estado
      }
      rubros {
        idRubro
        descripcion
        estado
      }
      subrubros {
        idSubrubro
        descripcion
        estado
      }
      paises {
        idPais
        descripcion
        estado
      }
      zonas {
        idZona
        descripcion
        estado
      }
      localidades {
        idLocalidad
        descripcion
        estado
      }
    }
  `
};

export default Catalogo;
