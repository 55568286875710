import { compose, pure, withProps, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import CanjeGraphQL from "js/graphql/resolvers/canje.resolver";
import client from "js/App/client.graphql.js";
import withPrograma from "js/hoc/withPrograma";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import moment from "moment";
import { withNotificationContext } from "js/notification-context";
import { _t } from "js/utils/TranslationService";

export default compose(
  pure,
  withPrograma,
  withStyles(styles),
  withRouter,
  withNotificationContext,
  withProps(ownerProps => {
    return {
      idCanje: ownerProps.match.params.idCanje
    };
  }),
  withHandlers({
    sendValues: ({ history, openNotification }) => async values => {
      const variables = {
        fechaEntrega:
          values.estadoCanje === "ENTREGADO"
            ? moment(values.fechaEntrega).toISOString()
            : null,
        estadoCanje: values.estadoCanje,
        costoReal: parseInt(values.costoReal, 10),
        idCanje: parseInt(values.idCanje, 10),
        deslotear: values.deslotear
      };

      const { errors } = await client.mutate({
        mutation: CanjeGraphQL.mutations.updateCanje,
        errorPolicy: "all",
        variables
      });
      if (errors && errors.length) {
        openNotification(errors[0].message);
        return;
      }
      openNotification("Canje actualizado correctamente");
      history.push(values.backUrl);
    },
    cancelCanje: ({ history, openNotification }) => async (
      idCanje,
      motivoCancelacion,
      backUrl
    ) => {
      const { errors } = await client.mutate({
        mutation: CanjeGraphQL.mutations.updateCanje,
        errorPolicy: "all",
        variables: {
          estadoCanje: "CANCELADO",
          idCanje,
          motivoCancelacion
        }
      });
      if (errors && errors.length) {
        openNotification(errors[0].message);
        return;
      }
      openNotification(
        _t("El canje fue cancelado y se devolvieron los puntos al usuario")
      );
      history.push(backUrl);
    }
  }),
  graphql(CanjeGraphQL.queries.getCanje, {
    alias: "AdminCanjeDetailHOC",
    options: ({ idCanje, computedMatch }) => ({
      variables: {
        idCanje: idCanje ? idCanje : computedMatch.params.idCanje
      },
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, getCanjes } }) => {
      if (loading) return;
      return {
        initialValues: {
          ...getCanjes.itemsPagina[0],
          factura: get(
            getCanjes,
            "itemsPagina[0].factura.numeroFactura",
            undefined
          ),
          idProveedor: get(
            getCanjes,
            "itemsPagina[0].proveedor.idProveedor",
            undefined
          )
        }
      };
    }
  })
);
