import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "js/components/TablaProductoRetail/advancedFilter.js";
import AppQraphQL from "js/graphql/resolvers/productoRetail.resolver";
import AppQraphQLImport from "js/graphql/resolvers/importar.resolver";
import { editAction } from "js/components/Shared/Tabla/ActionCells";
import { USER_ROLES as Roles } from "js/models/User";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import capitalize from "lodash/capitalize";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import GroupIcon from "@material-ui/icons/Group";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: "Productos Retail",
    btnsNextTitle,
    new: {
      title: "Nuevo",
      perms: [Roles.PRODUCTO_RETAIL_W]
    },
    export: {
      path: "productosRetail",
      perms: [Roles.PRODUCTO_RETAIL_R, Roles.PRODUCTO_RETAIL_EXPORT],
      tooltip: "Exportar productos retail"
    },
    import: {
      action: AppQraphQLImport.queries.importarProductosRetail,
      perms: [Roles.PRODUCTO_RETAIL_W, Roles.PRODUCTO_RETAIL_IMPORT],
      tooltip: "Importar productos retail",
      model: "Modelo_Productos_Retail.csv",
      modelTooltip: "Modelo de importación de productos retail",
      variables: {
        idPrograma: id
      }
    },
    queryStringMatcher: {
      idsPrograma: undefined
    }
  };
};
const columnData = [
  { id: "fechaCreacion", label: "Fecha Creación", type: "date" },
  { id: "codigo", label: "Código" },
  { id: "nombre", label: "Nombre" },
  {
    id: "estado",
    label: "Estado",
    type: "custom",
    parser: estado => <Estado estado={capitalize(estado)} />
  },
  { id: "actions", label: "Acciones" }
];

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const edit = params => ({
      ...editAction({
        ...params,
        history: this.props.history,
        perms: [Roles.PRODUCTO_RETAIL_W]
      })
    });

    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Canales">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/canales-retail`)
            }
          >
            <ShareIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CANAL_RETAIL_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Ventas">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/ventas-retail`)
            }
          >
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.VENTA_RETAIL_R]
    });
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Clientes">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(`/admin/programa/${id}/clientes-retail`)
            }
          >
            <GroupIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.CLIENTE_RETAIL_R]
    });

    return (
      <Table
        storage={"FiltroProductoRetail"}
        query={AppQraphQL.queries.getProductosRetail}
        defaultHeader={defaultHeader(id, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => data.getProductosRetail}
        actionsCustom={[edit]}
      />
    );
  }
}

export default Tabla;
