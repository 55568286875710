import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import {
  compose,
  defaultProps,
  withHandlers,
  withProps,
  withState,
  lifecycle
} from "recompose";
import TriviaRaw from "./Trivia";
import EncuestaRaw from "./Encuesta";
import FormularioRaw from "./Formulario";
import AccionesGraphQL from "js/graphql/resolvers/acciones.resolver";
import get from "lodash/get";
import { withApollo } from "react-apollo";
import UserQraphQL from "js/graphql/resolvers/usuario.resolver";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";

const enhance = compose(
  withRouter,
  withApollo,
  withState("token", "setToken", ""),
  withProps(({ computedMatch: { params: { idAccion } } }) => ({ idAccion })),
  graphql(AppQraphQL.queries.isLoggedIn, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, isLoggedIn } = data;
      if (loading) return;
      return {
        isLoggedIn
      };
    }
  }),
  graphql(AccionesGraphQL.mutations.generarTokenParticipacion, {
    name: "generateToken"
  }),
  graphql(UserQraphQL.queries.getMiUsuarioCompleto, {
    options: () => ({
      fetchPolicy: "cache-first"
    }),
    props: ({ data }) => {
      const { loading, miUsuario } = data;
      if (loading)
        return {
          usuario: {}
        };
      return {
        usuario: miUsuario
      };
    }
  }),
  graphql(AccionesGraphQL.queries.getMiAccionById, {
    options: ({ idAccion }) => ({
      variables: { idAccion },
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, misAcciones }, ownProps: { history } }) => {
      if (loading) return;
      const accion = get(misAcciones, "itemsPagina[0]");
      if (!accion) history.replace("/");
      if (
        accion &&
        (accion.participaciones || []).length &&
        !accion.permiteMultipleParticipacion
      )
        history.replace("/ya-participo");
      return { accion };
    }
  }),
  withHandlers({
    onSubmit: ({ idAccion, history, client: { queryManager } }) => async e => {
      // PermService.addAccion(idAccion);
      window.scrollTo(0, 0);
      await queryManager.refetchQueryByName("getRedirect");
      //history.replace("/");
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { idAccion, generateToken, setToken } = this.props;
      window.addEventListener("message", this.props.onSubmit);
      const {
        data: { generarTokenParticipacion: token }
      } = await generateToken({ variables: { idAccion } });
      setToken(token);
    }
  }),
  defaultProps({ accion: {} })
);

export const Trivia = enhance(TriviaRaw);
export const Formulario = enhance(FormularioRaw);
export const Encuesta = enhance(EncuestaRaw);
