import React, { PureComponent } from "react";
import Table from "js/components/Shared/Tabla";
import AppQraphQL from "js/graphql/resolvers/linksTracking.resolver";
import advancedFilter from "js/pages/Admin/Programa/LinksTracking/Tabla/advancedFilter.js";
import { USER_ROLES as Roles } from "js/models/User";
import { numberWithCommas } from "js/utils";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

const defaultHeader = (id, inBackOffice, btnsNextTitle) => {
  const header = {
    title: "Tracking de clicks de links",
    export: {
      path: "linksTracking",
      perms: [Roles.LINKS_TRACKING_R],
      tooltip: "Exportar tracking de links"
    }
  };

  header.btnsNextTitle = btnsNextTitle;
  if (inBackOffice) {
    header.others = [
      {
        type: "import",
        action: AppQraphQL.mutations.importLinksToTrack,
        perms: [Roles.LINKS_TRACKING_W],
        tooltip: "Importar Links para trackear",
        model: "Modelo_Tracking_Links.csv",
        modelTooltip: "Modelo de importación de links para trackear",
        variables: {
          idPrograma: id
        }
      },
      {
        type: "export",
        path: "linksTracking/linksToTrack",
        perms: [Roles.LINKS_TRACKING_R],
        tooltip: "Exportar links para trackear"
      }
    ];
  }

  return header;
};

const columnData = [
  { id: "link", label: "Link" },
  { id: "descripcion", label: "Descripción", removeSort: true },
  {
    id: "cantidadClicks",
    label: "Clicks",
    isNumeric: true,
    type: "custom",
    parser: cantidadClicks =>
      cantidadClicks ? numberWithCommas(cantidadClicks) : "--"
  }
];

const defaultSort = { order: "asc", orderBy: "link" };

class Tabla extends PureComponent {
  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    const btnsNextTitle = [];
    btnsNextTitle.push({
      btn: (
        <Tooltip title="Configurar">
          <IconButton
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/admin/programa/${id}/links-tracking/edit`
              )
            }
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      ),
      perms: [Roles.LINKS_TRACKING_W]
    });

    return (
      <Table
        storage={"FiltroLinksTracking"}
        query={AppQraphQL.queries.getLinksTracking}
        defaultHeader={defaultHeader(id, inBackOffice, btnsNextTitle)}
        advancedFilter={advancedFilter}
        sort={defaultSort}
        columnData={columnData}
        getTipo={data => {
          return { itemsPagina: data.getLinksTracking };
        }}
        showPagination={false}
        pathId={"link"}
      />
    );
  }
}

export default Tabla;
