import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/Campana/Parametros/MyForm.js";
import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";
import { get, set } from "lodash";
import { removeTypename } from "js/utils/Helper";
import newItems from "js/pages/TemplateBuilder2/Components/NewItems";
const { newBase, newSlider } = newItems;

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        asignacionPuntos: "AL_CIERRE",
        imagenRequerida: false
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      parametrosVisuales: {}
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id } = this.state;

    await client
      .query({
        query: CampanaGraphQL.queries.getParametrosCampana,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        const parametros = res.data.getParametrosCampana;
        const parametrosVisuales = parametros
          ? JSON.parse(parametros.parametrosVisuales)
          : {};
        if (parametros) {
          this.setState({
            initialValues: {
              ...removeTypename(parametros),
              parametrosVisuales: {
                tipoVisual: parametrosVisuales.tipoVisual,
                tieneBorde: parametrosVisuales.tieneBorde,
                generalContainerShadow:
                  parametrosVisuales.generalContainerShadow,
                campanaContainerShadow:
                  parametrosVisuales.campanaContainerShadow,
                containerShadow: parametrosVisuales.containerShadow,
                formatoFechas: parametrosVisuales.formatoFechas,
                barraCampana: parametrosVisuales.barraCampana,
                posicionFechaActualizacion:
                  parametrosVisuales.posicionFechaActualizacion,
                posicionFechasInicioFin:
                  parametrosVisuales.posicionFechasInicioFin,
                showPuntosTargets: parametrosVisuales.showPuntosTargets,
                campanaPorPagina: parametrosVisuales.campanaPorPagina,
                usuariosPorCampana: parametrosVisuales.usuariosPorCampana
              }
            },
            parametrosVisuales: parametrosVisuales
          });
        }
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, parametrosVisuales } = this.state;
    const visualParams = {
      ...parametrosVisuales,
      tipoVisual: values.parametrosVisuales
        ? values.parametrosVisuales.tipoVisual
        : "BARRAS_HORIZONTAL",
      tieneBorde: values.parametrosVisuales
        ? values.parametrosVisuales.tieneBorde
        : true,
      campanaContainerShadow: values.parametrosVisuales
        ? values.parametrosVisuales.campanaContainerShadow
        : true,
      generalContainerShadow: values.parametrosVisuales
        ? values.parametrosVisuales.generalContainerShadow
        : true,
      containerShadow: values.parametrosVisuales
        ? values.parametrosVisuales.containerShadow
        : true,
      formatoFechas: values.parametrosVisuales
        ? values.parametrosVisuales.formatoFechas
        : "DD/MM/YYYY",
      barraCampana: values.barraCampana
        ? values.parametrosVisuales.barraCampana
        : true,
      posicionFechaActualizacion: values.parametrosVisuales
        ? values.parametrosVisuales.posicionFechaActualizacion
        : "DEBAJO_CAMPANA",
      posicionFechasInicioFin: values.parametrosVisuales
        ? values.parametrosVisuales.posicionFechasInicioFin
        : "DEBAJO_ESTADO",
      showPuntosTargets: values.parametrosVisuales
        ? values.parametrosVisuales.showPuntosTargets
        : true,
      campanaPorPagina: values.parametrosVisuales
        ? values.parametrosVisuales.campanaPorPagina
          ? parseInt(values.parametrosVisuales.campanaPorPagina)
          : 2
        : 2,
      usuariosPorCampana: values.parametrosVisuales
        ? values.parametrosVisuales.usuariosPorCampana
          ? parseInt(values.parametrosVisuales.usuariosPorCampana)
          : 5
        : 5
    };
    const parametros = {
      ...values,
      idPrograma: id,
      parametrosVisuales: JSON.stringify(visualParams)
    };
    await client
      .mutate({
        mutation: CampanaGraphQL.mutations.updateParametrosCampana,
        variables: {
          parametros
        }
      })
      .then(res => {
        this.props.openNotification("Parametros modificados correctamente");
        history.push(`/admin/programa/${id}/campanas`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  async createTestPerformance() {
    const { client } = this.props;
    const { id } = this.state;

    await client
      .mutate({
        mutation: CampanaGraphQL.mutations.createTestPerformance,
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        this.props.openNotification(
          "Performance de prueba creada correctamente."
        );
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleVisualesChange = key => value => {
    const newParams = { ...this.state.parametrosVisuales };
    this.setState({
      parametrosVisuales: set(newParams, key, get(value, "target.value", value))
    });
  };

  onChangeFilaTipo = key => value => {
    const { parametrosVisuales } = this.state;
    let contenidos = get(parametrosVisuales, "banners.contenidos", []);
    if (parseInt(value) - 1 < contenidos.length)
      contenidos.splice(parseInt(value));
    else while (contenidos.length < parseInt(value)) contenidos.push(newBase);
    let payload = set(
      parametrosVisuales,
      "banners.filaTipo",
      get(value, "target.value", value)
    );
    payload = set(parametrosVisuales, "banners.contenidos", contenidos);
    this.setState({
      parametrosVisuales: payload
    });
    this.forceUpdate();
  };

  onChangeBanners = key => value => {
    let payload = {};
    const { parametrosVisuales } = this.state;
    if (key.split(".").pop() === "tipo") {
      let arr = key.replace("0.", "").split(".");
      arr.pop();
      const contenido = get(parametrosVisuales, arr, {});
      if (value === "slider" && !get(contenido, "bases", []).length)
        payload = set(parametrosVisuales, arr, {
          ...newSlider,
          ...contenido
        });
      else if (value === "base")
        payload = set(parametrosVisuales, arr, {
          ...newBase,
          ...contenido
        });
    }
    payload = set(
      parametrosVisuales,
      key.replace("0.", "banners."),
      get(value, "target.value", value)
    );
    this.setState({
      parametrosVisuales: payload
    });
    this.forceUpdate();
  };

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          segmentos={this.state.segmentos}
          campana={this.state.campana}
          parametrosVisuales={this.state.parametrosVisuales}
          handleVisualesChange={this.handleVisualesChange.bind(this)}
          createTestPerformance={this.createTestPerformance.bind(this)}
          onChangeFilaTipo={this.onChangeFilaTipo.bind(this)}
          onChangeBanners={this.onChangeBanners.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
