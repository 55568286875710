import React, { PureComponent } from "react";
import AppQraphQL from "js/graphql/resolvers/programa.resolver";
import WysiwygPanel from "js/pages/Admin/Programa/Legales/WysiwygPanel/index.js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

class Legales extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        condiciones: null,
        privacidad: null,
        preguntas: null
      },
      id: this.props.match.params.id
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      },
      idPrograma
    } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getProgramaById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id || idPrograma
        }
      })
      .then(res => {
        res.data.getProgramas.itemsPagina.length > 0 &&
          this.setState({
            initialValues: {
              condiciones: res.data.getProgramas.itemsPagina[0].condiciones,
              privacidad: res.data.getProgramas.itemsPagina[0].privacidad,
              preguntas: res.data.getProgramas.itemsPagina[0].preguntas
            }
          });
      });
  }

  submit = async () => {
    const {
      client,
      history,
      match: {
        params: { id }
      },
      idPrograma
    } = this.props;
    const { initialValues } = this.state;
    await client
      .mutate({
        mutation: AppQraphQL.queries.updateProgramaLegales,
        variables: {
          programa: {
            idPrograma: parseInt(id || idPrograma, 10),
            ...initialValues
          }
        }
      })
      .then(res => {
        history.push(id ? `/admin/programa/${id}/gestion` : `/contenidos`);
        this.props.openNotification("Actualizado correctamente");
      });
  };

  onChange = e => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    const { classes, history } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      initialValues: { condiciones, privacidad, preguntas }
    } = this.state;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <WysiwygPanel
            name="Términos y condiciones"
            initialValue={condiciones}
            inputName="condiciones"
            onChange={this.onChange}
          />
          <WysiwygPanel
            initialValue={privacidad}
            inputName="privacidad"
            name="Política de privacidad"
            onChange={this.onChange}
          />
          <WysiwygPanel
            initialValue={preguntas}
            name="Preguntas frecuentes"
            inputName="preguntas"
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={() =>
              history.push(id ? `/admin/programa/${id}/gestion` : `/contenidos`)
            }
          >
            Cancelar
          </Button>
          <Button
            onClick={this.submit}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default Legales;
