import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { reduxForm, change, getFormMeta, formValueSelector } from "redux-form";
import { Grid, Button, Typography } from "@material-ui/core";
import { InputTextField } from "js/components/Shared/Form/Fields";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import "./MyForm.css";
import { _t } from "js/utils/TranslationService";

class Form extends Component {
  constructor(props) {
    super(props);
    let id = null;
    let inBackOffice = false;
    if (window.location.pathname.indexOf("admin") !== -1) {
      id = this.props.match.params.id;
      inBackOffice = true;
    } else {
      id = parseInt(AuthService.getIdPrograma());
      inBackOffice = false;
    }
    this.state = {
      enableQr: true,
      enableTerceo: true,
      enableAutomatico: true,
      enableBarcode: true,
      inBackOffice,
      canCodigo: AuthService.hasPerms([Roles.CARGACODIGOS_R])
    };
    this.handleCodigo = this.handleCodigo.bind(this);
  }

  handleScanQr = data => {
    if (data) {
      this.props.changeFieldValue("codigoQR", data);
      this.props.resetErrors();
      this.setState({ enableQr: false });
    }
  };

  handleCodigo = data => {
    if (data) {
      this.props.changeFieldValue("codigo", data);
      this.props.resetErrors();
    }
  };

  handleErrorQr = err => {
    console.log("ERROR AL LEER EL CÓDIGO QR");
    console.error(err);
  };
  handleScanBarcode = codigo => {
    this.props.changeFieldValue("codigoBarra", codigo);
    this.props.resetErrors();
    this.setState({ enableBarcode: false });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      bannerImages
    } = this.props;
    const { canCodigo, inBackOffice, id } = this.state;

    return (
      <Grid container spacing={16}>
        {bannerImages && (
          <Grid item xs={8} sm={12}>
            <img
              src={bannerImages}
              style={{ maxWidth: "1280px", maxHeight: "500px" }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h5">{"Validar código"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              {canCodigo && (
                <>
                  <Grid item>
                    <InputTextField
                      name="codigo"
                      label="Ingrese su código aquí"
                      disabled={disabled}
                      autocomplete="off"
                      autocorrect="off"
                      spellcheck="off"
                      required
                    />
                  </Grid>

                  <Grid className={"errors-list-top"} container spacing={16}>
                    <Grid item xs={12}>
                      <ul className={classes.error}>
                        {errors &&
                          errors.map((e, i) => {
                            return <li key={i}>{_t(e.message.toString())}</li>;
                          })}
                      </ul>
                    </Grid>
                  </Grid>

                  <Grid
                    classname={classes.gridButton}
                    item
                    style={{ marginBottom: "210px" }}
                  >
                    <Button
                      type="button"
                      variant="outlined"
                      size="large"
                      color="primary"
                      classname={"cancel-btn"}
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push(
                          inBackOffice
                            ? `/admin/programa/${id}/carga-de-codigos`
                            : `/carga-de-codigos/new`
                        )
                      }
                    >
                      {_t("Cancelar")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      classname={classes.button}
                      disabled={pristine || submitting || disabled}
                      style={{ marginRight: "10px" }}
                    >
                      {_t("Confirmar")}
                    </Button>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}> */}
                  {/* <QrReader
                      delay={300}
                      onerror={this.handleErrorQr}
                      onscan={this.handleScanQr}
                      style={{ width: '100%' }}
                    /> */}
                  {/* </Grid> */}
                </>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formVentaCodigosCargados",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formVentaCodigosCargados");

Form = connect(
  state => ({
    formMeta: getFormMeta("formVentaCodigosCargados")(state),
    codigo: selector(state, "codigo")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formVentaCodigosCargados", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
