import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Proveedor/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/proveedor.resolver";
import { removeTypename } from "js/utils/Helper";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        tieneSucursales: false,
        estado: "ACTIVO",
        publicaSucursales: true,
        publicaPaginaWeb: false
      },
      disabled: null,
      errors: null,
      id: this.props.match.params.idProveedor
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idProveedor, edit }
      }
    } = this.props;
    if (idProveedor !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getProveedorById,
          fetchPolicy: "network-only",
          variables: {
            idProveedor
          }
        })
        .then(res =>
          this.setState({
            initialValues: {
              ...res.data.getProveedores.itemsPagina[0],
              idPais:
                res.data.getProveedores.itemsPagina[0].areaOperacion &&
                res.data.getProveedores.itemsPagina[0].areaOperacion.pais &&
                res.data.getProveedores.itemsPagina[0].areaOperacion.pais
                  .idPais,
              idZona:
                res.data.getProveedores.itemsPagina[0].areaOperacion &&
                res.data.getProveedores.itemsPagina[0].areaOperacion.zona &&
                res.data.getProveedores.itemsPagina[0].areaOperacion.zona
                  .idZona,
              idLocalidad:
                res.data.getProveedores.itemsPagina[0].areaOperacion &&
                res.data.getProveedores.itemsPagina[0].areaOperacion
                  .localidad &&
                res.data.getProveedores.itemsPagina[0].areaOperacion.localidad
                  .idLocalidad
            },
            disabled: !(edit === "edit")
          })
        );
    }
  }

  async submit(values) {
    let valuesCopy = Object.assign({}, values);
    const areaOperacion = {};
    const {
      client,
      history,
      match: {
        params: { idProveedor }
      }
    } = this.props;

    if (!valuesCopy.tieneSucursales) {
      areaOperacion["areaOperacion_idPais"] = valuesCopy.idPais;
      areaOperacion["areaOperacion_idZona"] = valuesCopy.idZona || null;
      areaOperacion["areaOperacion_idLocalidad"] =
        valuesCopy.idLocalidad || null;
    } else {
      areaOperacion["areaOperacion_idPais"] = null;
      areaOperacion["areaOperacion_idZona"] = null;
      areaOperacion["areaOperacion_idLocalidad"] = null;
    }
    delete valuesCopy.idPais;
    delete valuesCopy.idZona;
    delete valuesCopy.idLocalidad;
    delete valuesCopy.areaOperacion;
    await client
      .mutate({
        mutation:
          idProveedor === "new"
            ? AppQraphQL.mutations.createProveedor
            : AppQraphQL.mutations.updateProveedor,
        variables: {
          proveedor:
            idProveedor === "new"
              ? { ...removeTypename(valuesCopy), ...areaOperacion }
              : { ...removeTypename(valuesCopy), ...areaOperacion, idProveedor }
        }
      })
      .then(res => {
        const message = "Proveedor grabado correctamente";
        this.props.openNotification(message);
        if (idProveedor === "new" && valuesCopy.tieneSucursales) {
          history.push(
            `/admin/proveedor/${
              res.data.createProveedor.idProveedor
            }/sucursal/new`
          );
        } else {
          history.push("/admin/proveedor");
        }
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }
  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
        />
      </Fragment>
    );
  }
}

export default Edit;
