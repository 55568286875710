import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, change, getFormMeta, formValueSelector } from "redux-form";
import {
  Grid,
  FormControl,
  Button,
  Icon,
  Tooltip,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";

import { styles } from "js/components/Shared/Form/styles.js";
import {
  InputTextField,
  InputNumberField,
  ListField,
  CheckboxField
} from "js/components/Shared/Form/Fields";
import {
  SortableComponent,
  ColorPicker,
  PixelPicker,
  FontPicker
} from "js/pages/TemplateBuilder2/Controls";
import Fila from "js/pages/TemplateBuilder2/Components/Fila";
import { getTipo } from "js/pages/TemplateBuilder2/Components/NewItems";

const newBase = getTipo.get("base");

class Form extends Component {
  onChangeParametroVisual = key => value => {
    this.props.handleVisualesChange(key)(value);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      history,
      errors,
      disabled,
      id,
      parametrosVisuales,
      tipoTargetsPorUsuarios,
      tipoPuntosPorExceso,
      tipoVisual,
      onChangeBanners,
      onChangeFilaTipo,
      asignacionPuntos
    } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h4">{"Párametros de campañas"}</Typography>
          </Grid>

          {errors &&
            errors.length !== 0 && (
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors.map(e => (
                    <li>{e.message}</li>
                  ))}
                </ul>
              </Grid>
            )}

          <Grid item xs={12} md={2}>
            <ListField
              name="asignacionPuntos"
              label={"Asignación de puntos"}
              options={[
                { label: "Diario", value: "DIARIO" },
                { label: "Al cierre", value: "AL_CIERRE" },
                { label: "No acredita", value: "NO_ACREDITA" }
              ]}
              disabled={disabled}
              required
            />
          </Grid>

          {asignacionPuntos === "AL_CIERRE" && (
            <Grid item xs={12} sm={4}>
              <InputTextField
                name="conceptoGeneral"
                label="Concepto de movimientos generados"
                helperText="Usar <campana> para incluir el nombre de campaña en el concepto"
                disabled={disabled}
                required
              />
            </Grid>
          )}

          {asignacionPuntos === "DIARIO" && (
            <React.Fragment>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="conceptoTarget"
                  label="Concepto de movimientos generados por completar targets"
                  helperText="Usar <campana>, <objetivo> y <idTarget> para incluirlos en el concepto"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="conceptoObjetivo"
                  label="Concepto de movimientos generados por completar objetivos"
                  helperText="Usar <campana>, <objetivo> para incluirlos en el concepto"
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputTextField
                  name="conceptoCampana"
                  label="Concepto de movimientos generados por completar campañas"
                  helperText="Usar <campana> para incluir el nombre de campaña en el concepto"
                  disabled={disabled}
                  required
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item xs={12} md={3}>
            <FormControl className={classes.field}>
              <InputNumberField
                name="objetivosPorCampana"
                label={"Objetivos por campaña"}
                disabled={disabled}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <CheckboxField
              name="imagenRequerida"
              label="¿Imágen de campaña requerida?"
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">{"Targets"}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <ListField
              name="targetsPorUsuarios.tipo"
              label={"Tipo"}
              options={[
                { label: "Fijo", value: "FIJO" },
                { label: "Variable", value: "VARIABLE" }
              ]}
              disabled={disabled}
            />
          </Grid>

          {tipoTargetsPorUsuarios === "FIJO" && (
            <Grid item xs={12} md={3}>
              <FormControl className={classes.field}>
                <InputNumberField
                  name="targetsPorUsuarios.cantidadTargets"
                  label={"Cantidad"}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          )}

          {tipoTargetsPorUsuarios === "VARIABLE" && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="targetsPorUsuarios.minTargets"
                    label={"Mínimo"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="targetsPorUsuarios.maxTargets"
                    label={"Máximo"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">{"Puntos por exceso"}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <ListField
              name="puntosPorExceso.tipo"
              label={"Tipo"}
              options={[
                { label: "Fijo", value: "FIJO" },
                { label: "Variable", value: "VARIABLE" }
              ]}
              disabled={disabled}
            />
          </Grid>

          {tipoPuntosPorExceso === "FIJO" && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="puntosPorExceso.targetExcedidoPor"
                    label={"Target"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="puntosPorExceso.puntosFijos"
                    label={"Puntos"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          )}

          {tipoPuntosPorExceso === "VARIABLE" && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="puntosPorExceso.targetVariable"
                    label={"Cantidad variable"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl className={classes.field}>
                  <InputNumberField
                    name="puntosPorExceso.puntosVariables"
                    label={"Puntos"}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">{"Párametros visuales"}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6">{"General"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="flex-center"
                  alignItems="center"
                  spacing={24}
                >
                  <Grid item xs={6} md={4}>
                    <CheckboxField
                      name="parametrosVisuales.generalContainerShadow"
                      label="¿Container general con sombra?"
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {"Ancho máximo container"}
                    </Typography>
                    <PixelPicker
                      pixels={get(parametrosVisuales, "anchoGeneral")}
                      onChange={this.onChangeParametroVisual("anchoGeneral")}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {"Padding general"}
                    </Typography>
                    <PixelPicker
                      pixels={get(parametrosVisuales, "paddingGeneral")}
                      onChange={this.onChangeParametroVisual("paddingGeneral")}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {"Margen superior"}
                    </Typography>
                    <PixelPicker
                      pixels={get(parametrosVisuales, "margenSuperior")}
                      onChange={this.onChangeParametroVisual("margenSuperior")}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {"Margen inferior"}
                    </Typography>
                    <PixelPicker
                      pixels={get(parametrosVisuales, "margenInferior")}
                      onChange={this.onChangeParametroVisual("margenInferior")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <ListField
                      name="parametrosVisuales.formatoFechas"
                      label={"Formato de fechas"}
                      options={[
                        { label: "día/mes/año", value: "DD/MM/YYYY" },
                        { label: "día.mes.año", value: "DD.MM.YYYY" },
                        { label: "día-mes-año", value: "DD-MM-YYYY" },
                        { label: "año/mes/día", value: "YYYY/MM/DD" },
                        { label: "año.mes.día", value: "YYYY.MM.DD" },
                        { label: "año-mes-día", value: "YYYY-MM-DD" }
                      ]}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      {"Fuente"}
                    </Typography>
                    <FontPicker
                      activeFont={get(parametrosVisuales, "fontFamily")}
                      onChange={this.onChangeParametroVisual("fontFamily")}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl className={classes.field}>
                      <InputNumberField
                        name="parametrosVisuales.campanaPorPagina"
                        label={"Campaña por página"}
                        disabled={disabled}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl className={classes.field}>
                      <InputNumberField
                        name="parametrosVisuales.usuariosPorCampana"
                        label={"Usuarios por campaña"}
                        disabled={disabled}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6">{"Banner"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <SortableComponent
                  newItem={newBase}
                  isCollapsable={true}
                  items={[parametrosVisuales.banners]}
                  component={Fila}
                  onChange={() => undefined}
                  maxAmountItems={1}
                  staticAmountItems={true}
                  position={[]}
                  metadata={{
                    onChange: onChangeBanners,
                    onChangeFilaTipo: onChangeFilaTipo,
                    idPrograma: id
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6">{"Campaña"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="flex-center"
                  alignItems="center"
                  spacing={24}
                >
                  {/* SHADOW Y FLECHA */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <CheckboxField
                          name="parametrosVisuales.campanaContainerShadow"
                          label="¿Container de campaña con sombra?"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color flecha"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorFlecha")}
                          onChange={this.onChangeParametroVisual("colorFlecha")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño flecha"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeFlecha")}
                          onChange={this.onChangeParametroVisual("sizeFlecha")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* BARRA AL LADO DEL NOMBRE CAMPAÑA */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <CheckboxField
                          name="parametrosVisuales.barraCampana"
                          label="¿Agregar barra a la izquierda del nombre de campaña?"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color barra"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorBarraCampana")}
                          onChange={this.onChangeParametroVisual(
                            "colorBarraCampana"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Ancho barra"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "anchoBarraCampana")}
                          onChange={this.onChangeParametroVisual(
                            "anchoBarraCampana"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* NOMBRE Y DESCRIPCION CAMPAÑA */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color nombre campaña"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorNombreCampana")}
                          onChange={this.onChangeParametroVisual(
                            "colorNombreCampana"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño nombre campaña"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeNombreCampana")}
                          onChange={this.onChangeParametroVisual(
                            "sizeNombreCampana"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color descripción campaña"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(
                            parametrosVisuales,
                            "colorDescripcionCampana"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "colorDescripcionCampana"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño descripción campaña"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(
                            parametrosVisuales,
                            "sizeDescripcionCampana"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "sizeDescripcionCampana"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* FECHAS INICIO Y FIN */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <ListField
                          name="parametrosVisuales.posicionFechasInicioFin"
                          label={"Posición fechas inicio y fin"}
                          options={[
                            {
                              label: "Debajo nombre de campaña",
                              value: "DEBAJO_CAMPANA"
                            },
                            {
                              label: "Después nombre de campaña",
                              value: "LADO_CAMPANA"
                            }
                          ]}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color fechas inicio y fin"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(
                            parametrosVisuales,
                            "colorFechasInicioFin"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "colorFechasInicioFin"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño fechas inicio y fin"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(
                            parametrosVisuales,
                            "sizeFechasInicioFin"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "sizeFechasInicioFin"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* FECHA ACTUALIZACIÓN */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <ListField
                          name="parametrosVisuales.posicionFechaActualizacion"
                          label={"Posición fecha actualización"}
                          options={[
                            {
                              label: "Debajo del estado",
                              value: "DEBAJO_ESTADO"
                            },
                            {
                              label: "Antes de la descripción",
                              value: "ANTES_DESC"
                            },
                            { label: "Antes del chart", value: "ANTES_CHART" },
                            {
                              label: "Debajo del chart a la derecha",
                              value: "DEBAJO_CHART_DERECHA"
                            },
                            {
                              label: "Debajo del chart a la izquierda",
                              value: "DEBAJO_CHART_IZQUIERDA"
                            }
                          ]}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color fecha actualización"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(
                            parametrosVisuales,
                            "colorFechaActualizacion"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "colorFechaActualizacion"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño fecha actualización"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(
                            parametrosVisuales,
                            "sizeFechaActualizacion"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "sizeFechaActualizacion"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* ESTADO */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color container estado"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(
                            parametrosVisuales,
                            "colorBackgroundEstado"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "colorBackgroundEstado"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color estado"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorEstado")}
                          onChange={this.onChangeParametroVisual("colorEstado")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño estado"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeEstado")}
                          onChange={this.onChangeParametroVisual("sizeEstado")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6">{"Objetivo"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="flex-center"
                  alignItems="center"
                  spacing={24}
                >
                  {/* CONTAINER OBJETIVO */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <CheckboxField
                          name="parametrosVisuales.containerShadow"
                          label="¿Container de objetivo con sombra?"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Altura container objetivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(
                            parametrosVisuales,
                            "alturaContainerObjetivo"
                          )}
                          onChange={this.onChangeParametroVisual(
                            "alturaContainerObjetivo"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color nombre objetivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorNombreObjetivo")}
                          onChange={this.onChangeParametroVisual(
                            "colorNombreObjetivo"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño nombre objetivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeNombreObjetivo")}
                          onChange={this.onChangeParametroVisual(
                            "sizeNombreObjetivo"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* TIPO VISUAL */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={12} md={4}>
                        <ListField
                          name="parametrosVisuales.tipoVisual"
                          label={"Tipo de vizualación de performance"}
                          options={[
                            {
                              label:
                                "Barras horizontales (En mobile se renderiza como vertical)",
                              value: "BARRAS_HORIZONTAL"
                            },
                            {
                              label: "Barras verticales",
                              value: "BARRAS_VERTICAL"
                            },
                            { label: "Circular", value: "CIRCULAR" },
                            { label: "Semi circular", value: "SEMI_CIRCULAR" },
                            { label: "Triangulo", value: "TRIANGULOS" }
                          ]}
                          disabled={disabled}
                        />
                      </Grid>

                      {tipoVisual === "BARRAS_HORIZONTAL" && (
                        <React.Fragment>
                          <Grid item xs={6} md={2}>
                            <Typography variant="subtitle1">
                              {"Altura barra"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <PixelPicker
                              pixels={get(parametrosVisuales, "alturaBarra")}
                              onChange={this.onChangeParametroVisual(
                                "alturaBarra"
                              )}
                            />
                          </Grid>
                        </React.Fragment>
                      )}

                      {tipoVisual === "BARRAS_VERTICAL" && (
                        <React.Fragment>
                          <Grid item xs={6} md={2}>
                            <Typography variant="subtitle1">
                              {"Ancho barra"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <PixelPicker
                              pixels={get(parametrosVisuales, "anchoBarra")}
                              onChange={this.onChangeParametroVisual(
                                "anchoBarra"
                              )}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>

                  {/* COLORES */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-center"
                      alignItems="center"
                      spacing={24}
                    >
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color fondo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorFondo")}
                          onChange={this.onChangeParametroVisual("colorFondo")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color avance"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorAvance")}
                          onChange={this.onChangeParametroVisual("colorAvance")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color texto target"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorTextoTarget")}
                          onChange={this.onChangeParametroVisual(
                            "colorTextoTarget"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color texto avance"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorTextoAvance")}
                          onChange={this.onChangeParametroVisual(
                            "colorTextoAvance"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño números"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeNumeros")}
                          onChange={this.onChangeParametroVisual("sizeNumeros")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Tamaño textos"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <PixelPicker
                          pixels={get(parametrosVisuales, "sizeTextos")}
                          onChange={this.onChangeParametroVisual("sizeTextos")}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="subtitle1">
                          {"Color ícono target completado"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <ColorPicker
                          color={get(parametrosVisuales, "colorCheckIcon")}
                          onChange={this.onChangeParametroVisual(
                            "colorCheckIcon"
                          )}
                        />
                      </Grid>

                      {(tipoVisual === "BARRAS_HORIZONTAL" ||
                        tipoVisual === "BARRAS_VERTICAL" ||
                        tipoVisual === "TRIANGULOS") && (
                        <React.Fragment>
                          <Grid item xs={6} md={2}>
                            <Typography variant="subtitle1">
                              {"Color linea target"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <ColorPicker
                              color={get(
                                parametrosVisuales,
                                "colorLineaTarget"
                              )}
                              onChange={this.onChangeParametroVisual(
                                "colorLineaTarget"
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <Typography variant="subtitle1">
                              {"Color linea avance"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <ColorPicker
                              color={get(
                                parametrosVisuales,
                                "colorLineaAvance"
                              )}
                              onChange={this.onChangeParametroVisual(
                                "colorLineaAvance"
                              )}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                      <Grid item xs={12} md={4}>
                        <CheckboxField
                          name="parametrosVisuales.showPuntosTargets"
                          label="¿Mostrar puntos de los targets?"
                          disabled={disabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* BORDE EN LA BARRA DEL CHART  */}
                  {(tipoVisual === "BARRAS_HORIZONTAL" ||
                    tipoVisual === "BARRAS_VERTICAL") && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="flex-center"
                        alignItems="center"
                        spacing={24}
                      >
                        <Grid item xs={12} md={4}>
                          <CheckboxField
                            name="parametrosVisuales.tieneBorde"
                            label="¿Agregar borde a la barra?"
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Typography variant="subtitle1">
                            {"Color borde"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <ColorPicker
                            color={get(parametrosVisuales, "colorBorde")}
                            onChange={this.onChangeParametroVisual(
                              "colorBorde"
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Typography variant="subtitle1">
                            {"Ancho borde"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <PixelPicker
                            pixels={get(parametrosVisuales, "anchoBorde")}
                            onChange={this.onChangeParametroVisual(
                              "anchoBorde"
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              style={{ width: "350px" }}
              onClick={() => this.props.createTestPerformance()}
            >
              {"Generar performance de prueba"}
            </Button>
          </Grid>

          <Grid className={classes.gridButton} item xs={12}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              style={{ marginRight: 20 }}
              onClick={() => history.push(`/admin/programa/${id}/campanas`)}
            >
              {"Cancelar"}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={disabled || submitting}
            >
              {"Guardar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Form = reduxForm({
  form: "formParametrosCampanas",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

const selector = formValueSelector("formParametrosCampanas");

Form = connect(
  state => ({
    formMeta: getFormMeta("formParametrosCampanas")(state),
    tipoTargetsPorUsuarios: selector(state, "targetsPorUsuarios.tipo"),
    tipoPuntosPorExceso: selector(state, "puntosPorExceso.tipo"),
    tipoVisual: selector(state, "parametrosVisuales.tipoVisual"),
    asignacionPuntos: selector(state, "asignacionPuntos")
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formCampana", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
