import React, { PureComponent } from "react";
import cx from "classnames";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/parametrosCargaFacturaPorPuntos.resolver";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddIconIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { findIndex } from "lodash";
import { removeTypename } from "js/utils/Helper";
import SortableComponent from "js/components/FormularioFacturaPorPuntos/Parametros/SortableComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { _t } from "js/utils/TranslationService";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import _ from "lodash";

const initialfields = [
  {
    key: "tasaDeAcreditacion",
    label: "Tasa de acreditación",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Monto",
    value: ["0.0001"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "limitePorFactura",
    label: "Límite por factura",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Numerico",
    value: ["350"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "limiteDiario",
    label: "Límite diario",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Numerico",
    value: ["3500"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "limiteMensual",
    label: "Límite mensual",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Numerico",
    value: ["15000"],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "puntoDeVenta",
    label: "Punto de venta",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Lista",
    value: [],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "importe",
    label: "Importe",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Monto",
    value: [],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "incrementos",
    label: "Incrementos",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Lista",
    value: [],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  },
  {
    key: "regexNroFactura",
    label: "Patrón Nº de Factura",
    visibleRegistro: null,
    requeridoRegistro: null,
    visiblePerfil: null,
    requeridoPerfil: null,
    tipo: "Texto",
    value: [],
    grupo: null,
    placeholder: null,
    disableEdit: true,
    __typename: "DefinicionParametrosFacturaPorPuntos"
  }
];

class Perfil extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      definicionParametrosFacturaPorPuntosValues: initialfields,
      nombreAtributo: "",
      tipoAtributo: "Texto",
      showInfo: true,
      errors: []
    };
  }

  getParametrosFacturaPorPuntos() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { definicionParametrosFacturaPorPuntosValues } = this.state;
    return client
      .query({
        query: AppQraphQL.queries.getParametrosFacturaPorPuntosById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        if (!res.data.getParametrosFacturaPorPuntos.length) return;
        let values = [];
        res.data.getParametrosFacturaPorPuntos.forEach((v, i) => {
          values.push({
            ...v,
            key: v.key
          });
        });

        let unIncluded = _.differenceBy(
          definicionParametrosFacturaPorPuntosValues,
          values,
          "key"
        );
        values = _.concat(values, unIncluded);

        this.setState({
          definicionParametrosFacturaPorPuntosValues: values
        });
      });
  }

  async componentDidMount() {
    this.setState({
      disabled: !AuthService.hasPerms([Roles.FACTURAS_POR_PUNTOS_R])
    });
    await this.getParametrosFacturaPorPuntos();
  }

  handleSortEnded(items) {
    this.setState({ definicionParametrosFacturaPorPuntosValues: items });
  }

  onClick(e, idRow, action = null) {
    const { definicionParametrosFacturaPorPuntosValues } = this.state;
    const index = findIndex(definicionParametrosFacturaPorPuntosValues, {
      key: idRow
    });
    let copy = Object.assign([], definicionParametrosFacturaPorPuntosValues);
    if (action === "delete") {
      copy.splice(index, 1);
    } else {
      let tempObj = {};
      const id = e.target.id || e.target.name;
      const value = e.target.value;
      tempObj = { ...definicionParametrosFacturaPorPuntosValues[index] };
      let result = value;
      if (
        action === "Lista" ||
        action === "Lista_Multiple" ||
        action === "Lista_Autocomplete"
      ) {
        result = value.includes(",") ? value.split(",") : value;
      }
      tempObj[id] =
        action === "boolean"
          ? !definicionParametrosFacturaPorPuntosValues[index][id]
          : result;
      copy.splice(index, 1, tempObj);
    }
    this.labelHasError(copy);
  }

  handleChangeAttributo(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  labelHasError = definicionParametrosFacturaPorPuntosValues => {
    definicionParametrosFacturaPorPuntosValues.forEach(value => {
      value.labelHasError =
        !value.label.length ||
        definicionParametrosFacturaPorPuntosValues.filter(
          value2 => value2.label === value.label
        ).length > 1;
    });
    this.setState({ definicionParametrosFacturaPorPuntosValues });
  };

  addAtributo() {
    const {
      definicionParametrosFacturaPorPuntosValues,
      nombreAtributo,
      tipoAtributo
    } = this.state;
    if (!nombreAtributo || !tipoAtributo) return;
    const index = findIndex(definicionParametrosFacturaPorPuntosValues, {
      label: nombreAtributo
    });
    if (index === -1) {
      let tempObj = {};
      tempObj["label"] = nombreAtributo;
      tempObj["tipo"] = tipoAtributo;
      tempObj["key"] = definicionParametrosFacturaPorPuntosValues.length;

      const newArray = definicionParametrosFacturaPorPuntosValues.slice();
      newArray.push(tempObj);
      const message = "Atributo agregado correctamente";
      this.props.openNotification(message);
      this.setState({
        definicionParametrosFacturaPorPuntosValues: newArray,
        nombreAtributo: "",
        tipoAtributo: "Texto"
      });
    } else {
      const message = "No se puede crear un atributo ya existente";
      this.props.openNotification(message);
    }
  }

  validarCampos(paramsFacturaPorPuntos, required) {
    let result = {
      status: true,
      message: []
    };

    let camposPorValidar = [];

    if (required) {
      camposPorValidar = ["tasaDeAcreditacion"];
    } else {
      camposPorValidar = [
        "limiteDiario",
        "limitePorFactura",
        "limiteMensual",
        "puntoDeVenta",
        "regexNroFactura"
      ];
    }

    for (let campo of camposPorValidar) {
      let parametro = paramsFacturaPorPuntos.find(param => param.key === campo);

      if (parametro) {
        let { key, label, value, tipo } = parametro;
        let val;

        if (
          required ||
          (!required && value[0] && value[0].length > 0) ||
          tipo === "Lista"
        ) {
          switch (tipo) {
            case "Monto":
              let isFloatNumber = /^\d+(\.\d+)?$/.test(value);
              val = parseFloat(value);

              if (!isFloatNumber) {
                result.status = false;
                result.message.push(
                  `El campo ${label} solo se permite valores numéricos con decimales`
                );
              } else if (isNaN(val)) {
                result.status = false;
                result.message.push(
                  `El campo ${label} posee un valor incorrecto`
                );
              } else if (val <= 0) {
                result.status = false;
                result.message.push(
                  `El campo ${label} debe ser mayor o igual a 0 (cero)`
                );
              }
              break;
            case "Numerico":
              let isIntegerNumber = /^[0-9]+$/.test(value);

              val = parseInt(value);

              if (!isIntegerNumber) {
                result.status = false;
                result.message.push(
                  `El campo ${label} solo permite valores numéricos enteros positivos`
                );
              } else if (isNaN(val)) {
                result.status = false;
                result.message.push(
                  `El campo ${label} posee un valor incorrecto`
                );
              } else if (val <= 0) {
                result.status = false;
                result.message.push(
                  `El campo ${label} debe ser mayor o igual a 0 (cero)`
                );
              }
              break;
            case "Lista":
              let values = [];

              for (val of value) {
                if (val && val.trim().length > 0) {
                  values.push(val.trim());
                }
              }

              if (values.length === 0) values = [""];

              parametro.value = values;

              break;
            case "Texto":
              if (key === "regexNroFactura" && value && value.length > 0) {
                //Se eliminan barras al inicio/fin sin las hubiera
                let exp = value[0].replace(/^\//, "").replace(/\/$/, "");

                try {
                  //testeamos que sea una expresión regular válida
                  RegExp(exp);
                } catch (error) {
                  result.status = false;
                  result.message.push(
                    `La expresión del campo ${label} posee un formato inválido`
                  );
                }
              }

              break;
          }
        }
      }
    }
    return result;
  }

  async saveAction() {
    const {
      client,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    try {
      let { definicionParametrosFacturaPorPuntosValues } = this.state;

      if (
        definicionParametrosFacturaPorPuntosValues.filter(v => v.labelHasError)
          .length
      )
        return;
      definicionParametrosFacturaPorPuntosValues.forEach(element => {
        if (typeof element.key === "number") {
          delete element.key;
        }
        delete element.labelHasError;
      });

      let estadoCamposObligatorios = this.validarCampos(
        definicionParametrosFacturaPorPuntosValues,
        true
      );

      if (!estadoCamposObligatorios.status)
        throw new Error(estadoCamposObligatorios.message[0]);

      let estadoOtrosCampos = this.validarCampos(
        definicionParametrosFacturaPorPuntosValues,
        false
      );

      if (!estadoOtrosCampos.status)
        throw new Error(estadoOtrosCampos.message[0]);

      definicionParametrosFacturaPorPuntosValues = definicionParametrosFacturaPorPuntosValues.map(
        field => {
          if (field.key === "regexNroFactura") {
            field.value = field.value
              .join("")
              .replace(/^\//, "")
              .replace(/\/$/, "");
          }
          return field;
        }
      );

      await client
        .mutate({
          mutation: AppQraphQL.queries.bulkParametrosFacturaPorPuntos,
          refetchQueries: [`getRedirect`],
          variables: {
            definicionesParametrosFacturaPorPuntos: removeTypename(
              definicionParametrosFacturaPorPuntosValues
            ),
            idPrograma: parseInt(id, 10)
          }
        })
        .then(() => {
          history.push("/admin/programa/" + id + "/factura-por-puntos");
          this.props.openNotification(
            _t("Parametros factura por puntos actualizados correctamente")
          );
        });
    } catch (error) {
      this.props.openNotification(_t(error.message));
    }
  }

  volver = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push(`/admin/programa/${id}/factura-por-puntos`);
  };

  changeIcon() {
    this.setState(prevState => ({ showInfo: !prevState.showInfo }));
  }

  render() {
    const {
      definicionParametrosFacturaPorPuntosValues,
      nombreAtributo,
      tipoAtributo,
      showInfo
    } = this.state;
    const { classes } = this.props;
    const { disabled } = this.state;
    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Parámetros factura por puntos
        </Typography>
        <div className={classes.root}>
          {!disabled && (
            <div className={classes.addInput}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="name-simple">Nombre del campo</InputLabel>
                <Input
                  name="nombreAtributo"
                  value={nombreAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  name="tipoAtributo"
                  value={tipoAtributo}
                  onChange={e => this.handleChangeAttributo(e)}
                >
                  <MenuItem value={"Texto"}>Texto</MenuItem>
                  <MenuItem value={"Numerico"}>Número</MenuItem>
                  <MenuItem value={"Monto"}>Monto</MenuItem>
                  <MenuItem value={"Lista"}>Lista</MenuItem>
                  <MenuItem value={"Lista_Multiple"}>Lista múltiple</MenuItem>
                  <MenuItem value={"Lista_Autocomplete"}>
                    Lista autocompletable
                  </MenuItem>
                </Select>
              </FormControl>
              <Tooltip
                title="Agregar atributo"
                placement="top-start"
                enterDelay={300}
              >
                <IconButton onClick={this.addAtributo.bind(this)}>
                  <AddIconIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={cx(
                      classes.align,
                      classes.columnWidth100,
                      classes.border
                    )}
                  >
                    Nombre
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Tipo de campo
                  </TableCell>
                  <TableCell
                    className={cx(classes.align, classes.columnWidth100)}
                  >
                    Valores
                  </TableCell>
                </TableRow>
              </TableHead>
              <SortableComponent
                onClick={this.onClick.bind(this)}
                sortEnded={this.handleSortEnded.bind(this)}
                data={definicionParametrosFacturaPorPuntosValues}
                disabled={disabled}
              />
            </Table>
          </Paper>
          {!disabled && (
            <div className={classes.buttonSave}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.volver}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.saveAction.bind(this)}
              >
                Guardar
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withApollo(withRouter(Perfil));
