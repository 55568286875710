import moment from "moment";
import { get, isEmpty } from "lodash";

const traverse = function(o, fn) {
  for (var i in o) {
    fn.apply(this, [i, o[i]]);
    if (o[i] !== null && typeof o[i] === "object" && Array.isArray(o[i])) {
      traverse(o[i], fn);
    }
  }
};

export const plainObject = n => {
  var data = {};
  traverse(n, function(k, v) {
    // if (Array.isArray(v) || (typeof v !== "object" && v !== "")) data[k] = v;
    // console.log(parseInt(k, 10));
    // console.log(isNumber(parseInt(k, 10)));
    // console.log(isNumber(k));
    // console.log(typeof k === 'string' && k.match(/[a-z]/i));
    if (
      (Array.isArray(v) || (typeof v !== "object" && v !== "")) &&
      typeof k === "string" &&
      k.match(/[a-z]/i)
    )
      data[k] = v;
  });
  return data;
};

export const plainObjectWithDefault = (n, defaultNullValue) => {
  var data = {};
  traverse(n, function(k, v) {
    if (typeof v !== "object") {
      data[k] = v;
    } else if (v === null) {
      data[k] = defaultNullValue;
    }
  });
  return data;
};

export const removeTypename = value => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const descend = (object, sub, split = "_") => {
  var handle = object,
    stack = sub.split(split),
    history = [],
    peek;
  if (
    handle[stack[0]] === null ||
    handle[stack[0]] === undefined ||
    handle[stack[0]] === ""
  )
    return "--";
  while (handle[stack[0]]) {
    if (peek) {
      history.push(peek);
    }
    peek = stack.shift();
    handle = handle[peek];
  }
  if (stack.length > 0) {
    history.push(peek);
    return "--";
  }

  return handle;
};

export const showErrors = e => {
  let err = [];
  err["message"] = e.message || "Error";
  return !isEmpty(e.graphQLErrors) ? e.graphQLErrors : [err];
};

export const renderFecha = fecha =>
  fecha && moment(fecha).isValid() ? moment(fecha).format("DD/MM/YYYY") : "--";

export const renderFechaWithFormat = (format, fecha) =>
  fecha && moment(fecha).isValid() ? moment(fecha).format(format) : "--";

export const renderHora = fecha =>
  fecha ? moment(fecha).format("HH:mm") : "--";

export const capitalizeFirstLetter = string =>
  get(string, "length", 0) > 0 &&
  string.charAt(0).toUpperCase() + string.slice(1);

const parseAmbiente = key => {
  const rawFile = new XMLHttpRequest();
  rawFile.open(
    "GET",
    `${window.location.protocol}//${window.location.hostname}:${
      window.location.port
    }/.ambiente`,
    false
  );
  rawFile.send(null);
  const regex = new RegExp(key, "i");
  const lines = rawFile.responseText.split("\n");
  const keyValue = lines.find(line => line.match(regex));

  const valueFromFile = keyValue && keyValue.replace(key, "");
  return valueFromFile || "dev";
};

export const getEnv = parseAmbiente("ambiente=");

export const setJivoChat = (modulos, chatOnline) => {
  const modulo =
    modulos && modulos.find(({ nombreGrupo }) => nombreGrupo === "Chat Online");

  if (modulo && modulo.estado === "ACTIVO" && chatOnline.codigoChatOnline) {
    const existingScript = document.getElementById("jivo-chat-script");
    if (existingScript) {
      console.log("Jivo chat ya se cargó desde el login");
    } else {
      const script = document.createElement("script");
      script.async = true;
      script.id = "jivo-chat-script";
      script.src = `//code.jivosite.com/widget/${chatOnline.codigoChatOnline}`;

      document.head.appendChild(script);
    }
  }
};
