import { USER_ROLES as Roles } from "js/models/User";
import { kibanaUrl } from "js/constants";
import { getEnv } from "js/utils/Helper";
import { navegacion } from "./glosario";
import { subMonths, startOfDay, endOfDay } from "date-fns";
import ReporteIndicadores from "./ReporteIndicadores";
import NuevoReporteIndicadores from "./NuevoReporteIndicadores";
export const FILTROS = {
  PROGRAMA: "programa",
  SEGMENTO: "segmento",
  USUARIO: "usuario",
  MODULO: "modulo"
};

export const getUrl = ({
  idPrograma,
  idSegmento,
  idUsuario,
  from,
  to,
  id,
  modulo
}) => {
  let url = `${kibanaUrl}?id=${id}`;
  if (idPrograma) url += `&idPrograma=${idPrograma}`;
  if (idSegmento) url += `&idSegmento=${idSegmento}`;
  if (idUsuario) url += `&idUsuario=${idUsuario}`;
  if (from) url += `&from=${from}`;
  if (to) url += `&to=${to}`;
  if (modulo) url += `&modulo=${encodeURIComponent(modulo)}`;
  url += `&env=${getEnv}`;
  return url;
};

export default [
  {
    number: 1,
    name: "Indicadores",
    permissions: [Roles.REPORTE_INDICADORES],
    filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO],
    withTodosProgramas: true,
    id: "5481a890-f989-11e9-af53-959bd73e3002",
    height: 200,
    noCustom: true,
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    },
    componentReporte: NuevoReporteIndicadores
  },
  {
    number: 2,
    name: "Evolución de Canjes",
    id: "8e437b60-d6d7-11e8-829b-4f3a024c4407",
    permissions: [Roles.REPORTE_CANJES],
    filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO],
    withTodosProgramas: true,
    height: 2250,
    noCustom: true,
    componentReporte: ReporteIndicadores,
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    number: 3,
    name: "Cuenta Corriente Usuarios",
    id: "c372d120-d6d5-11e8-829b-4f3a024c4407",
    permissions: [Roles.REPORTE_MOVIMIENTOS_USUARIO],
    filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO],
    withTodosProgramas: false,
    height: 1080,
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    number: 4,
    name: "Navegación",
    id: "da429b90-d213-11e8-bb95-4f8f2de97a94",
    permissions: [Roles.REPORTE_NAVEGACION],
    filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO, FILTROS.MODULO],
    withTodosProgramas: true,
    height: 1700,
    glosario: navegacion,
    modulo: "Login",
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    number: 5,
    name: "Detalle de Canjes",
    id: "f8bc3770-d637-11e8-9a45-0956167c9cc5",
    permissions: [Roles.REPORTE_BALANCE],
    filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO],
    withTodosProgramas: true,
    height: 1300,
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    number: 6,
    name: "Cuenta Corriente Programa",
    id: "0ed8c0a0-d61a-11e8-9a45-0956167c9cc5",
    permissions: [Roles.REPORTE_MOVIMIENTOS_PROGRAMA],
    filter: [FILTROS.PROGRAMA],
    withTodosProgramas: false,
    height: 1040,
    noCustom: true,
    defaultFilter: {
      range: "custom",
      dates: {
        from: startOfDay(subMonths(new Date(), 12)),
        to: endOfDay(new Date())
      }
    }
  }
  // {
  //   number: 6,
  //   name: "Ranking de canjes",
  //   id: "bc510790-5add-11e9-b66a-9d0cb37db61c",
  //   permissions: [Roles.REPORTE_RANKING_PRODUCTOS],
  //   filter: [FILTROS.PROGRAMA, FILTROS.SEGMENTO],
  //   withTodosProgramas: true,
  //   height: 1160
  // }
];
