import React from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import moment from "moment";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import DatePicker from "js/components/Shared/Form/Fields/DatePicker";
import Divider from "@material-ui/core/Divider";
import { TextField } from "@material-ui/core";
import ProductosField from "./Productos";
import { removeTypename } from "../../utils/Helper";
import { addImageURL, regexs } from "js/constants";
import { getUrl } from "js/utils";
import { _t } from "js/utils/TranslationService";
import CargaFacturasQraphQL from "js/graphql/resolvers/cargaFacturas.resolver";
import Modal from "./modal";
import SplashScreen from "js/pages/Loading";
import { FACING_MODE_ENVIRONMENT } from "./utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ErrorList from "js/components/Shared/ErrorList";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import {
  fetchParametrosVisuales,
  getBanners,
  cleanText
} from "js/utils/helpers/FacturaPorPuntos.helpers.js";
import Fila from "js/components/Fila";

const videoConstraints = {
  width: { min: 480 },
  height: { min: 720 },
  aspectRatio: 1.5,
  facingMode: FACING_MODE_ENVIRONMENT
};

class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.cam = React.createRef();
    this.state = {
      fileImage: null,
      fileName: "",
      imagen: [],
      isPdf: false,
      disabled: false,
      puntoVenta: "",
      numeroFactura: "",
      fechaFactura: moment().toISOString(),
      productos: [],
      detalle: "",
      openModal: false,
      imagenModal: [],
      errors: null,
      selectedProducto: null,
      selectedProductos: [],
      isLoadingImage: false,
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false
    };
    const { initialFilter = {} } = props;
    let dateFactura = { ...initialFilter };
    this.handleCapture = this.handleCapture.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openModalFunction = this.openModalFunction.bind(this);
    this.getUrlFacturaImagen = this.getUrlFacturaImagen.bind(this);
    this.getFileName = this.getFileName.bind(this);
    this.takeImage = this.takeImage.bind(this);
    this.submitValue = this.submitValue.bind(this);
    this.submitImage = this.submitImage.bind(this);
  }

  componentDidMount = async () => {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;

    const { parametrosVisuales, banners } = this.state;

    const dataParametrosVisuales = await fetchParametrosVisuales(
      id || localStorage.getItem("idPrograma"),
      client
    );

    if (dataParametrosVisuales && dataParametrosVisuales.banners) {
      let banners = getBanners(dataParametrosVisuales.banners, true);

      if (banners) {
        banners.contenidos = cleanText(banners);
      }
      this.setState({ banners });
    }
  };

  validatorRegex = value => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { inBackOffice } = this.state;
    let idProgramaLookFor = inBackOffice
      ? parseInt(id)
      : parseInt(localStorage.getItem("idPrograma"));
    let isInvalid = false;
    let regexVerification = regexs.find(regex => {
      return regex.idPrograma === idProgramaLookFor;
    });
    if (!regexVerification) return isInvalid;
    if (!regexVerification.regex.test(value.numeroFactura)) {
      isInvalid = true;
    }
    return isInvalid;
  };

  submitValueDate = e => {
    this.setState(({}) => ({
      fechaFactura: e.target.value
    }));
  };

  handleProductSelect = event => {
    this.setState({ selectedProducto: event.target.value });
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleCapture = value => {
    this.setState({
      isCapture: value
    });
  };

  handleDeleteFileImage = () => {
    this.setState({
      fileImage: null,
      fileName: "",
      imagen: [],
      imagenModal: [],
      disabled: false
    });
  };

  handleCleanImage = () => {
    this.setState({ fileImage: null });
  };

  handleDisabled = () => {
    this.setState({ disabled: true });
  };

  handleDeleteProducto = value => {
    const arr = this.state.selectedProductos;
    const objWithIdIndex = arr.findIndex(obj => obj.value === value);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    this.setState({ selectedProductos: arr });
  };

  handleSubProducto = index => {
    const arr = this.state.selectedProductos;
    if (arr[index].cantidad === 1) return;
    arr[index].cantidad--;
    this.setState({ selectedProductos: arr });
  };

  handleAddProducto = index => {
    const arr = this.state.selectedProductos;
    arr[index].cantidad++;
    this.setState({ selectedProductos: arr });
  };

  openModalFunction = value => {
    this.setState({
      openModal: value,
      disabled: false
    });
  };

  getUrlFacturaImagen = value => {
    this.setState({ imagenModal: value });
  };

  getFileName = value => {
    this.setState({
      fileName: value
    });
  };

  takeImage = async () => {
    const imagenCam = this.cam.current.getScreenshot();
    this.setState({
      fileImage: imagenCam
    });
    const mensaje = "Imagen capturada correctamente.";
    return this.props.openNotification(mensaje);
  };

  async submitImage() {
    if (!this.state.fileImage) throw Error("No hay imagen para cargar.");

    let file = null;

    await fetch(this.state.fileImage)
      .then(res => res.blob())
      .then(blob => {
        file = new File([blob], "Carga de factura", { type: "image/jpg" });
      });

    const { height, width } = { height: 2048, width: 1536 };
    const formData = new window.FormData();
    formData.append("image", file);
    formData.append("context", "cargaFactura");
    formData.append("height", height);
    formData.append("width", width);

    this.setState({ isLoadingImage: true });
    await fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ imagen }) => ({
          imagen: imagen.concat(getUrl(url)),
          isLoadingImage: false
        }));
      })
      .then(() => {
        const mensaje = "Imagen guardada correctamente.";
        this.props.openNotification(mensaje);
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  }

  async getPuntosProducto(codigo) {
    const {
      client,
      match: {
        params: { id }
      }
    } = this.props;

    const { data, errors } = await client.query({
      query: CargaFacturasQraphQL.queries.getPuntosProducto,
      variables: {
        idPrograma: id || localStorage.getItem("idPrograma"),
        codigo
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    if (data.getPuntosProducto.puntos) return data.getPuntosProducto.puntos;
    else if (errors) {
      this.setState({ errors: errors });
      return 0;
    }
  }

  updateAutocompleteQueryParams = key => async select => {
    const arr = this.state.selectedProductos;
    // Chequeamos que no se encuentre agregado
    if (arr.findIndex(p => p.value === select[0].value) > -1) return;
    const value = select.map(option => option);
    let puntos = await this.getPuntosProducto(select[0].value);
    let obj = [...this.state.selectedProductos];
    obj.push({ cantidad: 1, puntos: puntos, ...value[0] });
    return this.setState({ selectedProductos: obj });
  };

  async submitValue() {
    const {
      puntoVenta,
      numeroFactura,
      fechaFactura,
      detalle,
      selectedProductos,
      imagenModal,
      inBackOffice,
      imagen
    } = this.state;
    const {
      match: {
        params: { id }
      },
      client,
      history
    } = this.props;

    if (
      numeroFactura === "" ||
      puntoVenta === "" ||
      fechaFactura === "" ||
      !selectedProductos
    ) {
      return this.props.openNotification(
        "Debe completar los campos obligatorios."
      );
    }

    if (this.validatorRegex({ numeroFactura })) {
      return this.props.openNotification(
        "El formato del campo 'Número de Factura' es inválido."
      );
    }

    if (selectedProductos.length === 0) {
      return this.props.openNotification("Debe agregar al menos un producto.");
    }

    try {
      // No se se cargó la imagen desde el modal, intentamos obtener desde la captura de la cámara
      if (!imagen[0] && !this.state.imagenModal[0]) await this.submitImage();
    } catch (error) {
      return this.props.openNotification(error.toString());
    }

    let variables = {};

    const transformProducts = () => {
      return selectedProductos.map(prod => {
        return {
          codigo: prod.value,
          nombre: prod.label,
          cantidad: prod.cantidad,
          puntos: prod.puntos
        };
      });
    };

    variables.idPrograma = id || localStorage.getItem("idPrograma");
    variables.idUsuario = localStorage.getItem("idUsuario");
    variables.puntoVenta = puntoVenta;
    variables.numeroFactura = numeroFactura;
    variables.fechaFactura = fechaFactura;
    variables.detalle = detalle;
    variables.productos = transformProducts();
    // variables.imagen = !imagenModal[0] ? this.state.imagen : [];
    // variables.imagenModal = !this.state.imagen[0] ? imagenModal : [];
    variables.facturaUrl = !this.state.imagen[0]
      ? imagenModal[0]
      : this.state.imagen[0];
    variables.estado = "PENDIENTE_APROBACION";

    await client
      .mutate({
        mutation: CargaFacturasQraphQL.mutations.createCargaFactura,
        variables: {
          cargaFactura: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje = _t("Factura enviada");
        this.props.openNotification(mensaje);
        if (!inBackOffice) {
          history.push(`/landing`);
        } else {
          history.push(`/admin/programa/${id}/moderacion`);
        }
      })
      .catch(errors => {
        if (errors.toString().includes("error al enviar el mail")) {
          this.props.openNotification("Error al enviar el mail");
          if (!inBackOffice) {
            history.push(`/landing`);
          } else {
            history.push(`/admin/programa/${id}/moderacion`);
          }
        } else {
          this.setState({ errors: errors });
          return false;
        }
      });
  }

  render() {
    const {
      classes,
      match: {
        params: { id }
      },
      search
    } = this.props;
    const {
      isCapture,
      disabled,
      openModal,
      selectedProductos,
      inBackOffice,
      banners
    } = this.state;

    return (
      <>
        {banners &&
          banners.visible &&
          !inBackOffice && (
            <div style={{ marginBottom: "1.5%" }}>
              <Fila {...banners} baseLink={"Banners Performance"} />
            </div>
          )}
        <Card className={classes.card} raised>
          <Grid container spacing={16} className={classes.gridCard}>
            {/*cambio cliente por punto venta */}
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  required={true}
                  id="puntoVenta"
                  name={"punto de venta"}
                  label={_t("Punto de Venta")}
                  value={this.state.puntoVenta}
                  onChange={this.handleChange("puntoVenta")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  required={true}
                  id={"numeroFactura"}
                  name={"numeroFactura"}
                  label={_t("N° Factura")}
                  value={this.state.numeroFactura}
                  onChange={this.handleChange("numeroFactura")}
                />
              </FormControl>
            </Grid>
            {/* fecha de factura debe ser formateo liza dd/mm/aaaa */}
            {/* fecha de creacion para poder realizar el filtro en la moderación,
              fecha de factura es laque manda en el filtro de busqueda */}
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <DatePicker
                  key={"fechaFactura"}
                  name={"fechaFactura"}
                  label={_t("Fecha Factura")}
                  input={{
                    onChange: date =>
                      this.submitValueDate({
                        target: {
                          name: "fechaFactura",
                          value: date.toISOString()
                        }
                      }),
                    value: moment(this.state.fechaFactura)
                  }}
                  format={"DD/MM/YYYY"}
                  autoOk={true}
                  startOfDate
                  disableFuture
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  id={"detalle"}
                  name={"detalle"}
                  label={_t("Detalle")}
                  value={this.state.detalle}
                  onChange={this.handleChange("detalle")}
                  className={classes.textField}
                  margin="normal"
                  multiline
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <ProductosField
                  noOptionsMessage={`No hay ${_t("Productos").toLowerCase()}`}
                  onChange={this.updateAutocompleteQueryParams(
                    "selectedProductos"
                  )}
                  value={selectedProductos}
                  multiple={true}
                  search={search}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <List dense>
                {selectedProductos.map((producto, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`x${producto.cantidad} - ${producto.label} `}
                        secondary={`Cód.: ${producto.value}`}
                      />
                      <IconButton
                        onClick={() => this.handleSubProducto(index)}
                        aria-label="Restar"
                      >
                        <RemoveIcon style={{ fontSize: "medium" }} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.handleDeleteProducto(producto.value)
                        }
                        aria-label="Eliminar"
                      >
                        <DeleteIcon style={{ fontSize: "medium" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => this.handleAddProducto(index)}
                        aria-label="Agregar"
                      >
                        <AddIcon style={{ fontSize: "medium" }} />
                      </IconButton>
                      {/* </ListItemSecondaryAction> */}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
          {isCapture ? (
            <>
              {!this.state.fileImage ? (
                <>
                  <Dialog
                    open={isCapture && !this.state.fileImage}
                    PaperProps={{ className: classes.dialogContent }}
                  >
                    <Webcam
                      className={classes.webcam}
                      audio={false}
                      // height={200}
                      // width={200}
                      ref={this.cam}
                      screenshotFormat="imagen/jpeg"
                      videoConstraints={videoConstraints}
                    />
                    <CardActions className={classes.actionContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ isCapture: false })}
                      >
                        {_t("Cancelar")}
                      </Button>
                      <Button
                        onClick={this.takeImage}
                        variant="contained"
                        color="primary"
                      >
                        {_t("Captura")}
                      </Button>
                    </CardActions>
                  </Dialog>
                </>
              ) : (
                <>
                  <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />

                  <img
                    style={{ width: "100%", padding: "10px" }}
                    src={this.state.fileImage}
                  />
                  <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />

                  <CardActions className={classes.actionContainer}>
                    <Button
                      onClick={() => this.handleCleanImage()}
                      variant="contained"
                      color="primary"
                    >
                      {_t("Volver a Intentar")}
                    </Button>
                  </CardActions>
                </>
              )}
            </>
          ) : (
            <>
              <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />
              <CardActions className={classes.actionContainer}>
                {/* COLOCAR NOMBRE DEL ARCHIVO SUBIDO Y LA POSIBILIDAD DE ELIMINARLO */}
                <Button
                  disabled={this.state.isCapture}
                  onClick={() => this.openModalFunction(true)}
                  variant="contained"
                  color="primary"
                >
                  {_t("Subir imagen")}
                </Button>
                {!isCapture &&
                  !openModal &&
                  this.state.imagenModal[0] && (
                    <Chip
                      label={this.state.fileName}
                      deleteIcon
                      color="black"
                      onDelete={this.handleDeleteFileImage}
                      variant="outlined"
                    />
                  )}
                {openModal ? (
                  <Modal
                    disabled={disabled}
                    openModalFunction={this.openModalFunction}
                    getUrlFacturaImagen={this.getUrlFacturaImagen}
                    getFileName={this.getFileName}
                    handleDisabled={this.handleDisabled}
                  />
                ) : null}
                <Button
                  disabled={disabled}
                  onClick={() => this.handleCapture(true)}
                  variant="contained"
                  color="primary"
                >
                  {_t("Capturar Imagen")}
                </Button>
              </CardActions>
            </>
          )}

          {this.state.isLoadingImage && (
            <Grid container item style={{ height: 100 }}>
              <SplashScreen className={classes.progress} />
            </Grid>
          )}
          <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />
          <CardActions className={classes.actionContainer}>
            <FormControl className={classes.formControl}>
              <Button
                type="submit"
                onClick={this.submitValue}
                variant="contained"
                color="primary"
              >
                Enviar
              </Button>
            </FormControl>
          </CardActions>
          <CardActions className={classes.actionContainer}>
            <ErrorList errors={this.state.errors} belowInput />
          </CardActions>
        </Card>
      </>
    );
  }
}

MyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitValue: PropTypes.func.isRequired
};

export default MyForm;
