import React, { PureComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tabla from "js/components/TablaGenerarEleccionProducto";
import Seleccion from "js/pages/Admin/Programa/EleccionProducto/Seleccion.js";
import ProgramaQraphQL from "js/graphql/resolvers/programa.resolver";
import ProductoQraphQL from "js/graphql/resolvers/producto.resolver";
import CatalogoQraphQL from "js/graphql/resolvers/catalogo.resolver";
import SegmentoQraphQL from "js/graphql/resolvers/segmento.resolver";
import { findIndex, isEmpty, size } from "lodash";
import ErrorList from "js/components/Shared/ErrorList";
import { TreeField } from "js/components/Shared/Form/Fields";

const ID_PROGRAMA = "idPrograma";
const AJUSTE = "porcentaje";
const STOCK_INFINITO = "stockInfinito";
const STOCK_ERA_INFINITO = "stockEraInfinito";
const STOCK_PROGRAMA = "stockPorPrograma";
const STOCK_POR_USUARIO = "stockPorUsuario";
const STOCK_POR_PERIODO = "stockPorPeriodo";
const PERIODO = "periodo";

class EleccionProducto extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programa: {},
      productosYaElegidos: [], // productos ya seleccionados
      segmentos: [],
      segmentosSeleccionados: [],
      elecciones: [], // eleccion actual de los productos,
      errors: null
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      client
    } = this.props;

    // TODO: refactor en 1 unica call
    await Promise.all([
      client
        .query({
          query: ProgramaQraphQL.queries.getProgramaById,
          fetchPolicy: "network-only",
          variables: { idPrograma: id }
        })
        .then(res => {
          res.data.getProgramas.itemsPagina.length > 0 &&
            this.setState({
              programa: res.data.getProgramas.itemsPagina[0]
            });
        }),
      client
        .query({
          query: ProductoQraphQL.queries.getEleccionProductos,
          fetchPolicy: "network-only",
          variables: { eleccionProductoLike: { idPrograma: id } }
        })
        .then(res => {
          res.data.getEleccionProductos.itemsPagina.length > 0 &&
            this.setState({
              productosYaElegidos: res.data.getEleccionProductos.itemsPagina
            });
        }),
      client
        .query({
          query: SegmentoQraphQL.queries.getSegmentosInput,
          variables: { idPrograma: id }
        })
        .then(res => {
          this.setState({
            segmentos: (res.data.getSegmentos || []).map(s => ({
              ...s,
              checked: false
            }))
          });
        })
    ]);
  }

  onChange = (ids, item) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (Number.isInteger(item)) {
      this.remove(item);
      return;
    }
    let { elecciones } = this.state;
    let newItem = Object.assign({}, item);
    newItem[ID_PROGRAMA] = id;
    newItem[AJUSTE] = 0;
    newItem[STOCK_ERA_INFINITO] = item.stockInfinito;
    newItem[STOCK_INFINITO] = item.stockInfinito;
    newItem[STOCK_PROGRAMA] = "";
    newItem[STOCK_POR_USUARIO] = "";
    newItem[STOCK_POR_PERIODO] = "";
    newItem[PERIODO] = "";
    delete newItem["__typename"];
    delete newItem["proveedor"];
    elecciones.push(newItem);
    this.setState({ elecciones, timestamp: new Date() });
  };

  validate(row, name, value) {
    if (name === STOCK_PROGRAMA && value > row.stock) {
      return row.stock;
    } else if (name === STOCK_POR_USUARIO && value > row.stockPorPrograma) {
      return row.stockPorPrograma;
    } else if (name === STOCK_POR_PERIODO && value > row.stockPorUsuario) {
      return row.stockPorUsuario;
    }
    return value;
  }

  handleChange(e, idRow) {
    const { elecciones } = this.state;
    const index = findIndex(elecciones, { idProducto: idRow });
    let copy = Object.assign([], elecciones);
    let tempObj = {};
    const id = e.target.name;
    let value = e.target.checked || e.target.value;
    // esto es para asegurar que se pueda usar coma en el campo
    if (id === "porcentaje") {
      const regex = /^[0-9.,-]+$/;
      if (!regex.test(value)) {
        return;
      }
    }
    // si es infinito, cambiar stock maestro a vacio
    tempObj = { ...elecciones[index] };
    if (id === STOCK_INFINITO) {
      tempObj = { ...elecciones[index], stockPorPrograma: null };
    }
    tempObj[id] = value;
    //Stock validation
    if (
      id === STOCK_PROGRAMA ||
      id === STOCK_POR_USUARIO ||
      id === STOCK_POR_PERIODO
    ) {
      if (parseInt(tempObj[STOCK_PROGRAMA], 10) > tempObj.stock) {
        tempObj[STOCK_PROGRAMA] = tempObj.stock;
      }
      if (
        parseInt(tempObj[STOCK_POR_USUARIO], 10) >
        parseInt(tempObj[STOCK_PROGRAMA], 10)
      ) {
        tempObj[STOCK_POR_USUARIO] = tempObj[STOCK_PROGRAMA];
      }
      if (
        parseInt(tempObj[STOCK_POR_PERIODO], 10) >
        parseInt(tempObj[STOCK_POR_USUARIO], 10)
      ) {
        tempObj[STOCK_POR_PERIODO] = tempObj[STOCK_POR_USUARIO];
      }
    }
    copy[index] = Object.assign(copy[index], tempObj);
    this.setState({ elecciones: copy });
  }

  onChangeSegmento = selectedNodes => {
    this.setState({ segmentosSeleccionados: selectedNodes });
  };

  async saveAction() {
    let segmentoGroup = [];
    const { segmentosSeleccionados, elecciones } = this.state;
    const {
      match: {
        params: { id }
      },
      client
    } = this.props;
    if (isEmpty(segmentosSeleccionados)) {
      let e = [];
      e["message"] = "El segmento no puede estar vacío";
      this.setState({ errors: e });
      return false;
    }
    if (
      this.state.elecciones.filter(prod => prod[AJUSTE] !== 0 && !prod[AJUSTE])
        .length
    ) {
      let e = [];
      e["message"] = "El campo ajuste no puede estar vacío";
      this.setState({ errors: e });
      return false;
    }
    segmentosSeleccionados.map(
      item =>
        item.idSegmento &&
        segmentoGroup.push({
          idSegmento: item.idSegmento,
          typename: item.__typename
        })
    );
    const tempElecciones = elecciones.map(item => ({ ...item }));
    tempElecciones.map(i => {
      i.stockPorPrograma = parseInt(i.stockPorPrograma, 10);
      i.stockPorUsuario = parseInt(i.stockPorUsuario, 10);
      i.stockPorPeriodo = parseInt(i.stockPorPeriodo, 10);
      i.periodo = parseInt(i.periodo, 10);
      i.porcentaje = i.porcentaje ? i.porcentaje.replace(",", ".") : 0;
      return delete i.costo;
    });
    console.log(tempElecciones);
    await client
      .mutate({
        mutation: CatalogoQraphQL.queries.createCatalogo,
        variables: {
          eleccionesProductos: tempElecciones,
          segmentoGroup
        }
      })
      .then(res => {
        this.props.openNotification("Catálogo creado correctamente.");
        this.props.history.push(`/admin/programa/${id}/catalogo`);
      })
      .catch(e => {
        this.setState({ errors: e });
        return false;
      });
  }

  onDeselectAll = () => this.setState({ elecciones: [] });

  remove(idProducto) {
    const { elecciones } = this.state;
    const index = findIndex(elecciones, { idProducto: idProducto });
    let copy = Object.assign([], elecciones);
    copy.splice(index, 1);
    this.setState({ elecciones: copy });
  }

  renderTabla() {
    const { elecciones, productosYaElegidos } = this.state;
    const selectedItems = !isEmpty(elecciones)
      ? elecciones.map(item => parseInt(item.idProducto, 10))
      : [];

    const disabledItems = !isEmpty(productosYaElegidos)
      ? productosYaElegidos.map(item => parseInt(item.producto.idProducto, 10))
      : [];
    return (
      <Tabla
        disabledItems={disabledItems}
        onSelectItem={this.onChange}
        selectedItems={selectedItems}
        onDeselectAll={this.onDeselectAll}
      />
    );
  }

  renderDatosPrograma() {
    const { productosYaElegidos } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.results}>
        <Grid container direction="row" alignItems="center" spacing={16}>
          <Grid item xs={false} sm={3} />
          <Grid item xs={false} sm={3} />
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              <div>Tasa Conversión </div>
              <div>
                <b>
                  {this.state.programa && this.state.programa.tasaConversion}
                </b>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              <div>Productos</div>
              <div>
                <b>{productosYaElegidos && size(productosYaElegidos)}</b>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
  renderSeleccion() {
    const { elecciones, programa } = this.state;
    return (
      <Seleccion
        programa={programa}
        elecciones={elecciones}
        onHandleChange={this.handleChange.bind(this)}
        onRemove={this.remove.bind(this)}
      />
    );
  }

  renderForm() {
    const { segmentos, errors } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.results}>
        <Grid container direction="row" alignItems="center" spacing={16}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Agregar productos al catálogo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TreeField
              defaultLabel="Segmentos"
              data={segmentos}
              onChange={this.onChangeSegmento}
              withTodos
            />
          </Grid>
          <ErrorList errors={errors} />
        </Grid>
        <div className={classes.buttonSave}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.saveAction.bind(this)}
          >
            Agregar al catálogo
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { elecciones } = this.state;

    return (
      <div>
        {this.renderTabla()}
        {this.renderDatosPrograma()}
        {elecciones.length !== 0 && this.renderSeleccion()}
        {elecciones.length !== 0 && this.renderForm()}
      </div>
    );
  }
}

export default EleccionProducto;
