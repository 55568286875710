import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Producto/Edit/MyForm.js";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/producto.resolver";
import { removeTypename } from "js/utils/Helper";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productImages: [],
      initialValues: {
        stockInfinito: true,
        gestionadoPor: "ASHIWEA",
        tipoProducto: "ORDEN_COMPRA",
        estado: "ACTIVO"
      },
      disabled: null,
      idProducto: this.props.match.params.idProducto
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.match.params.edit !== "edit"
    });
  }

  async componentDidMount() {
    const { client } = this.props;
    const {
      match: {
        params: { idProducto, edit }
      }
    } = this.props;
    if (idProducto !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getProductoById,
          fetchPolicy: "network-only",
          variables: {
            productoLike: {
              idProducto
            }
          }
        })
        .then(res => {
          const {
            imagenes = [],
            sucursalesExcluidas = []
          } = res.data.getProductos.itemsPagina[0];
          this.setState({
            productImages: imagenes.map(({ url }) => url),
            initialValues: {
              ...res.data.getProductos.itemsPagina[0],
              idProveedor:
                res.data.getProductos.itemsPagina[0].proveedor.idProveedor,
              idRubro:
                res.data.getProductos.itemsPagina[0].rubro &&
                res.data.getProductos.itemsPagina[0].rubro.idRubro,
              idSubrubro:
                res.data.getProductos.itemsPagina[0].subrubro &&
                res.data.getProductos.itemsPagina[0].subrubro.idSubrubro,
              idDatosRequeridos: res.data.getProductos.itemsPagina[0].datosRequeridos.map(
                dato => dato.idDatoRequerido
              ),
              sucursalesExcluidas: sucursalesExcluidas.map(s => s.idSucursal)
            },
            disabled: !(edit === "edit")
          });
        });
    }
  }

  onChangeImage = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "producto");
    formData.append("height", 314);
    formData.append("width", 556);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ productImages }) => ({
          productImages: productImages.concat(getUrl(url))
        }));
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onRemoveImage = urlToRemove =>
    this.setState(({ productImages }) => ({
      productImages: productImages.filter(url => url !== urlToRemove)
    }));

  async submit(values) {
    const {
      client,
      history,
      match: {
        params: { idProducto }
      }
    } = this.props;

    const { productImages: imagenes } = this.state;
    let valuesCopy = Object.assign({}, values);
    valuesCopy.valorOrdenCompra = parseInt(valuesCopy.valorOrdenCompra);
    delete valuesCopy.rubro;
    delete valuesCopy.subrubro;
    delete valuesCopy.proveedor;

    // TO-DO: Remove
    delete valuesCopy.datosRequeridos;

    if (idProducto !== "new") delete valuesCopy.codigosActivacion;

    await client
      .mutate({
        mutation:
          idProducto === "new"
            ? AppQraphQL.queries.createProducto
            : AppQraphQL.queries.updateProducto,
        variables: {
          producto:
            idProducto === "new"
              ? { ...removeTypename(valuesCopy), imagenes }
              : {
                  ...removeTypename(this.removeDatosCreacion(valuesCopy)),
                  imagenes,
                  idProducto: parseInt(idProducto, 10)
                }
        }
      })
      .then(res => {
        const mensaje = "Producto grabado correctamente";
        this.props.openNotification(mensaje);
        history.push("/admin/producto");
      })
      .catch(e => {
        const message = e.message.replace("GraphQL error:", "").trim();
        this.props.openNotification(message);
      });
  }

  removeDatosCreacion = values => {
    delete values.usuarioCreador;
    delete values.usuarioModificador;
    delete values.fechaAlta;
    delete values.fechaModificacion;
    return values;
  };

  render() {
    const { productImages } = this.state;
    return (
      <Fragment>
        <Form
          id={this.state.idProducto}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          onChangeImage={this.onChangeImage}
          onRemoveImage={this.onRemoveImage}
          productImages={productImages}
        />
      </Fragment>
    );
  }
}

export default withApollo(withRouter(Edit));
