import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/Catalogo/Edit/MyForm.js";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import AppQraphQL from "js/graphql/resolvers/catalogo.resolver";
import { addChecked, filter } from "../utils";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        estado: "ACTIVO"
      },
      paises: [],
      rubros: [],
      segmentos: [],
      paisesSeleccionados: [],
      rubrosSeleccionados: [],
      segmentosSeleccionados: [],
      disabled: null,
      id: this.props.match.params.id,
      idCatalogo: this.props.match.params.idCatalogo,
      productoInfinito: false
    };
  }

  async componentDidMount() {
    const {
      client,
      match: {
        params: { id, idCatalogo, edit }
      }
    } = this.props;
    await client
      .query({
        query: AppQraphQL.queries.getEleccionProductoById,
        fetchPolicy: "no-cache",
        variables: {
          idPrograma: id,
          idEleccionProducto: idCatalogo
        }
      })
      .then(res => {
        const page = res.data.getEleccionProducto.itemsPagina[0];
        const paises = filter(res.data.getPaises || []);
        const rubros = filter(res.data.getRubros || []);
        const segmentos = filter(res.data.getSegmentos || []);

        const paisesSeleccionados = filter(page.paises || []);
        const zonasSeleccionadas = filter(page.zonas || []);
        const localidadesSeleccionadas = filter(page.localidades || []);
        const rubrosSeleccionados = filter(page.rubros || []);
        const subRubrosSeleccionados = filter(page.subrubros || []);
        const segmentosSeleccionados = filter(page.segmentos || []);

        paisesSeleccionados.map(item =>
          addChecked(paises, "idPais", item.idPais)
        );
        zonasSeleccionadas.map(item =>
          addChecked(paises, "idZona", item.idZona)
        );
        localidadesSeleccionadas.map(item =>
          addChecked(paises, "idLocalidad", item.idLocalidad)
        );
        rubrosSeleccionados.map(item =>
          addChecked(rubros, "idRubro", item.idRubro)
        );
        subRubrosSeleccionados.map(item =>
          addChecked(rubros, "idSubrubro", item.idSubrubro)
        );
        segmentosSeleccionados.map(item =>
          addChecked(segmentos, "idSegmento", item.idSegmento)
        );

        this.setState({
          initialValues: { ...page },
          paises,
          rubros,
          segmentos,
          paisesSeleccionados: paisesSeleccionados
            .concat(zonasSeleccionadas)
            .concat(localidadesSeleccionadas),
          rubrosSeleccionados: rubrosSeleccionados.concat(
            subRubrosSeleccionados
          ),
          segmentosSeleccionados,
          disabled: !(edit === "edit"),
          productoInfinito: page.producto ? page.producto.stockInfinito : false
        });
      });
  }

  componentWillReceiveProps = nextProps => {
    const {
      match: {
        params: { edit }
      }
    } = nextProps;
    this.setState({ disabled: !(edit === "edit") });
  };

  async submit(values) {
    let paises = [];
    let zonas = [];
    let localidades = [];
    let rubros = [];
    let subrubros = [];
    let segmentos = [];
    const {
      segmentosSeleccionados,
      rubrosSeleccionados,
      paisesSeleccionados,
      initialValues
    } = this.state;
    const {
      client,
      history,
      match: {
        params: { id, idCatalogo }
      }
    } = this.props;

    if (
      isEmpty(segmentosSeleccionados) ||
      isEmpty(rubrosSeleccionados) ||
      isEmpty(paisesSeleccionados)
    ) {
      let e = [];
      e["message"] =
        "Segmentos, Categoría/Subcategoría y País/Zona/Localidad no pueden estar vacíos.";
      this.setState({ errors: e });
      return false;
    }

    if (!initialValues.producto.stockInfinito && values.stockInfinito) {
      let e = [];
      e["message"] = [
        "La opción stock infinito del producto no puede ser activada si el stock en el Master no es infinito.\n",
        "Debe seleccionar el stock infinito en el Master y luego en el programa."
      ];
      this.setState({ errors: e });
      return false;
    }

    if (initialValues.producto.stock < values.stockPorPrograma) {
      let e = [];
      e["message"] =
        "El stock programa no puede ser mayor que el stock de producto master: " +
        initialValues.producto.stock;
      this.setState({ errors: e });
      return false;
    }

    paisesSeleccionados &&
      paisesSeleccionados.map(item => {
        item.idPais && paises.push(item.idPais);
        item.idZona && zonas.push(item.idZona);
        item.idLocalidad && localidades.push(item.idLocalidad);
        return true;
      });
    rubrosSeleccionados &&
      rubrosSeleccionados.map(item => {
        item.idRubro && rubros.push(item.idRubro);
        item.idSubrubro && subrubros.push(item.idSubrubro);
        return true;
      });
    segmentosSeleccionados &&
      segmentosSeleccionados.map(
        item => item.idSegmento && segmentos.push(item.idSegmento)
      );
    await client
      .mutate({
        mutation: AppQraphQL.queries.updateEleccionProducto,
        variables: {
          eleccionProducto: {
            idEleccionProducto: parseInt(idCatalogo, 10),
            estado: values.estado,
            porcentaje: parseFloat(values.porcentaje, 10),
            prioridad: parseInt(values.prioridad, 10),
            stockInfinito: values.stockInfinito,
            stockPorPrograma: parseInt(values.stockPorPrograma, 10),
            stockPorUsuario: parseInt(values.stockPorUsuario, 10),
            stockPorPeriodo: parseInt(values.stockPorPeriodo, 10),
            periodo: values.periodo,
            cucarda: !isEmpty(values.cucarda) ? values.cucarda : null,
            paises,
            zonas,
            localidades,
            rubros,
            subrubros,
            segmentos
          }
        }
      })
      .then(res => {
        history.push(`/admin/programa/${id}/catalogo`);
      })
      .catch(e => {
        let err = [];
        err["message"] = e.message;
        this.setState({
          errors: !isEmpty(e.graphQLErrors) ? e.graphQLErrors : [err]
        });
        return false;
      });
  }

  onChangePais = paisesSeleccionados => {
    this.setState({ ...paisesSeleccionados });
  };
  onChangeRubro = rubrosSeleccionados => {
    this.setState({ ...rubrosSeleccionados });
  };
  onChangeSegmento = segmentosSeleccionados => {
    this.setState({ ...segmentosSeleccionados });
  };

  render() {
    return (
      <Fragment>
        {this.state.initialValues &&
          this.state.paises && (
            <Form
              id={this.state.id}
              idEleccionProducto={this.state.idCatalogo}
              disabled={this.state.disabled}
              initialValues={this.state.initialValues}
              paises={this.state.paises}
              onChangePais={this.onChangePais.bind(this)}
              rubros={this.state.rubros}
              onChangeRubro={this.onChangeRubro.bind(this)}
              segmentos={this.state.segmentos}
              onChangeSegmento={this.onChangeSegmento.bind(this)}
              onSubmit={this.submit.bind(this)}
              errors={this.state.errors}
              productoInfinito={this.state.productoInfinito}
            />
          )}
      </Fragment>
    );
  }
}

export default withApollo(withRouter(Edit));
