import MyForm from "./MyForm";
import { compose, withHandlers } from "recompose";
import { withNotificationContext } from "js/notification-context";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { styles } from "js/components/Shared/Form/styles.js";
import styles2 from "./styles.js";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import ReconocimientoQraphQL from "js/graphql/resolvers/reconocimiento.resolver";
import { removeTypename } from "js/utils/Helper";
import { graphql } from "react-apollo";
const selector = formValueSelector("formularioReconocimientoForm");

const enhance = compose(
  withNotificationContext,
  withRouter,
  withStyles(styles),
  withStyles(styles2),
  graphql(ReconocimientoQraphQL.queries.getFormularioReconocimiento, {
    options: ({
      match: {
        params: { id }
      }
    }) => ({
      variables: {
        idPrograma: id
      },
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, getFormularioReconocimiento } }) => {
      if (loading) return;
      let formulario = getFormularioReconocimiento;
      return {
        initialValues: formulario
          ? {
              ...formulario
            }
          : {
              acreditacionAutomatica: false,
              puedeReconocerEquipos: false,
              nivelAprobacion: 0,
              apruebaRecursosHumanos: false,
              focoenreconocedor: false,
              otorgaPremio: false,
              otorgaPuntos: false,
              matrizPuntos: [{}],
              matrizPremio: [{}],
              redefineTipo: []
            },
        tipos:
          formulario && formulario.tipo && formulario.tipo.valores
            ? formulario.tipo.valores.splice()
            : []
      };
    }
  }),
  graphql(ReconocimientoQraphQL.mutations.setFormularioReconocimiento, {
    name: "submitMutation"
  }),
  withHandlers({
    onSubmit: ({
      submitMutation,
      openNotification,
      match: {
        params: { id }
      }
    }) => async values => {
      const formularioReconocimiento = removeTypename(values);
      if (formularioReconocimiento._id) delete formularioReconocimiento._id;
      formularioReconocimiento.idPrograma = id;
      formularioReconocimiento.matrizPuntos =
        formularioReconocimiento.otorgaPuntos &&
        formularioReconocimiento.matrizPuntos
          ? formularioReconocimiento.matrizPuntos.filter(
              rango =>
                rango.valor && (rango.rangoInferior || rango.rangoSuperior)
            )
          : [];
      formularioReconocimiento.matrizPremio =
        formularioReconocimiento.otorgaPremio &&
        formularioReconocimiento.matrizPremio
          ? formularioReconocimiento.matrizPremio.filter(
              rango =>
                rango.valor && (rango.rangoInferior || rango.rangoSuperior)
            )
          : [];
      if (formularioReconocimiento.valor) {
        formularioReconocimiento.valor.valores = formularioReconocimiento.valor
          .valores
          ? formularioReconocimiento.valor.valores
          : [];
      }
      if (formularioReconocimiento.tipo) {
        formularioReconocimiento.tipo.valores = formularioReconocimiento.tipo
          .valores
          ? formularioReconocimiento.tipo.valores
          : [];
      }
      if (
        formularioReconocimiento.mailRecordatorio &&
        !formularioReconocimiento.mailRecordatorio.html
      )
        formularioReconocimiento.mailRecordatorio = null;
      if (
        formularioReconocimiento.mailAprobacion &&
        !formularioReconocimiento.mailAprobacion.html
      )
        formularioReconocimiento.mailAprobacion = null;
      if (
        formularioReconocimiento.mailReconocimientoRechazado &&
        !formularioReconocimiento.mailReconocimientoRechazado.html
      )
        formularioReconocimiento.mailReconocimientoRechazado = null;
      if (
        formularioReconocimiento.mailReconocimientoAprobado &&
        !formularioReconocimiento.mailReconocimientoAprobado.html
      )
        formularioReconocimiento.mailReconocimientoAprobado = null;
      if (
        formularioReconocimiento.mailReconocimientoEnEdicion &&
        !formularioReconocimiento.mailReconocimientoEnEdicion.html
      )
        formularioReconocimiento.mailReconocimientoEnEdicion = null;
      submitMutation({ variables: { formularioReconocimiento } })
        .then(
          res =>
            res.errors && res.errors.length
              ? openNotification(res.errors[0].message)
              : openNotification("Formulario guardado correctamente")
        )
        .catch(e =>
          openNotification(e.message.replace("GraphQL error:", "").trim())
        );
    }
  }),
  connect(state =>
    selector(
      state,
      "otorgaPuntos",
      "otorgaPremio",
      "conSG",
      "url",
      "nivelAprobacion",
      "apruebaRecursosHumanos"
    )
  ),
  reduxForm({
    form: "formularioReconocimientoForm",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
);

export default enhance(MyForm);
