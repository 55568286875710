import { withStyles } from "@material-ui/core/styles";
import ReportesCLN from "./ReportesCLN";
import CLNGraphQL from "js/graphql/resolvers/clubLaNacion.resolver";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { get, maxBy } from "lodash";
import {
  compose,
  withState,
  withProps,
  lifecycle,
  withHandlers
} from "recompose";
import { getAnoMes } from "./Reportes/utils";
import moment from "moment";
import { withApollo } from "react-apollo";

const enhance = compose(
  withRouter,
  withApollo,
  withStyles(styles),
  withState("loading", "setLoading", false),
  withState("data", "setData", {}),
  withState("info", "setInfo", {}),
  withState("variables", "setVariables", {
    fechaDesde: parseInt(
      moment()
        .subtract(3, "months")
        .format("YYYYMM")
    ),
    fechaHasta: parseInt(
      moment()
        .subtract(1, "month")
        .format("YYYYMM")
    )
  }),
  withProps(({ location: { pathname } }) => {
    return {
      query: pathname === "/reportes" ? "miReporteCLN" : "getReporteCLN"
    };
  }),
  withHandlers({
    fetchData: ({
      client,
      variables,
      query,
      setData,
      setInfo,
      setLoading,
      idUsuario
    }) => async () => {
      setLoading(true);
      const { data } = await client.query({
        query: CLNGraphQL.queries[query],
        errorPolicy: "all",
        fetchPolicy: "network-only",
        variables: {
          ...variables,
          idUsuario
        }
      });
      const previousYear = {};
      try {
        const dataAnoAnterior = await client.query({
          query: CLNGraphQL.queries[query],
          errorPolicy: "all",
          fetchPolicy: "network-only",
          variables: {
            fechaDesde: parseInt(
              moment(variables.fechaDesde, "YYYYMM")
                .subtract(1, "year")
                .format("YYYYMM")
            ),
            fechaHasta: parseInt(
              moment(variables.fechaHasta, "YYYYMM")
                .subtract(1, "year")
                .format("YYYYMM")
            ),
            idUsuario
          }
        });
        console.log(dataAnoAnterior);
        dataAnoAnterior.data[query].transaccionesGlobales.map(
          ({ anoMes, transacciones }) => {
            previousYear[anoMes] = transacciones;
          }
        );
        data[query].comercio.perfil.otros = JSON.parse(
          data[query].comercio.perfil.otros
        );
      } catch (e) {
        console.log(e);
      }

      const result = data[query];
      const comercio = get(
        result,
        "comercio.perfil.otros.nombre_fantasia",
        get(
          result,
          "transaccionesPorSucursal[0].sucursal.comercio.username",
          get(result, "transaccionesGlobales[0].comercio.username")
        )
      );
      const { objetivo, anoMes } =
        maxBy(get(result, "transaccionesGlobales", []), "anoMes") || {};
      setInfo({
        comercio,
        objetivo,
        lastMonth: getAnoMes(
          moment(anoMes, "YYYYMM")
            .add(1, "M")
            .format("YYYYMM")
        ),
        previousYear
      });
      setData(result);
      setLoading(false);
    }
  }),
  withHandlers({
    onChange: ({ variables, setVariables, fetchData }) => key => async ({
      _d
    }) => {
      await setVariables({
        ...variables,
        [key]: parseInt(moment(_d).format("YYYYMM"))
      });
      fetchData();
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData();
    },
    componentWillReceiveProps(nextProps) {
      nextProps.idUsuario !== this.props.idUsuario && this.props.fetchData();
    }
  })
);
export default enhance(ReportesCLN);
