import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppQraphQL from "js/graphql/resolvers/notificacion.resolver";
import Typography from "@material-ui/core/Typography";

import TablaNotificaciones from "js/pages/Admin/Programa/Notificaciones/Table";
import { styles } from "js/pages/Admin/Programa/Notificaciones/styles.js";

class Notificaciones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: []
    };
  }

  getNotificaciones() {
    const { client } = this.props;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return client
      .query({
        query: AppQraphQL.queries.getNotificacionesById,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: id
        }
      })
      .then(res => {
        res.data.getNotificaciones.length > 0 &&
          this.setState({ initialValues: res.data.getNotificaciones });
      });
  }

  async componentDidMount() {
    await this.getNotificaciones();
  }

  render() {
    const { initialValues } = this.state;
    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Notificaciones
        </Typography>
        {initialValues && <TablaNotificaciones rows={initialValues} />}
      </div>
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(Notificaciones)));
