import React, { PureComponent } from "react";
import EnhancedTable from "js/components/Shared/Tabla/EnhancedTable";
import DefaultFilter from "js/components/Shared/Tabla/DefaultFilter";
import Header from "js/components/Shared/Tabla/Header";
import ButtonBar from "js/components/Shared/Tabla/ActionButtons";
import PropTypes from "prop-types";
import FilterService from "js/utils/FilterService";
import { size } from "lodash";
import { plainObject } from "js/utils/Helper";
import SplashScreen from "js/pages/SplashScreen";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import Collapse from "js/components/Shared/Collapsable";

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id,
          idProveedor,
          idUsuario,
          idProducto,
          idCampana,
          idBeneficio,
          codigo
        }
      },
      initialFilter = {}
    } = props;
    let filter = { ...initialFilter };

    // Para las tablas del FE, el id el programa no esta en "match" y hay que hacer las queries con el id del programa
    // entonces como id no va a tener valor feProgramId si va a tener
    let feProgramId = undefined;
    const inBackOffice = window.location.pathname.indexOf("admin") !== -1;
    if (!inBackOffice) {
      feProgramId = parseInt(localStorage.getItem("idPrograma"));
    }

    if (id) {
      filter.idPrograma = id;
      filter.idsProgramas = [id];
    } else if (feProgramId) {
      filter.idPrograma = feProgramId;
      filter.idsProgramas = [feProgramId];
    }

    if (idProveedor) filter.idProveedor = idProveedor;
    if (idUsuario) filter.idUsuario = idUsuario;
    if (idProducto) filter.idProducto = idProducto;
    if (idCampana) filter.idCampana = idCampana;
    if (idBeneficio) filter.idBeneficio = idBeneficio;
    // para meds de unilever
    if (codigo) {
      filter.codigo = codigo;
    } else {
      const feCodidgo =
        props.computedMatch &&
        props.computedMatch.params &&
        props.computedMatch.params.codigo
          ? props.computedMatch.params.codigo
          : null;
      if (feCodidgo) {
        filter.codigo = feCodidgo;
      }
    }

    this.state = {
      selected: [],
      disabled: [],
      data: null,
      itemPopUpId: undefined,
      dialogPopUpId: undefined,
      order: props.sort.order,
      orderBy: props.sort.orderBy,
      page: 1,
      rowsPerPage: props.rowsPerPage || 10,
      filter: filter,
      expanded: false,
      inBackOffice: inBackOffice,
      feProgramId: feProgramId
    };
  }

  _fetchData = async ({ order, orderBy, page, rowsPerPage, filter }) => {
    const filtered = plainObject(filter);
    const { client, query } = this.props;
    await client
      .query({
        query: query,
        fetchPolicy: "network-only",
        variables: {
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          ...(Object.keys(filtered).length !== 0 ? filtered : this.state.filter)
        }
      })
      .then(res => {
        this.setState({
          data: res.data || this.state.data,
          order: order || this.state.order,
          orderBy: orderBy || this.state.orderBy,
          page: page || this.state.page,
          rowsPerPage: rowsPerPage || this.state.rowsPerPage,
          filter: filter || this.state.filter
        });
        if (this.props.dispatchAction) {
          this.props.dispatchAction(this.props.getTipo(res.data).itemsPagina);
        }
      });
    // .catch(error => {
    //
    // });
  };

  componentDidMount = () => {
    const useFilterService =
      AuthService.getUltimaURL().indexOf(
        window.location.pathname.split("/").pop()
      ) > -1;
    const filtro =
      useFilterService && FilterService.getFilter(this.props.storage);
    if (this.props.disabled) {
      this.setState({ disabled: this.state.disabled });
    }
    this.props.storage &&
      filtro &&
      this.setState({ selected: filtro.selected });

    const { initialValues } = this.props;
    if (initialValues && initialValues.cuentaCorriente_usuario_gerencia) {
      filtro.cuentaCorriente_usuario_gerencia =
        initialValues.cuentaCorriente_usuario_gerencia;
      this.setState({ filter: filtro });
    }
    //FIXME: hack para q no cargue TODOS los programas si no tiene el permiso
    // (AuthService.hasPerms([Roles.PROGRAMA_LISTA]) || this.props.forceFetch) &&
    //   this._fetchData({ ...filtro });
  };

  componentWillReceiveProps = props => {
    this.search();
    const { selectedItems, disabledItems, initialFilter } = props;
    if (initialFilter) {
      const filter = this.state.filter;
      filter.cuentaCorriente_usuario_gerencia =
        initialFilter.cuentaCorriente_usuario_gerencia;
      this.searchAndSet(filter);
    }
    this.setState({
      selected: selectedItems,
      disabled: disabledItems
    });
  };

  componentWillUnmount = () => {
    this.props.storage &&
      FilterService.setFilter(this.props.storage, {
        order: this.state.order,
        orderBy: this.state.orderBy,
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filter: this.state.filter,
        selected: this.state.selected
      });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this._fetchData({ order, orderBy });
  };

  handleSelectAllClick = (data, event, checked) => {
    // Si se quiere usar como id del item de la tabla un dato que esta dentro de un objeto del item
    // en el pathId se pone <objeto>.<campo>. No poner más de un nivel
    const {
      pathId,
      actionConditionVariable,
      actionConditionValues
    } = this.props;
    const key = pathId ? pathId : Object.keys(data[0])[0];
    let keys = null;
    if (key.indexOf(".") !== -1) {
      keys = key.split(".");
    }
    if (checked) {
      const selected = [];
      data.forEach(n => {
        if (actionConditionVariable) {
          actionConditionValues.forEach(value => {
            if (n[actionConditionVariable] === value) {
              selected.push(
                key.indexOf(".") !== -1 ? n[keys[0]][keys[1]] : n[key]
              );
            }
          });
        } else {
          selected.push(key.indexOf(".") !== -1 ? n[keys[0]][keys[1]] : n[key]);
        }
      });
      this.setState({ selected });
      this.props.storage &&
        FilterService.setFilter(this.props.storage, {
          ...this.state,
          selected: selected
        });
      return;
    }
    this.setState({ selected: [] });
  };

  handleDeselectAllClick = () => {
    this.setState({ selected: [] });
    this.props.onDeselectAll();
  };

  handleClick = (event, item) => {
    const { selected } = this.state;
    const { pathId } = this.props;
    const key = pathId ? pathId : Object.keys(item)[0];
    let id = null;
    if (key.indexOf(".") !== -1) {
      let keys = key.split(".");
      id = item[keys[0]][keys[1]];
    } else {
      id = item[key];
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = Object.assign([], selected);
    if (selectedIndex === -1) {
      newSelected.push(id);
      this.props.onSelectItem(newSelected, Object.assign({}, item));
    } else {
      newSelected.splice(selectedIndex, 1);
      this.props.onSelectItem(newSelected, id);
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this._fetchData({ page: parseInt(page, 10) + 1 });
  };

  handleChangeRowsPerPage = event => {
    this._fetchData({ rowsPerPage: event.target.value, page: 1 });
  };

  handleKey = e => {
    if (e.keyCode === 13) this.search();
  };

  togglePopUp = (itemPopUpId, dialogPopUpId) =>
    this.setState({ itemPopUpId, dialogPopUpId });

  submitValue = e => {
    const obj = this.state.filter;
    // const objPais = {};
    const objZona = {};
    const objLocalidad = {};
    // const objSegmento = {};

    if (Array.isArray(e.target.value) && e.target.value.length === 0) {
      delete obj[e.target.name];
    } else {
      obj[e.target.name] = e.target.value;
    }
    const paisActual = this.state.filter && this.state.filter.idPais;
    // const programaActual = this.state.filter && this.state.filter.idPrograma;
    // TODO: cambio de programa genera cambio de segmento
    // Ver esto cuando ande el BE de busqueda de Usuarios
    // if (e.target.name === "idPrograma" && e.target.value !== programaActual) {
    // objSegmento["idSegmento"] = "";}

    if (e.target.name === "idPais" && e.target.value !== paisActual) {
      objZona["idZona"] = undefined;
      objLocalidad["idLocalidad"] = undefined;
    }
    if (e.target.name === "idZona" && e.target.value === "") {
      objLocalidad["idLocalidad"] = undefined;
    }

    this.setState(({ filter }) => ({
      filter: { ...obj, ...objZona, ...objLocalidad }
    }));
  };

  clear = () => {
    //FIXME: pasar por params los values del clear
    const cuentaCorriente_tipo = this.state.filter.cuentaCorriente_tipo;
    const cuentaCorriente_usuario_gerencia =
      this.state.filter["cuentaCorriente_usuario_gerencia"] &&
      !AuthService.hasPerms([Roles.CC_SELECT_GERENCIA])
        ? this.state.filter.cuentaCorriente_usuario_gerencia
        : undefined;
    const {
      match: {
        params: {
          id,
          idSegmento,
          idProveedor,
          idUsuario,
          idProducto,
          idCampana,
          idBeneficio,
          codigo
        }
      },
      idPrograma
    } = this.props;

    // Eso sirve para todas las tablas que pueden estar en el back office y el front end
    // En el caso de las del front end solo tienen que traer los datos de un solo programa
    const { inBackOffice, feProgramId } = this.state;
    let fianalIdPrograma = undefined;
    let finalIdsProgramas = undefined;

    if (id || idPrograma) {
      fianalIdPrograma = id || idPrograma;
      finalIdsProgramas = [id || idPrograma];
    }

    if (!inBackOffice) {
      fianalIdPrograma = feProgramId;
      finalIdsProgramas = [feProgramId];
    }

    const filter = {
      idPrograma: fianalIdPrograma,
      idsProgramas: finalIdsProgramas,
      idSegmento
    };

    if (idProveedor) filter.idProveedor = idProveedor;
    if (idUsuario) filter.idUsuario = idUsuario;
    if (idProducto) filter.idProducto = idProducto;
    if (idCampana) filter.idCampana = idCampana;
    if (idBeneficio) filter.idBeneficio = idBeneficio;
    // para meds de unilever
    if (codigo) {
      filter.codigo = codigo;
    } else {
      const feCodidgo =
        this.props.computedMatch &&
        this.props.computedMatch.params &&
        this.props.computedMatch.params.codigo
          ? this.props.computedMatch.params.codigo
          : null;
      if (feCodidgo) {
        filter.codigo = feCodidgo;
      }
    }

    this._fetchData({
      filter: {
        // estado: "ACTIVO",
        ...filter,
        cuentaCorriente_tipo,
        cuentaCorriente_usuario_gerencia
      }
    });
  };

  getHeaderQueryString = ({ queryStringMatcher = {} }) => {
    const { filter } = this.state;
    let filterCopy = Object.assign({}, filter);
    Object.keys(queryStringMatcher).map(key => {
      if (queryStringMatcher[key])
        filterCopy[queryStringMatcher[key]] = filterCopy[key];
      return delete filterCopy[key];
    });
    Object.keys(filterCopy).forEach(
      key => filterCopy[key] === undefined && delete filterCopy[key]
    );
    return filterCopy;
  };

  search = () => {
    let filter = this.state.filter;
    Object.keys(filter).forEach(key => {
      if (filter[key] === "TODOS") delete filter[key];
    });
    this._fetchData({
      filter: {
        ...filter
      }
    });
    this.setState({ expanded: false });
  };

  searchAndSet = filter => {
    Object.keys(filter).forEach(key => {
      if (filter[key] === "TODOS") delete filter[key];
    });
    this._fetchData({
      filter: {
        ...filter
      }
    });
    this.setState({ expanded: false, filter: filter });
  };

  handleCollapse = () =>
    this.setState(prevState => ({ expanded: !prevState.expanded }));

  render() {
    const {
      showHeader,
      columnData,
      getTipo,
      filter,
      advancedFilter: AdvancedFilter,
      defaultHeader,
      actions,
      actionsCustom,
      toolbarActions,
      deleteAction,
      labelDisplayedRows,
      buttons,
      pathId,
      actionConditionVariable,
      actionConditionValues,
      showPagination
    } = this.props;
    return (
      <div tabIndex="0" onKeyDown={this.handleKey}>
        {showHeader && (
          <Header
            values={defaultHeader}
            filter={this.getHeaderQueryString(defaultHeader)}
            refetch={() => this.search()}
          />
        )}
        {!!filter.length && (
          <DefaultFilter
            filter={filter}
            value={this.state.filter}
            submitValue={this.submitValue.bind(this)}
          />
        )}
        {!!AdvancedFilter && (
          <Collapse
            clear={this.clear.bind(this)}
            search={this.search.bind(this)}
            expanded={this.state.expanded}
            onChange={this.handleCollapse}
            title="Filtros"
          >
            <AdvancedFilter
              value={this.state.filter}
              submitValue={this.submitValue.bind(this)}
              search={this.search.bind(this)}
              conGerencia={this.props.conGerencia}
              idPrograma={this.props.idPrograma}
            />
          </Collapse>
        )}
        {!this.state.data && <SplashScreen />}
        {this.state.data && (
          <EnhancedTable
            refetch={this._fetchData}
            columnData={columnData}
            labelDisplayedRows={labelDisplayedRows}
            data={getTipo(this.state.data).itemsPagina}
            page={
              showPagination
                ? getTipo(this.state.data).infoPagina.numeroPagina
                : 0
            }
            total={
              showPagination ? getTipo(this.state.data).infoPagina.total : 0
            }
            totalDisabledItems={size(this.props.disabledItems) || 0}
            handleRequestSort={this.handleRequestSort.bind(this)}
            handleSelectAllClick={this.handleSelectAllClick.bind(
              this,
              getTipo(this.state.data).itemsPagina
            )}
            handleClick={this.handleClick.bind(this)}
            handleDeselectAllClick={this.handleDeselectAllClick.bind(this)}
            handleChangePage={this.handleChangePage.bind(this)}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            footer={this.props.footer}
            actions={actions}
            actionsCustom={actionsCustom}
            toolbarActions={toolbarActions}
            hanleDeleteAction={deleteAction.bind(this)}
            state={this.state}
            onTableChange={() => this.search()}
            pathId={pathId}
            actionConditionVariable={actionConditionVariable}
            actionConditionValues={actionConditionValues}
            togglePopUp={this.togglePopUp}
            itemPopUpId={this.state.itemPopUpId}
            dialogPopUpId={this.state.dialogPopUpId}
            showPagination={showPagination}
          />
        )}
        {this.state.data && (
          <ButtonBar
            onTableChange={() => this.search()}
            buttons={buttons}
            total={
              showPagination ? getTipo(this.state.data).infoPagina.total : 0
            }
            filters={this.state.filter}
            selected={this.state.selected.length}
          />
        )}
      </div>
    );
  }
}

Tabla.propTypes = {
  storage: PropTypes.string,
  query: PropTypes.object.isRequired,
  columnData: PropTypes.array.isRequired,
  showHeader: PropTypes.bool,
  defaultHeader: PropTypes.object,
  sort: PropTypes.object,
  filter: PropTypes.array.isRequired,
  advancedFilter: PropTypes.func,
  getTipo: PropTypes.func.isRequired,
  actions: PropTypes.array,
  actionsCustom: PropTypes.array,
  buttons: PropTypes.array,
  toolbarActions: PropTypes.array,
  disabledItems: PropTypes.array,
  selectedItems: PropTypes.array,
  onSelectItem: PropTypes.func,
  onDeselectAll: PropTypes.func,
  deleteAction: PropTypes.func,
  dispatchAction: PropTypes.func,
  computedMatch: PropTypes.object,
  showPagination: PropTypes.bool
};

Tabla.defaultProps = {
  storage: null,
  showHeader: true,
  initialFilter: {},
  filter: [],
  advancedFilter: undefined,
  sort: {},
  actions: [],
  footer: [],
  actionsCustom: [],
  buttons: [],
  toolbarActions: [],
  disabledItems: [],
  selectedItems: [],
  onSelectItem: () => undefined,
  onDeselectAll: () => undefined,
  deleteAction: () => undefined,
  dispatchAction: () => undefined,
  computedMatch: {},
  showPagination: true
};

export default Tabla;
